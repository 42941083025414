import React, { useState } from "react";
import { Avatar } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import Button from "../Button";
import useAuth0 from "../../hooks/useAuth0";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/DashboardBanner.module.scss";

interface BannerProps {
  image: string | null;
  title: string;
  info: string;
  percentage?: number;
  subTitle?: string;
  logoutButton?: boolean;
  profileCard?: boolean;
  showBackButton?: boolean;
  customWidth?: string;
}

const DashboardBanner: React.FC<BannerProps> = ({
  image,
  title,
  info,
  subTitle,
  logoutButton = false,
  profileCard = true,
  showBackButton = false,
  customWidth,
}) => {
  const history = useHistory();
  const { isMobile } = useIsMobile();
  const { user, logout } = useAuth0();
  const isVerified = user?.verificationStatus;
  image = image ? process.env.REACT_APP_API_BASE_URL + image+"?width=160&height=160" : "/images/default-avatar.png";

  return (
    <div className={css.DashboardBanner}>
      <div className={css.profileInfo}>
        {showBackButton && (
          <div className={css.backButton}>
            <ArrowBackIosIcon
              sx={{
                width: "20px",
                height: "20px",
                marginLeft: "10px",
                color: "#5C8E89",
              }}
            />
            <div
              style={{
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#5C8E89",
              }}
            >
              Tilbake
            </div>
          </div>
        )}

        <Avatar
          src={image ?? "/images/default-avatar.png"}
          className={css.profilePic}
        />

        <div style={{ flex: 1 }}>
          <div className={css.minPond}>{subTitle}</div>
          <div className={css.welcomeText}>{title}</div>
          <div
            className={css.infoText}
            style={{ width: customWidth ? customWidth : "auto" }}
          >
            {info}
          </div>
        </div>

        {!isMobile && logoutButton && (
          <div>
            <Button
              label="Logg ut"
              skin="transparent"
              onClick={() => {
                logout();
                history.push("/login");
              }}
            />
          </div>
        )}

        {/* {profileCard && user.breeder && (
          <div
            className={css.profileInfoCard}
            style={{
              backgroundColor: isVerified === 0 ? "#B4CECB" : "",
            }}
          >
            <div style={{ display: "flex", gap: "12px" }}>
              <img
                src="/images/Skjermbilde.png"
                alt="Icon"
                style={{ width: "73px" }}
              />
              <div>
                <div className={css.BliText}>
                  {isVerified === 0
                    ? "Under vurdering!"
                    : isVerified === 1
                    ? "Din kennel er verifisert!"
                    : "Bli verifisert!"}
                </div>
                <div className={css.BliDesc}>
                  {isVerified === 0
                    ? "Du mottar en bekreftelse på e-post når verifisering er godkjent."
                    : isVerified === 1
                    ? "Verifisering øker synlighet og tillit hos valpekjøpere."
                    : "Verifisering øker synlighet og tillit hos potensielle kjøpere. Les mer her."}
                </div>
              </div>
            </div>

            {(isVerified === null || isVerified === 2) && (
              <button
                className={css.profileInfoCardButton}
                onClick={() => {
                  history.push("/verification");
                }}
              >
                <ArrowForwardIcon
                  style={{ fontSize: "17px", marginRight: "3px" }}
                />
                  Start verifisering
              </button>
            )}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default DashboardBanner;
