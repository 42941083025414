import { FC, ReactNode } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

interface IProps {
    children: ReactNode;
}

const DatePickerProvider: FC<IProps> = ({ children }): JSX.Element=> {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'TimePicker']} sx={{flex: 1, '.MuiInputLabel-sizeSmall': { top: 2 }, paddingTop: 0}}>
                { children }
            </DemoContainer>
        </LocalizationProvider>
    );
};

export default DatePickerProvider;