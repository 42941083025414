import {
  Alert,
  CircularProgress,
  Stack,
  TextField,
  Button as ButtonSubmit,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import Button from "../../components/Button";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/AccountSettings.module.scss";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import Footer from "./Footer";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import { useHistory } from "react-router-dom";
import useAuth0 from "../../hooks/useAuth0";
import {
  useDeleteMemberMutation,
  useGetMeQueryQuery,
  useToggleGhostModeMutation,
  useUpdateMemberMutation,
} from "../../api/graphql.gen";
import { useForm } from "react-hook-form";
import useToast from "../../components/Toast/hooks";
import ProfileAvatar from "../../components/ProfileAvatar";
import ChangePassword from "./ChangePassword";

const formFields = [
  "firstName",
  "lastName",
  "email",
  "phoneNumber",
  "street",
  "zip",
  "city",
];

const AccountSettings: React.FC = () => {
  const { showToast } = useToast();
  const { isAuthenticated, isLoading, user: AuthUser, logout } = useAuth0();
  // const { data: userInfo } = useGetUserByEmailQuery({
  //   email: authUser?.email ?? "",
  // });
  // const userId = userInfo?.getUserByEmail.id ?? -1;
  // const { data: userData } = useGetUserQuery({ id: userId });
  const { data: userData } = useGetMeQueryQuery();
  const user: any = userData?.memberQuery.me;
  const { isMobile } = useIsMobile();

  const [confirmGhost, setConfirmGhost] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [savedChanges, setSavedChanges] = useState(false);
  const [NewPassword, setNewPassword] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "/images/default-avatar.png"
  );
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({});
  const history = useHistory();

  useEffect(() => {
    window.analytics.page("Kontoinnstillinger");
  }, []);

  useEffect(() => {
    if (user) {
      formFields.map((key) => setValue(key, user[key]));
      const profileImage =
        process.env.REACT_APP_API_BASE_URL + user.profileImage;
      setProfilePicture(profileImage ?? "/images/default-avatar.png");
    }
  }, [user]);

  const { mutate: updateProfile, isLoading: isUpdating } =
    useUpdateMemberMutation({
      onSuccess: () => {
        setSavedChanges(true);
        showToast("Profile has been successfully updated.", "success");
      },
    });

  const { mutate: toggleGhostMode } = useToggleGhostModeMutation({
    onSuccess: () => {
      setSavedChanges(true);
      showToast("Profile has been successfully updated.", "success");
    },
  });

  const { mutate: deleteMember } = useDeleteMemberMutation({
    onSuccess: () => {
      setSavedChanges(true);
      logout();
      history.push("/login");
      showToast("Profile has been successfully deleted.", "success");
    },
  });

  const saveToggleGhostMode = () => {
    toggleGhostMode({});

    setConfirmGhost(false);
  };

  const saveDeleteMember = () => {
    deleteMember({});
    // setShowError(true);
    setConfirmDelete(false);
  };

  const onSubmit = (data: any) => {
    if (user?.memberId !== -1) {
      updateProfile({
        model: {
          ...data,
          breeder: user?.breeder ?? false,
          about: user?.about ?? "",
          password: "",
          profilePicture:
            profilePicture === "/images/default-avatar.png"
              ? null
              : profilePicture,
        },
      });
    }
  };

  return (
    <div className={css.AccountSettings}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND/ Dashboard"
          next="Kontoinnstillinger >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          percentage={60}
          image="/images/icons/dashboard/settings-circle.svg"
          title="Kontoinnstillinger"
          info="Profilen din på POND.no er satt sammen av informasjon hentet fra kontoen din. Jo mer informasjon du legger til her, jo mer kan vi tilpasse POND for deg."
          customWidth={""}
          logoutButton={true}
        />
        {!NewPassword && (
          <div className={css.mainContainer}>
            <div className={css.leftContainer}>
              <ProfileAvatar url={profilePicture} title={"Profilbilde"} />
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Stack gap={3} maxWidth={564}>
                <TextField
                  label="Fornavn*"
                  variant="outlined"
                  fullWidth
                  error={!!errors["firstName"]}
                  InputLabelProps={{ shrink: true }}
                  {...register("firstName", { required: true })}
                />
                <TextField
                  label="Etternavn*"
                  variant="outlined"
                  fullWidth
                  error={!!errors["lastName"]}
                  InputLabelProps={{ shrink: true }}
                  {...register("lastName", { required: true })}
                />
                <TextField
                  disabled
                  label="E-postadresse*"
                  variant="outlined"
                  fullWidth
                  {...register("email", {
                    required: "E-postadresse er påkrevd",
                    pattern: {
                      value:
                        /^[a-zA-Z0-9_.+-æøåÆØÅ]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                      message: "Ugyldig e-postadresse",
                    },
                  })}
                  error={!!errors.email}
                  InputLabelProps={{ shrink: true }}
                />
                <TextField
                  sx={{ flex: 1 }}
                  label="Telefonnummer*"
                  variant="outlined"
                  fullWidth
                  error={!!errors["phoneNumber"]}
                  InputLabelProps={{ shrink: true }}
                  {...register("phoneNumber", { required: true })}
                />
                <TextField
                  label="Adresse"
                  variant="outlined"
                  fullWidth
                  {...register("street")}
                  InputLabelProps={{ shrink: true }}
                />
                <Stack direction="row" gap={"22px"}>
                  <TextField
                    label="Postnummer"
                    variant="outlined"
                    fullWidth
                    {...register("zip")}
                    InputLabelProps={{ shrink: true }}
                  />
                  <TextField
                    label="Sted"
                    variant="outlined"
                    fullWidth
                    {...register("city")}
                    InputLabelProps={{ shrink: true }}
                  />
                </Stack>
                {/* {!!user?.breeder && (
                  <div className={css.section}>
                    <div className={css.btns}>
                      <Button
                        label="Spøkelsesmodus"
                        onClick={() => setConfirmGhost(true)}
                      />
                    </div>
                    <p>
                      Når du har valgt spøkelsesmodus vil ingen kunne kontakte
                      deg. Profilen din er fortsatt synlig men ingen vil kunne
                      sende deg meldinger, eller forespørre om valp.
                    </p>
                  </div>
                )} */}
                <div className={css.section}>
                  <div className={css.btns}>
                    <Button
                      label="Slett konto"
                      skin="error"
                      onClick={() => setConfirmDelete(true)}
                    />
                  </div>
                  <p>
                    Når du har valgt å slette kontoen din kommer alt du har lagt
                    inn på POND til å gå tapt for alltid, du vil ikke lengre
                    kunne logge inn på tjenesten og du kan ikke angre dette i
                    ettertid.
                  </p>
                </div>
              </Stack>
              <Stack direction="row">
                <Button
                  label="Tilbake"
                  skin="lightGreen"
                  onClick={() => history.goBack()}
                />
                {/* <Button
                  label="Fullfør"
                  skin="dark"
                  onClick={handleSubmit(onSubmit)}
                /> */}
                <ButtonSubmit
                  disabled={isUpdating}
                  variant="contained"
                  size="large"
                  style={{ width: "10rem" }}
                  onClick={handleSubmit(onSubmit)}
                >
                  {isUpdating ? (
                    <CircularProgress color="primary" size={24} />
                  ) : (
                    "Fullfør"
                  )}
                </ButtonSubmit>
              </Stack>
            </div>
          </div>
        )}
        {!NewPassword && !AuthUser.isExternalLogin && (
          <div className={css.contentContainer}>
            <div className={css.mainContainer}>
              <div className={css.leftContainer} />
              <div className={css.infoContainer}>
                <div className={css.section}>
                  <Button
                    label="tilbakestill passord"
                    skin="dark"
                    onClick={() => setNewPassword(true)}
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {NewPassword && !AuthUser.isExternalLogin && (
          <div className={css.mainContainer}>
            <div className={css.leftContainer}>
              <ProfileAvatar url={profilePicture} title={"Profilbilde"} />
            </div>
            <div className={css.infoContainers}>
              <div className={css.section} style={{ borderWidth: "0px" }}>
                <>
                  <h4 style={{ marginBottom: "20px" }}>Oppdater passord</h4>
                  <ChangePassword
                    onClose={() => {
                      setNewPassword(false);
                    }}
                  />
                </>
              </div>
            </div>
          </div>
        )}
        {savedChanges && (
          <Alert
            severity="success"
            onClose={() => {
              setSavedChanges(false);
            }}
            className={css.success}
          >
            Dine endringer har blitt lagret
          </Alert>
        )}
        <Footer />
      </div>
      <ConfirmationAlert
        title="Sletter konto"
        content="Er du sikker på at du vil slette kontoen din?"
        open={confirmDelete}
        handleClose={() => setConfirmDelete(false)}
        handleCancel={() => setConfirmDelete(false)}
        handleConfirm={() => saveDeleteMember()}
      />
      <ConfirmationAlert
        title="Spøkelsesmodus"
        content="Er du sikker på at du vil slå på spøkelsesmodus?"
        open={confirmGhost}
        handleClose={() => setConfirmGhost(false)}
        handleCancel={() => setConfirmGhost(false)}
        handleConfirm={() => saveToggleGhostMode()}
      />
    </div>
  );
};

export default AccountSettings;
