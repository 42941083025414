import React, { useState } from "react";
import css from "../../styles/CustomSwitch.module.scss";

// Define the props type
interface CustomSwitchProps {
  onhandlePress?: (isActive: boolean) => void;
  feedbackStatus: boolean;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({
  onhandlePress,
  feedbackStatus,
}: any) => {
  const [isActive, setIsActive] = useState(feedbackStatus);
  const toggleSwitch = () => {
    setIsActive((prev: boolean) => {
      onhandlePress(!prev);
      return !prev;
    });
  };

  return (
    <div
      className={`${css.toggleSwitch} ${isActive ? css.active : ""}`}
      onClick={toggleSwitch}
      role="switch"
      aria-checked={isActive}
    >
      <span className={`${css.toggleLabel} ${isActive ? "" : css.inactive}`}>
        {isActive ? "Ja" : "Nei"}
      </span>
      <div className={css.toggleThumb}></div>
    </div>
  );
};

export default CustomSwitch;
