import React, { useEffect } from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useHistory } from "react-router-dom";

const Step4: React.FC = () => {
  const history = useHistory();
  return (
    <Stack
      width="100vw"
      justifyContent="center"
      alignItems="center"
      bgcolor="#E2F6D1"
    >
      <Stack
        marginBottom="12px"
        padding={{ xs: "68px 16px 52px 16px" }}
        gap="32px"
        width={{ xs: "80%", sm: "80%", md: "532px" }}
        alignItems="center"
        textAlign="center"
      >
        <Typography
          fontSize={{ xs: "32px", md: "50px" }}
          fontWeight="400"
          lineHeight={{ xs: "40px", md: "63px" }}
          letterSpacing="-0.5%"
          color="#065C53"
        >
          Du har fullført verifisering!
        </Typography>
        <Typography
          fontSize={{ xs: "16px", md: "21px" }}
          fontWeight="300"
          lineHeight={{ xs: "24px", md: "42px" }}
          letterSpacing="0.5%"
          color="#065C53"
        >
          Vi skal nå gå gjennom dine opplysninger. Det kan ta opptil 5 dager før
          du mottar en bekreftelse på e-post om at verifiseringen er godkjent.
          Takk for din tålmodighet!
        </Typography>
        <img src="/images/dog.png" alt="Image" />
        <Button
          variant="contained"
          sx={{
            width: { xs: "100%", sm: "345px" },
            height: "56px",
            borderRadius: "56px",
          }}
          onClick={() => history.push("/min-pond")}
        >
          Lukk
        </Button>
      </Stack>
    </Stack>
  );
};

export default Step4;
