import { AxiosResponse } from "axios";
import { axios } from "./authenticated-axios";

export function fetcher<TData, TVariables>(
  query: string,
  variables?: TVariables
) {
  const baseUrl =
    process.env.REACT_APP_API_BASE_URL ?? "https://pond-api.codify.no";

  return async (): Promise<TData> => {
    // Set the Authorization header with the bearer token
    const token = localStorage.getItem("accessToken"); // Adjust this to how you store/retrieve your token
    const headers: Record<string, string> = token
      ? { Authorization: `Bearer ${token}` }
      : {};

    const res = await axios.post<
      { query: string; variables: TVariables | undefined },
      AxiosResponse<any>
    >(baseUrl + "/graphql", { query, variables }, { headers });

    const json = res.data;

    //@ts-ignore
    if (json.errors) {
      //@ts-ignore
      const { message } = json.errors[0];
      throw new Error(message);
    }

    //@ts-ignore
    return json.data;
  };
}
