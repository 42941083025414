import { Stack, Typography } from "@mui/material";
import Button from "../../../../components/Button";
import { useHistory } from "react-router-dom";
import useAuth from "../../../../hooks/useAuth0";
import { useEffect } from "react";

const Step4 = () => {
  const history = useHistory();
  const { user:userData, getUserInfo } = useAuth();
  
  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <Stack
      maxWidth={564}
      gap={4}
      py={2}
      px={1}
      textAlign="center"
      alignItems="center"
      margin="auto"
      marginTop="40px"
    >
      <Typography variant="h4" color="primary.main" fontWeight="400">
        Velkommen, {userData?.firstName} {userData?.lastName}!
      </Typography>
      <Typography
        color="primary.main"
        fontSize="21px"
        fontWeight="300"
        lineHeight="42px"
      >
        {userData?.breeder
          ? "Du er nå klar til å vise frem kennelen din og starte reisen med å finne de rette valpekjøperne. Gå til din kennelprofil for å se hvordan den ser ut, eller gå direkte til Min POND for å utforske dine muligheter."
          : "Nå er det bare å sette i gang med å utforske vårt rasebibliotek eller se gjennom tilgjengelige valpeannonser for å finne din neste bestevenn. Hvor vil du starte?"}
      </Typography>
      <img src="/images/dog.png" alt="dog" style={{ height: 315 }} />
      <Stack alignItems="center" gap={"18px"}>
        {userData?.breeder ? (
          <>
            <Button
              height="56px"
              label="Se din kennelprofil"
              skin="dark"
              style={{ width: 345 }}
              onClick={() => history.push(`/oppdretter/${userData?.memberId}`)}
            />
            <Button
              height="56px"
              label="Gå til min side"
              skin="outlined"
              style={{ width: 345 }}
              onClick={() => history.push("/min-pond")}
            />
          </>
        ) : (
          <>
            <Button
              height="56px"
              label="Utforsk rasebibliotek"
              skin="dark"
              style={{ width: 345 }}
              onClick={() => history.push("/hunderaser")}
            />
            <Button
              height="56px"
              label="Se valpeannonser"
              skin="outlined"
              style={{ width: 345 }}
              onClick={() => history.push("/valper-til-salgs")}
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default Step4;
