import Stack from "@mui/material/Stack";
import OnboardingSteps from "./components/Steps";
import { OnBoardingContextProvider } from "../../context/OnBoardingContext";
import useOnBoarding from "./hooks/useOnBoarding";
import "./index.scss";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth0";
import { useEffect } from "react";

const OnBoarding = () => {
  const history = useHistory();
  const { selected, setSelected, isLoading, onSuccess } = useOnBoarding();
  const { user } = useAuth();

  useEffect(() => {
    if (user.isRegisterStepsCompleted) {
      history.push("/min-pond");
    }
  }, [user]);
  return (
    <OnBoardingContextProvider>
      <Stack className="custom-register">
        {selected !== 3 && (
          <OnboardingSteps setSelected={setSelected} selected={selected} isLoading={isLoading} onSuccess={onSuccess} />
        )}
        {selected === 3 && history.push("/velkommen")}
      </Stack>
    </OnBoardingContextProvider>
  );
};

export default OnBoarding;
