import React from "react";
import css from "../../../styles/Community.module.scss";

const CommunitySection = ({ communityList }: { communityList: any }) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className={css.Community}>
      {communityList.items.map((item: any, index: number) => {
        const { title, rating, icon } = item.content.properties;

        return (
          <div key={index} className={css.gridItem}>
            <img src={baseUrl + icon[0].url} alt={title} />
            <div className={css.bold}>{rating}</div>
            <div>{title}</div>
          </div>
        );
      })}
    </div>
  );
};

export default CommunitySection;
