import React from "react";
import SwiperCore from "swiper";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import Button from "../../Button";

SwiperCore.use([Pagination, Navigation, Autoplay]);

const SliderSection = ({ sliderItems, isMobile, css }: any) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className={css.slides}>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        style={{ paddingBottom: 60 }}
        className="mySwiper"
        navigation={!isMobile}
        autoplay={true}
        speed={1000}
        pagination={{
          clickable: true,
        }}
      >
        {sliderItems.items.map((item: any) => (
          <SwiperSlide key={item.content.id}>
            <div className={css.swiperCard}>
              <picture>
                  <source
                    media="(max-width: 800px)"
                    srcSet={baseUrl + item.content.properties.image[0].url? `${baseUrl + item.content.properties.image[0].url}?width=430` : ""}
                  />
                  <img
                    src={baseUrl + item.content.properties.image[0].url? `${baseUrl + item.content.properties.image[0].url}?width=652` : ""}
                    alt={item.content.properties.title}
                  />
                </picture>               
              <div>
                <h2>{item.content.properties.title}</h2>
                <p>{item.content.properties.description}</p>
                <Button
                  label={item.content.properties.seeMoreLink[0].title}
                  onClick={() =>
                    (window.location.href =
                      item.content.properties.seeMoreLink[0].route.path)
                  }
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SliderSection;
