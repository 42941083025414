import { ReactNode } from 'react';
import { SnackbarProvider } from 'notistack';

interface IProps {
    children: ReactNode
}

const ToastProvider: React.FC<IProps> = ({children}): JSX.Element=> {
    return <SnackbarProvider anchorOrigin={{vertical: 'top', horizontal: 'center'}}>{children}</SnackbarProvider>;
};

export default ToastProvider;

