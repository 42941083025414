import { Avatar, CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import Button from "../components/Button";
import ProfileOurDogs from "../components/Profile/ProfileOurDogs";
import useIsVisible from "../hooks/useIsVisible";
import css from "../styles/Profile.module.scss";
import Modal from "react-bootstrap/Modal";
import { format } from "date-fns";
import CreateUserDialog from "../components/CreateUserDialog";

// import ClubData from "../model/ClubData";
import {
  BreederMemberDto,
  DogParentDto,
  MemberDto,
  useGetMeQueryQuery,
  useMemberQueryQuery,
} from "../api/graphql.gen";
import useAuth from "../hooks/useAuth0";
import Reference from "./Dashboard/Reference";
import { useFilteredFeedbackList } from "../hooks/useDogFeedback";
import {
  ClubItemContentResponseModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../hooks/useUmbracoQuery";
import Collapse from "@mui/material/Collapse";
import VerifiedBadge from "../components/VerifiedBadge";
import { axios } from "../api/authenticated-axios";
import { useIsMobile } from "../hooks/useIsMobile";
import DogModal from "../components/Profile/DogDetailModal";
import { Helmet } from "react-helmet";

interface RouteParams {
  userId: string;
}

const EmptyMessage = ({ label }: { label: string }) => (
  <div className={css.emptyMessage}>
    <div>{label}</div>
  </div>
);

const Profile: React.FC = () => {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);

  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const { isAuthenticated, user: authUser } = useAuth();

  const { isMobile: isMobile } = useIsMobile();

  const { data: feebackList } = useFilteredFeedbackList(parseInt(userId));

  const { data: userInfo, isLoading } = userId
    ? useMemberQueryQuery({ userId: parseInt(userId) })
    : useGetMeQueryQuery();

  const { data: clubItem } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:clubItem"],
    });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const userData = userInfo?.memberQuery as any;
  const user: BreederMemberDto = userId ? userData?.user : userData?.me;
  const isVerified = user?.isVerified;

  const { data: AgriaAgent } = useUmbracoContentItemQuery<any>(user?.kennel?.agriaAgentId ?? "");
  const myAgent = AgriaAgent?.properties;
  
  const myAds = user?.dogAds;

  const availableAd = myAds?.filter((item) => item.adType === "AVAILABLE");

  const ourDogsRef = useRef<HTMLDivElement>(null);
  const aboutUsRef = useRef<HTMLDivElement>(null);
  const puppiesRef = useRef<HTMLDivElement>(null);
  const plannedRef = useRef<HTMLDivElement>(null);
  const kennelInfo = useRef<HTMLDivElement>(null);
  const agriaRef = useRef<HTMLDivElement>(null);
  const referanser = useRef<HTMLDivElement>(null);

  const kennelIsVisible = useIsVisible(kennelInfo, "-100px");

  const [dogModal, setDogModal] = useState(false);
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [tab, setTab] = useState("1");
  const [selectedParent, setSelectedParent] = useState<DogParentDto>();

  useEffect(() => {
    if (user && !user.memberId) {
      history.push("/");
    }
  }, []);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "start",
    });
  };

  const extractFileNameFromUrl = (url: string): string | null => {
    return url.split("/").pop() || null;
  };

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const bannerImage =
    user?.kennel &&
    (user?.kennel.bannerImage == "/images/placeholder-dog.png" ||
      user?.kennel.bannerImage == "" ||
      !user.kennel.bannerImage)
      ? ""
      : JSON.parse(user?.kennel?.bannerImage ?? "{}");

  //  const profileImage = user?.profileImage == "/images/default-avatar.png" || user?.profileImage ==""? "" :
  //  JSON.parse(user?.profileImage??"");

  const isBreeder = user?.breeder ?? false;

  function convertToEnglishUrl(text: string) {
    return text
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/å/g, "aa")
      .replace(/ø/g, "oe")
      .replace(/æ/g, "ae")
      .replace(/ /g, "-");
  }
  const currentUrl = window.location.href;
  const domain = new URL(currentUrl).hostname.replace('www.', '');

  return (

    <div className={css.BreedProfile}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{user?.kennel?.name}</title>
        <meta name="description" content={user?.kennel?.name} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={"Pond.no"}
          />
          <meta property="og:url" content={currentUrl}  />
          <meta property="og:title" content={user?.kennel?.name} />
          <meta property="og:description" content={user?.kennel?.name}  />
          {user?.profileImage && (
          <meta name="og:image:secure" content={urlFor(user?.profileImage)}  />
          )}
          {user?.profileImage && (
          <meta name="og:image" content={urlFor(user?.profileImage)}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={domain} />
          <meta property="twitter:url" content={currentUrl} />
          <meta name="twitter:title" content={user?.kennel?.name} />
          <meta name="twitter:description" content={user?.kennel?.name} />
          {user?.profileImage && (
          <meta name="twitter:image:secure" content={urlFor(user?.profileImage)}></meta>
          )}
          {user?.profileImage && (
          <meta name="twitter:image" content={urlFor(user?.profileImage)}></meta>
          )}
        </Helmet>
      <div className={css.bannerContainer}>
        <div className={css.leftBanner} />
        {user?.kennel?.bannerImage ? (
          <div className={css.middleBanner}>
            {bannerImage && (
              <img
                src={
                  bannerImage
                    ? urlFor(bannerImage.url + "?width=768&height=440")
                    : ""
                }
                alt={user?.kennel?.name}
              />
            )}
          </div>
        ) : (
          <div className={css.noBanner} />
        )}
        <div className={css.rightBanner} />
      </div>
      <div className={css.kennelContainer} ref={kennelInfo}>
        <div className={css.kennelHorizontalContainer}>
          <Avatar
            src={
              user?.profileImage
                ? urlFor(user?.profileImage + "?width=190&height=190")
                : "/images/default-avatar.png"
            }
            alt="Profilbilde"
            ref={aboutUsRef}
            className={css.profilePic}
          />

          {isLoading ? (
            <Stack alignItems="center" m="32px">
              <CircularProgress color="primary" variant="indeterminate" />
            </Stack>
          ) : (
            <div className={css.kennelInfo}>
              <div className={css.VerifiedBadge}>
                {isBreeder && isVerified == 1 && (
                  <div className={css.badge}>
                    <VerifiedBadge
                      desktopHeight="45px"
                      desktopWidth="41px"
                      mobileWidth="72px"
                      mobileHeight="78px"
                    />
                  </div>
                )}
                <div className={css.kennelNames}>{user?.kennel?.name}</div>
              </div>

              <div className={css.location}>
                {user?.city ? `${user?.city}, Norge` : "Norge"}
              </div>
              <div className={css.breeds}>
                {user &&
                  user.kennel &&
                  user?.kennel?.breedersList &&
                  user?.kennel?.breedersList?.map((breed) => (
                    <div
                      key={breed}
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(
                          `/hunderaser/${convertToEnglishUrl(breed)}`
                        )
                      }
                    >
                      {breed}
                    </div>
                  ))}
              </div>
            </div>
          )}
        </div>
        <div style={{ paddingTop: 20 }}>
          {isBreeder && user.email !== authUser.email && (
            <div className={css.contact}>
              {/*<Button label="Lagre profil" skin="light" disabled />*/}
              <Button
                label="Kontakt meg"
                skin="light"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            </div>
          )}
          {isBreeder && user.email !== authUser.email && !user?.breeder && (
            <div className={css.contact}>
              <Button
                label="Send søknad"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/send-soknad/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            </div>
          )}
        </div>
      </div>
      {!kennelIsVisible && (
        <div className={css.smallKennelContainer}>
          <div className={css.infoContainer}>
            <Avatar
              src={
                user?.profileImage
                  ? urlFor(user?.profileImage + "?width=190&height=190")
                  : "/images/default-avatar.png"
              }
              alt="Profilbilde"
              className={css.profilePic}
            />
            <div className={css.kennelNames}>{user?.kennel?.name}</div>
          </div>
          <div className={css.buttonContainer}>
            {isBreeder && user.email !== authUser.email && (
              <Button
                label="Kontakt meg"
                skin="light"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            )}
            {isBreeder && user.email !== authUser.email && !user?.breeder && (
              <div className={css.contact}>
                <Button
                  label="Send søknad"
                  onClick={() =>
                    isAuthenticated
                      ? history.push(`/send-soknad/${userId}`)
                      : setShowCreateUser(true)
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
      <div className={css.container}>
        {isBreeder && (
          <div
            className={!kennelIsVisible ? css.fixedSidePanel : css.sidePanel}
          >
            <div className={css.fixedWrapper}>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(aboutUsRef)}
              >
                Om kennel
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(puppiesRef)}
              >
                Tilgjengelige valper
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(plannedRef)}
              >
                Fremtidige kull
              </div>
              <div
                className={css.panelItem}
                onClick={() => executeScroll(ourDogsRef)}
              >
                Mine hunder
              </div>
              {/* <div
                className={css.panelItem}
                onClick={() => executeScroll(referanser)}
              >
                Referanser
              </div> */}
              {isBreeder && myAgent && (
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(agriaRef)}
                >
                  Min Agria forsikringsagent
                </div>
              )}
            </div>
          </div>
        )}
        <div className={css.bottomContainer}>
          <div className={css.contentContainer}>
            <div className={css.aboutBreeder}>
              <div className={css.textContainer}>
                <h6>Om kennel</h6>
                {isLoading ? (
                  <Stack alignItems="center">
                    <CircularProgress color="primary" variant="indeterminate" />
                  </Stack>
                ) : (
                  <>
                    <p>{`${user?.firstName} ${user?.lastName}`}</p>
                    <p>{`${user?.kennel?.motivation}`}</p>
                  </>
                )}
              </div>
              <div />
              {(user?.kennel?.clubs?.length || user?.isAgriaMember) &&
                isBreeder && (
                  <Stack
                    className={css.textContainer}
                    direction={{ xs: "column" }}
                  >
                    <h6>Medlem av</h6>
                    <div className={css.clubGrid}>
                      {user?.kennel?.clubsList?.map((club) => {
                        const hasClub:
                          | ClubItemContentResponseModel
                          | undefined = clubItem?.items.find(
                          (item: any) =>
                            item?.properties?.clubName.toLowerCase() ===
                            club.toLowerCase()
                        );

                        return (
                          <div
                            className={css.clubContainer}
                            key={club}
                            onClick={() => {
                              hasClub?.properties.clubLink?.[0].url
                                ? window.open(
                                    hasClub?.properties.clubLink?.[0].url
                                  )
                                : undefined;
                            }}
                          >
                            <Avatar
                              className={css.avatar}
                              src={
                                hasClub?.properties?.clubImage?.[0]?.url // Use the found club's image if available
                                  ? baseUrl +
                                    hasClub.properties.clubImage[0].url
                                  : "/images/icons/club.svg" // Fallback if no image exists
                              }
                              alt="klubb ikon"
                            />
                            <div>{club}</div>
                          </div>
                        );
                      })}
                      {user.isAgriaMember && (
                        <div className={css.clubContainer}>
                          <Avatar
                            src="/images/agria-logo.png"
                            className={css.avatar}
                            alt="klubb ikon"
                          />
                          <div>Agria breeders club</div>
                        </div>
                      )}
                    </div>
                  </Stack>
                )}
            </div>
            {isBreeder && availableAd && (
              <div ref={puppiesRef}>
                <h6>Tilgjengelige kull</h6>
                {availableAd.length > 0 ? (
                  <div className={css.adGrid}>
                    {availableAd.map((ad) => {
                      const image =
                        ad.fileRecord && ad.fileRecord.url
                          ? `${process.env.REACT_APP_API_BASE_URL}${ad.fileRecord.url}?width=410&height=410`
                          : "/images/placeholder-dog.png";

                      return (
                        <div
                          key={ad.id}
                          className={css.adCard}
                          onClick={() => history.push(`/annonse/${ad.id}`)}
                        >
                          <img src={image} alt="Annonsebilde" />
                          <div className={css.title}>{ad.title}</div>
                          <div className={css.breed}>{ad.breed}</div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <EmptyMessage label="Denne oppdretteren har ingen tilgjengelige kull" />
                )}
              </div>
            )}
            {isBreeder && myAds && (
              <div ref={plannedRef} className={css.plannedContainer}>
                <h6>Fremtidige kull</h6>
                {myAds?.filter(
                  (item) =>
                    item.adType !== "AVAILABLE" && item.adType !== "DELETED"
                ).length ? (
                  <div className={css.upcomingContainer}>
                    {myAds
                      .filter((item) => item.adType === "PLANNED")
                      .map((ad) => (
                        <div key={ad.id}>
                          <div className={css.horizontalContainer}>
                            <div className={css.title}>Planlagte valper</div>
                            <div className={css.expected}>Forventet</div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.subTitle}>{ad?.breed}</div>
                            <div className={css.subTitle}>
                              {format(new Date(ad?.birthDate), "MMM yy")}
                            </div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.parents}>Mor</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "female"
                              )[0]?.nkkId ?? "N/A"}
                            </div>
                          </div>
                          <div
                            className={`${css.horizontalContainer} ${css.divider}`}
                          >
                            <div className={css.parents}>Far</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "male"
                              )[0]?.nkkId ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      ))}
                    {myAds
                      .filter((item) => item.adType === "COMING")
                      .map((ad) => (
                        <div key={ad.id}>
                          <div className={css.horizontalContainer}>
                            <div className={css.title}>Kommende valper</div>
                            <div className={css.expected}>Forventet</div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.subTitle}>{ad?.breed}</div>
                            <div className={css.subTitle}>
                              {format(new Date(ad?.birthDate), "MMM yy")}
                            </div>
                          </div>
                          <div className={css.horizontalContainer}>
                            <div className={css.parents}>Mor</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "female"
                              )[0]?.nkkId ?? "N/A"}
                            </div>
                          </div>
                          <div
                            className={`${css.horizontalContainer} ${css.divider}`}
                          >
                            <div className={css.parents}>Far</div>
                            <div className={css.parents}>
                              {ad?.parents?.filter(
                                (parent) => parent.gender === "male"
                              )[0]?.nkkId ?? "N/A"}
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <EmptyMessage label="Denne oppdretteren har ingen planlagte kull" />
                )}
              </div>
            )}
            {isBreeder && (
              <span>
                {user?.dogParents && user?.dogParents.length ? (
                  <span ref={ourDogsRef}>
                    <ProfileOurDogs
                      dogs={user.dogParents}
                      ref={ourDogsRef}
                      onClick={(dog) => {
                        setSelectedParent(dog);
                        setDogModal(true);
                      }}
                    />
                  </span>
                ) : (
                  <div ref={ourDogsRef} className={css.plannedContainer}>
                    <h6>Mine hunder</h6>
                    <EmptyMessage label="Denne oppdretteren har ikke lastet opp noen hunder" />
                  </div>
                )}
              </span>
            )}
            {/* {isLoading ? (
              <Stack alignItems="center" mt="32px">
                <CircularProgress color="primary" variant="indeterminate" />
              </Stack>
            ) : (
              <span ref={referanser}>
                <Reference
                  feebackList={feebackList}
                  userId={userId}
                  isMobile={isMobile}
                />
              </span>
            )} */}
            {isBreeder && myAgent && (
              <div className={css.agriaContainer} ref={agriaRef}>
                <div className={css.agentCard}>
                  <Avatar
                    className={css.avatar}
                    src={myAgent.image ? urlFor(myAgent.image[0].url) : ""}
                    alt="Bilde av agent"
                  />
                  <div>
                  <div className={css.name}>{myAgent.agentName}</div>
                  <div className={css.location}>
                    {myAgent?.location + ", Norge"}
                  </div>
                    <div className={css.button}>
                      <Button
                        label="Besøk profil"
                        onClick={() => {
                          window.analytics.track("Besøk agria agents profil");
                          window.open(myAgent.webLink ? myAgent.webLink[0].url : "");
                        }}
                      />
                    </div>
                  </div>
                </div>
                <img
                  src="/images/agria.png"
                  alt="Agra logo"
                  className={css.agriaLogo}
                />
                <ul>
                  <li>Personlig hjelp hvis du ønsker å kjøpe en forsikring</li>
                  <li>Tilgjengelige også på kvelder og i helger</li>
                  <li>Finnes i nærheten av deg</li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      {selectedParent && dogModal && (
        <DogModal
          show={dogModal}
          onHide={() => setDogModal(false)}
          selectedParent={selectedParent}
        />
      )}

      <CreateUserDialog
        open={showCreateUser}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
        onClose={() => setShowCreateUser(false)}
      />
    </div>
  );
};

export default Profile;
