import DOMPurify from "dompurify";

const ArticleContent = ({ content, css }: any) => {
  return (
    <div className={css.quote}>
      
      <br></br><br></br>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(
            content.properties.textContent.markup ?? ""
          ),
        }}
       
      />
    </div>
  );
};

export default ArticleContent;
