import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import css from "./index.module.scss";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";

interface IProps {
  show: boolean;
  onSuccess: (step: number, data: any) => void;
}

const Step2: React.FC<IProps> = ({ show, onSuccess }) => {
  const [step, setStep] = React.useState(0);
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      DogRetirementRange: 0,
      DogBreedingStartAgeRange: 0,
      HealthInsuranceDescription: "",
    },
  });

  const handleNextStep = async () => {
    const isValid = await trigger();
    if (isValid) {
      setStep(step + 1);
    }
  };

  const onSubmit = (data: any) => {
    onSuccess(2, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{display: show? "block": "none"}}>
      <Stack className={css.box} sx={{ gap: "24px" }}>
        {true && (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className={css.label}>
              I gjennomsnitt, hvor mange kull har tispene dine før de
              pensjoneres?
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ color: "#065C53" }}
                >
                  {["1-3", "3-5", "6-8", "9+"].map((label, index) => (
                    <FormControlLabel
                      key={label}
                      value={index}
                      control={<Radio />}
                      label={label}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: "700",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              )}
              name="DogRetirementRange"
              control={control}
              rules={{ required: "This field is required" }}
            />
            {errors.DogRetirementRange && (
              <p className={css.error}>Please select a range</p>
            )}
          </FormControl>
        )}
        {true && (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className={css.label}>
              Hvor gammel er tispa når du starter å avle?
            </FormLabel>
            <Controller
              render={({ field }) => (
                <RadioGroup
                  {...field}
                  value={field.value}
                  onChange={field.onChange}
                  sx={{ color: "#065C53" }}
                >
                  {["1-3", "3-5", "6-8", "9+"].map((value, index) => (
                    <FormControlLabel
                      key={value}
                      value={index}
                      control={<Radio />}
                      label={value}
                      sx={{
                        "& .MuiFormControlLabel-label": {
                          fontSize: "16px",
                          fontWeight: "700",
                        },
                      }}
                    />
                  ))}
                </RadioGroup>
              )}
              name="DogBreedingStartAgeRange"
              control={control}
              rules={{ required: "This field is required" }}
            />
            {errors.DogBreedingStartAgeRange && (
              <p className={css.error}>Please select a range</p>
            )}
          </FormControl>
        )}

        {true && (
          <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend" className={css.label}>
              Hva gjør du for at rasen(e) du avler på skal være sunn og ved god
              helse?
            </FormLabel>
            <TextField
              label="Skriv her..."
              multiline
              rows={6}
              fullWidth
              sx={{
                ".MuiInputLabel-root": {
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "18.2px",
                  color: "#82AEA9",
                  letterSpacing: "0.5%",
                },
                ".MuiOutlinedInput-root": {
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#ABC6C2",
                  },
                },
              }}
              error={!!errors["HealthInsuranceDescription"]}
              {...register("HealthInsuranceDescription", {
                required: "Dette er et påkrevd felt",
                minLength: {
                  value: 8,
                  message: "Det må være minst 8 tegn",
                },
                maxLength: {
                  value: 1000,
                  message: "det må være mindre enn 1000 tegn",
                },
              })}
              helperText={errors.HealthInsuranceDescription?.message}
            />
          </FormControl>
        )}
        {false ? (
          <Button
            variant="contained"
            sx={{
              marginTop: "40px",
              marginBottom: "60px",
              width: "100%",
              maxWidth: "399px",
              height: "56px",
              borderRadius: "56px",
            }}
            onClick={handleNextStep}
          >
            Gå videre
          </Button>
        ) : (
          <Button
            variant="contained"
            sx={{
              marginTop: "40px",
              marginBottom: "60px",
              width: "100%",
              maxWidth: "399px",
              height: "56px",
              borderRadius: "56px",
            }}
            type="submit"
          >
            Gå videre
          </Button>
        )}
      </Stack>
    </form>
  );
};

export default Step2;
