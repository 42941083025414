import { Add } from "@mui/icons-material";
import { Checkbox, FormControlLabel } from "@mui/material";
import React, { useRef, useState, useEffect, ChangeEvent } from "react";
import { useHistory } from "react-router";
import { useCreateUserMutation } from "../api/graphql.gen-old";
import Button from "../components/Button";
import Input from "../components/NewInput";
import css from "../styles/Login.module.scss";
import uploadFileToBlob from "../utils/azure-storage-blob";
import { validateName, validatePhone } from "../utils/fieldValidator";
import { v4 as uuidv4 } from "uuid";
import useAuth0 from "../hooks/useAuth0";
import Modal from "react-bootstrap/Modal";
import TermsAndCond from "./InfoPages/TermsAndCond";
import LoadingScreen from "../components/LoadingScreen";

const BecomeOwner: React.FC = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const [firstName, setFirstName] = useState<string>(user?.given_name ?? "");
  const [lastName, setLastName] = useState<string>(user?.family_name ?? "");
  const [city, setCity] = useState<string>("");
  const [street, setStreet] = useState<string>("");
  const [zip, setZip] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [firstNameError, setFirstNameError] = useState<string>("");
  const [lastNameError, setLastNameError] = useState<string>("");
  const [termsError, setTermsError] = useState<string>("");
  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [profilePicture, setProfilePicture] = useState<string>(
    user?.picture ?? ""
  );
  const [uploading, setUploading] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const [fileSelected, setFileSelected] = useState<File | null>(null);

  const history = useHistory();
  const { mutate: registerOwner } = useCreateUserMutation({
    onSuccess: () => {
      window.analytics.track("Bruker opprettet - kjøper", {
        firstName,
        lastName,
        email: user?.email,
        breeder: false,
      });
      history.push("/bruker-opprettet/kjøper");
    },
  });

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const target = event.target;
    if (target && target.files && target.files.length > 0) {
      const file = target.files[0];
      const uuidFilename = new File([file], uuidv4() + file.name);
      setFileSelected(uuidFilename);
    }
  };

  const onFileUpload = async () => {
    setUploading(true);
    await uploadFileToBlob(fileSelected);
    setProfilePicture(
      `https://pondimages.blob.core.windows.net/image-container/${fileSelected?.name}`
    );
    setFileSelected(null);
    setUploading(false);
  };

  const controlFirstStep = () => {
    if (firstName.length === 0 || lastName.length === 0) {
      setFirstNameError("Fornavn kan ikke være tomt");
    } else {
      setFirstNameError("");
    }
    if (lastName.length === 0) {
      setLastNameError("Etternavn kan ikke være tomt");
    } else {
      setLastNameError("");
    }
    if (!validatePhone(phoneNumber)) {
      setPhoneNumberError("Tlf må være 8 tall, eksempel 12345678");
    } else {
      setPhoneNumberError("");
    }
    if (!accepted) {
      setTermsError("Du må godta våre vilkår for å fortsette");
    } else {
      setTermsError("");
    }
    if (
      validateName(firstName) &&
      validateName(lastName) &&
      validatePhone(phoneNumber) &&
      accepted &&
      user?.email
    ) {
      setFirstNameError("");
      setLastNameError("");
      setPhoneNumberError("");
      registerOwner({
        firstName,
        lastName,
        email: user?.email,
        phoneNumber,
        password: "",
        breeder: false,
        about: "",
        street,
        zip,
        city,
      });
    }
  };

  useEffect(() => {
    window.analytics.page("Opprett bruker - Kjøper");
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (fileSelected) {
      onFileUpload();
    }
  }, [fileSelected]);

  return (
    <div className={css.Login}>
      <div className={css.form}>
        <h4 className={css.title}>Opprett konto</h4>
        <Input
          id="firstName"
          placeholder="Fornavn*"
          value={firstName}
          onChange={(value) => setFirstName(value)}
          type="firstName"
          className={css.input}
          error={firstNameError.length > 0}
          errorMessage={firstNameError}
        />
        <Input
          id="lastName"
          placeholder="Etternavn*"
          value={lastName}
          onChange={(value) => setLastName(value)}
          type="lastName"
          className={css.input}
          error={lastNameError.length > 0}
          errorMessage={lastNameError}
        />
        <Input
          id="phonenumber"
          placeholder="Telefonnummer*"
          value={phoneNumber}
          onChange={(value) => setPhoneNumber(value)}
          type="phonenumber"
          className={css.input}
          error={phoneNumberError.length > 0}
          errorMessage={phoneNumberError}
        />
        <Input
          id="street"
          placeholder="Adresse"
          value={street}
          onChange={(value) => setStreet(value)}
          type="street"
          className={css.input}
        />
        <Input
          id="zip"
          placeholder="Postnummer"
          value={zip}
          onChange={(value) => setZip(value)}
          type="zip"
          className={css.input}
        />
        <Input
          id="city"
          placeholder="Sted"
          value={city}
          onChange={(value) => setCity(value)}
          type="city"
          className={css.input}
        />
        <div className={css.uploadContainer}>
          <div
            className={css.imageUpload}
            onClick={() =>
              hiddenFileInput.current && hiddenFileInput.current.click()
            }
          >
            <span>Last opp profilbilde</span>
            <Add />
          </div>
          <input
            type="file"
            hidden
            ref={hiddenFileInput}
            onChange={onFileChange}
          />
          {profilePicture && (
            <div>
              <img
                src={profilePicture}
                alt="profile"
                style={{
                  height: 100,
                  width: 100,
                  marginTop: "0.5rem",
                  marginBottom: "0.5rem",
                  borderRadius: 5,
                }}
              />
              <div
                onClick={() => setProfilePicture("")}
                style={{ cursor: "pointer" }}
              >
                Slett
              </div>
            </div>
          )}
          {uploading && <LoadingScreen />}
        </div>
        <FormControlLabel
          className={css.checkboxContainer}
          control={
            <Checkbox
              checked={accepted}
              onChange={() => setAccepted(!accepted)}
            />
          }
          label={`Ved å klikke “Fortsett” sier jeg meg enig i PONDs annonseregler, bruksvilkår og personvernerklæring.`}
        />
        <p style={{ paddingTop: 16 }}>
          Les mer om informasjonen vi innhenter og hvordan vi lagrer det{" "}
          <u
            style={{ cursor: "pointer" }}
            onClick={() => setShowTermsModal(true)}
          >
            her
          </u>
          .
        </p>
        <p className={css.errorMessage}>{termsError}</p>
        <div className={css.buttonContainer}>
          <Button
            skin="lightGreen"
            label="Tilbake"
            className={css.button}
            onClick={() => history.goBack()}
          />
          <Button
            skin="dark"
            label="Fullfør"
            className={css.button}
            disabled={!accepted}
            onClick={() => controlFirstStep()}
          />
        </div>
      </div>
      <Modal
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
        animation
        size="xl"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <TermsAndCond />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BecomeOwner;
