import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Pagination, Autoplay } from "swiper/modules";
import { Fade } from "react-reveal";
import Button from "../../Button";

const TestimonialSection = ({
  title,
  testimonialPicker,
  buttonText,
  link,
  css,
  
}: any) => {
  const isMobile = window.innerWidth <= 768;

  const handleButtonClick = () => {
    // Assuming loginWithRedirect navigates to the link path
    const destinationPath = link[0]?.route?.path || "/";
    window.location.href = destinationPath;
  };

  return ( <div className={css.testimonials}>
    <Fade bottom>
      <div className={css.content}>
        {!isMobile && <h2>{title}</h2>}
        <Swiper
          slidesPerView={isMobile ? 1 : 4}
          spaceBetween={16}
          style={{ paddingBottom: 50 }}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          speed={1000}
          className="mySwiper"
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Autoplay]}
        >
            {testimonialPicker.map((testimonial: any, index: number) => (
              <SwiperSlide key={index} className={css.card}>
                <div className={css.quote} dangerouslySetInnerHTML={{ __html: testimonial.properties?.clientDescription.markup || "No quote available."}} />
                <div className={css.person}>
                  {testimonial.properties?.clientName},{testimonial.properties?.clientDesignation || "Role not available"}
                </div>
              </SwiperSlide>
            ))}
          
        </Swiper>
      </div>
      
    </Fade>
  </div>
 
  );
};

export default TestimonialSection;
