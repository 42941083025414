import * as React from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "../../../../components/Button";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import DatePickerProvider from "../../../../components/DatePickerProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import css from "../../../../styles/Steps.module.scss";
interface IProps {
  show: boolean;
  onSuccess: (step: number, data: any) => void;
}

const Step1: React.FC<IProps> = ({ show, onSuccess }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({});

  const onSubmit = (data: any) => {
    onSuccess(1, data);
  };

  const isAtLeast18YearsOld = (dateString: string): boolean => {
    const today = new Date();
    const dob = new Date(dateString);
    const age = today.getFullYear() - dob.getFullYear();
    return (
      age > 18 ||
      (age === 18 &&
        (today.getMonth() > dob.getMonth() ||
          (today.getMonth() === dob.getMonth() &&
            today.getDate() >= dob.getDate())))
    );
  };

  return (
    <form>
      <Stack
        gap={4}
        maxWidth={{ sm: 564, xs: 345 }}
        className={css.step1}
        sx={{ display: !show ? "none" : "" }}
      >
        <TextField
          label="Fornavn*"
          variant="outlined"
          fullWidth
          error={!!errors["FirstName"]}
          {...register("FirstName", {
            required: "Fornavn må oppgis.",
            pattern: {
              value: /^[A-Za-zæøåÆØÅ\s]{2,30}$/,
              message:
                "Fornavn må være mellom 2 og 30 tegn og kan kun inneholde bokstaver og mellomrom",
            },
          })}
          helperText={errors["FirstName"]?.message}
        />
        <TextField
          label="Etternavn*"
          variant="outlined"
          fullWidth
          error={!!errors["LastName"]}
          {...register("LastName", {
            required: "Etternavn må oppgis.",
            pattern: {
              value: /^[A-Za-zæøåÆØÅ\s]{2,30}$/,
              message:
                "Etternavn må være mellom 2 og 30 tegn og kan kun inneholde bokstaver og mellomrom",
            },
          })}
          helperText={errors["LastName"]?.message}
        />
        <Stack
          direction={{ xs: "column", sm: "row" }}
          gap={"22px"}
          sx={{ "> .MuiStack-root": { flex: "1", padding: 0 } }}
        >
          <TextField
            sx={{ flex: 1 }}
            label="Telefonnummer*"
            variant="outlined"
            fullWidth
            error={!!errors["PhoneNumber"]}
            {...register("PhoneNumber", {
              required: "Telefonnummer må oppgis.",
              pattern: {
                value: /^(?:\+47\s?)?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$|^\d{8}$/,
                message: "Ugyldig norsk telefonnummer",
              },
            })}
            helperText={errors["PhoneNumber"]?.message}
          />
          <Controller
            render={({ field, fieldState }) => (
              <DatePickerProvider>
                <DatePicker
                  onChange={(c: any) => field.onChange(c.format("YYYY-MM-DD"))}
                  slotProps={{
                    textField: {
                      label: "Fødselsdato*",
                      variant: "outlined",
                      fullWidth: true,
                      error: !!fieldState.error,
                      helperText: fieldState.error
                        ? fieldState.error.message
                        : "",
                      sx: {
                        "& .MuiInputLabel-shrink": {
                          transform: "translate(14px, -6px) scale(0.75)",
                        },
                      },
                    },
                  }}
                />
              </DatePickerProvider>
            )}
            name="DOB"
            control={control}
            rules={{
              required: "Fødselsdato må oppgis.",
              validate: (value) => {
                return isAtLeast18YearsOld(value) || "Du må være 18 år gammel.";
              },
            }}
          />
        </Stack>
        <TextField
          label="Adresse"
          variant="outlined"
          fullWidth
          {...register("Street", {
            maxLength: {
              value: 50,
              message: "Adresse kan ikke være lengre enn 50 tegn.",
            },
          })}
        />

        <Stack direction={{ sm: "row" }} className={css.post} gap={"22px"}>
          <TextField
            label="Postnummer"
            variant="outlined"
            fullWidth
            {...register("Zip", {
              maxLength: {
                value: 4,
                message: "Postnummer kan ikke være lengre enn 4 tegn.",
              },
              pattern: {
                value: /^\d{4}$/,
                message: "Ugyldig postnummer",
              },
            })}
          />
          <TextField
            label="Sted"
            variant="outlined"
            fullWidth
            {...register("City")}
          />
        </Stack>
        <FormControlLabel
          sx={{
            alignItems: "flex-start",
            ".MuiTypography-root": {
              fontSize: "16px",
              color: errors?.PrivacyPolicy ? "#d32f2f" : "initial",
            },
          }}
          control={
            <Controller
              render={({ field, fieldState }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  sx={{
                    "&.MuiCheckbox-root": {
                      color: fieldState.error ? "#d32f2f" : "initial",
                    },
                  }}
                />
              )}
              name="PrivacyPolicy"
              control={control}
              rules={{ required: true }}
            />
          }
          label={
            <Typography>
              Jeg bekrefter å ha lest og godkjent PONDs
              <a href="/betingelser-og-vilkar" target="_blank">
                brukervilkår
              </a>
              og
              <a href="/personvernerklæring" target="_blank">
                personvernserklæring
              </a>
              .
            </Typography>
          }
        />

        <Stack alignItems="center">
          <Button
            height="56px"
            label="Fortsett"
            skin="dark"
            style={{ width: "100%" }}
            onClick={handleSubmit(onSubmit)}
          />
        </Stack>
      </Stack>
    </form>
  );
};

export default Step1;
