import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../components/Button";
import css from "../styles/CreateAd.module.scss";

const NewAdMobile: React.FC = () => {
  const history = useHistory();

  return (
    <div className={css.CreateAd}>
      <div>
        <div className={css.sectionContainer}>
          <h4 className={css.selectAlternativeTitle}>Velg annonsetype</h4>
          <div className={css.buttonContainer}>
            <Button
              className={css.draftAd}
              label="Legg ut valpekull"
              onClick={() => history.push("/opprett-annonse")}
            />
            <Button
              className={css.draftAd}
              label="Omplasser hund"
              onClick={() => history.push("/opprett-omplassering-annonse")}
            />
          </div>
        </div>
      </div>
      <img
        src={"/images/login_image.svg"}
        alt="Person med laptop sitter foran hund"
      />
    </div>
  );
};

export default NewAdMobile;
