import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#065C53",
      light: "#065C53",
      dark: "#065C53",
    },
    secondary: {
      main: "#E2F6D1",
      light: "#E2F6D1",
      dark: "#E2F6D1",
    },
    text: {
      primary: "#065C53",
      secondary: "#065C53",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 16,
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: { borderRadius: "8px !important" },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: { color: "#065C53" },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: "24px", textTransform: "none" },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          ".Mui-active": {
            ".MuiSvgIcon-root": {
              color: "#065C53",
              ".MuiStepIcon-text": {
                fill: "#F6FAED",
              },
            },
          },
          ".MuiSvgIcon-root": {
            color: "#F6FAED",
            border: "1px solid #065C53",
            borderRadius: "50%",
            circle: {
              r: 16,
            },
            ".MuiStepIcon-text": {
              fill: "#065C53",
            },
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          left: "calc(-50% + 13px)",
          right: "calc(50% + 14px)",
        },
        line: {
          borderColor: "#065C53",
          borderTopWidth: "2px",
        },
      },
    },
  },
});
