import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Button from "../../components/Button";
import css from "../../styles/Info.module.scss";

const BecomeBreederInfo: React.FC = function () {
  useEffect(() => {
    window.analytics.page("Registrer deg som oppdretter");
  }, []);
  const history = useHistory();

  return (
    <div className={css.infoPage}>
      <h4>Registrer deg som oppdretter</h4>
      <p>
        Vi tilbyr en sikker plattform for oppdrettere som vil komme i kontakt
        med seriøse og informerte kjøpere. Frem ditt oppdrett gratis hos POND.
        Et verktøy for oppdrettere som gjør formidlingen enklere og tryggere for
        alle parter.
      </p>
      <p>
        Gjennom POND kan du fremme virksomheten din gratis og med vårt
        sorteringssystem ha full kontroll over alle forespørsler. Du kan
        planlegge og ha oversikt over valpebesøk, skreddersy dine egne
        kontrakter og føle deg trygg med vår sikre depositum- og
        betalingsordning.
      </p>
      <Button
        label="Kom i gang"
        className={css.button}
        onClick={() => history.push("/registrer/oppdretter")}
      />
      <Button
        label="Les mer"
        className={css.button}
        skin="light"
        onClick={() => history.push("/info/oppdretter")}
      />
    </div>
  );
};

export default BecomeBreederInfo;
