import React from "react";
import { Fade } from "react-reveal";
import Button from "../../Button";
import { useHistory } from "react-router-dom";

const BannerSection = ({ bannerItems, css, isAuthenticated }: any) => {
  const isMobile = window.innerWidth <= 768;
  const history = useHistory();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      window.location.reload();
    } else {
      history.push("/login");
    }
  };
  if (!bannerItems || bannerItems.items.length === 0) return null;

  const { title, description, image, imageMobile, achorPoint, redirectUrl } =
    bannerItems.items[0].content.properties;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  const backgroundUrl = isMobile
    ? baseUrl + imageMobile[0]?.url || "/images/home/rabbits-desktop.png"
    : baseUrl + image[0]?.url || "/images/home/banner.png";

  const handleRedirect = (url: any) => {
    if (url) {
      window.location.href = url;
    }
  };

  return (
    <div
      className={css.banner}
      style={{
        background: `url(${backgroundUrl})`,
        backgroundSize: "cover",
      }}
    >
      <Fade bottom delay={100}>
        <div className={isMobile ? css.bannerMobile : css.card}>
          <div>
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          <Button
            label={achorPoint}
            skin="light"
            onClick={() => handleRedirect(redirectUrl[0]?.queryString)}
          />
          <Button label={redirectUrl[0]?.title} onClick={handleButtonClick} />
        </div>
      </Fade>
    </div>
  );
};

export default BannerSection;
