import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button";
import css from "../../styles/AboutPond.module.scss";
import communityCss from "../../styles/Community.module.scss";
import { Community } from "../../components/Community";
//import { SanityComponent, getPosts, Section } from "../../utils/sanity-common";
import {
  ApiBlockItem,
  HjemContentModel,
  IApiContentResponsePagedViewModel,
  OmOssContentModel,
  TextBackgroundPropertiesModel,
  TextWithDescriptionPropertiesModel,
} from "../../api/umbracoApi";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import TextWithImage from "../../components/UmbracoComponents/About/TextWithImage";
import { useIsMobile } from "../../hooks/useIsMobile";
import TextWithButton from "../../components/UmbracoComponents/About/TextBackground";
import TextWithDescription from "../../components/UmbracoComponents/About/TextWithDescription";
import { isLeftHandSideExpression } from "typescript";
import { Helmet } from "react-helmet-async";

const AboutPond: React.FC = function () {
  const [teamModal, setTeamModal] = useState(false);
  const { data: contents } =
    useUmbracoContentItemQuery<any>("om-oss");

    const { data: communitySection } = useUmbracoContentItemQuery<HjemContentModel>(
      ""
   );


   const community: any=communitySection?.properties?.pageSections?.items.find(
      (item:any)=>{ 
        return item.content.contentType === "communitySection" ; 
      }
     );

   
  const { isMobile } = useIsMobile();
  const { data: teamItems } =
  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
    filter: ["contentType:teamItem"],
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const currentUrl = window.location.href;
  return (
    <div className={css.AboutPond}>
      <Helmet>
      <meta charSet="utf-8" />
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={
            contents?.properties?.metaKeywords
              ? contents?.properties?.metaKeywords
              : "Pond.no"
          }
          />
          <meta property="og:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl}  />
          <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta property="og:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"}  />
          {contents?.properties?.ogImage && (
          <meta name="og:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {contents?.properties?.ogImage && (
          <meta name="og:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeywords : "Pond.no"} />
          <meta property="twitter:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl} />
          <meta name="twitter:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta name="twitter:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {contents?.properties?.ogImage && (
          <meta name="twitter:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
          {contents?.properties?.ogImage && (
          <meta name="twitter:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
        </Helmet>
      <div className={css.infoPage}>
        {/* {sections?.map((section, index) => (
          <div key={index}>{SanityComponent(section)}</div>
        ))} */}

        {contents?.properties?.pageContent?.items?.map(
          (item: ApiBlockItem, index: number) => {
            switch (item.content.contentType) {
              case "textWithImage":
                return (
                  <TextWithImage
                    properties={item.content.properties}
                    isMobile={isMobile}
                  />
                );
              case "textBackground":
                return (
                  <TextWithButton
                    properties={
                      item.content.properties as TextBackgroundPropertiesModel
                    }
                  />
                );

              case "textWithDescription":
                return (
                  <TextWithDescription
                    properties={
                      item.content
                        .properties as TextWithDescriptionPropertiesModel
                    }
                  />
                );
              default:
                return (
                  <div key={index}>
                    <p>Unsupported content type: {item.content.contentType}</p>
                  </div>
                );
            }
            // <div key={item.id}>
            //   <RenderAboutPage content={item.content} isMobile={isMobile} />
            // </div>;
          }
        )}
      </div>
      <div className={css.content}>
        <div className={css.community}>
          {/* <h3>Vårt felleskap</h3> */}
          <h3>{community?.content?.properties?.title}</h3>
          <div className={communityCss.Community}>
            {community?.content?.properties?.communityList.items.map((item:any,index:any)=>{
                
                return(
                  <div key={index} className={communityCss.gridItem}>
                <object type="image/svg+xml" data={baseUrl + item.content.properties.icon[0].url} >
                  {item.content.properties.title}
                </object>
                <div className={communityCss.bold}>{item.content.properties.rating}</div>
                <div>{item.content.properties.title}</div>
              </div>

                );
                

          })}
            

            
          </div>
          
          {/* <Community /> */}
        </div>
      </div>

      <Modal
        show={teamModal}
        onHide={() => setTeamModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={css.teamModal}>
            <h3>Møt teamet vårt!</h3>
            <div className={css.teamGrid}>
            {teamItems?.items.map((member:any) => (
                <div key={member.properties.personName} className={css.teamMember}>
                  <img src={baseUrl + member.properties.image[0].url} alt={member.properties.personName} />
                  <div className={css.name}>{member.properties.personName}</div>
                  <div>{member.properties.designation}</div>
                  <div>{member.properties.email ? member.properties.email : "-"}</div>
                </div>
              ))}
            </div>
            <Button label="Gå tilbake" onClick={() => setTeamModal(false)} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AboutPond;
