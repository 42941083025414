const ArticleImages = ({ content, css, baseUrl }: any) => {
  return (
    <div className={css.imageContainer}>
      {content.properties.images &&
        content.properties.images.map((item: any, index: number) => (
          <div key={index}>
            <img
              src={baseUrl + item.url+"?width=556"}
              alt={item.name}
              className={css.bannerImage}
            />
          </div>
        ))}
    </div>
  );
};

export default ArticleImages;
