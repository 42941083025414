import { useState, useEffect } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
const useIsVisible = (element: any, rootMargin: string): boolean => {
  const [isVisible, setState] = useState(false);

  useEffect(() => {
    if (element.current) {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setState(entry.isIntersecting);
        },
        { rootMargin }
      );

      element.current && observer.observe(element.current);

      //return () => observer.unobserve(element.current);
    }
  }, []);

  return isVisible;
};

export default useIsVisible;
