import { AxiosResponse } from "axios";
import { axios } from "../../authenticated-axios"
import { FileType } from "../../../enums";
import { UploadedMediaModel } from "../../../model/UploadedMediaModel";

const uploadFile = async (file: File, fileType: FileType, fileId = "")=> {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType.toString()); 
        formData.append('fileId', fileId);
        const response : AxiosResponse<UploadedMediaModel> = await axios.post('/file/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response.data;
    } catch {
        return null;
    }
}

const uploadAvatar = async (file: File, fileType: FileType)=> {
    try {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('fileType', fileType.toString()); 
        const response : AxiosResponse<UploadedMediaModel> = await axios.post('/memberlist/avatar/upload', formData, { headers: { 'Content-Type': 'multipart/form-data' } });
        return response.data;
    } catch {
        return null;
    }
}

const deleteFile = async (fileId: number)=> {
    try {
        const response : AxiosResponse<any> = await axios.post('/file/deleteFile', fileId);
        return response.data;
    } catch {
        return null;
    }
}

export {
    uploadFile,
    deleteFile,
    uploadAvatar
}