import React from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { IconButton, Typography, Button } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UploadButton from "./UploadButton";
import css from "../styles/Miljo.module.scss";
import ClearIcon from '@mui/icons-material/Clear';

const steps = ["Åpenhet", "Kunnskap", "Miljo"];

const Miljo: React.FC = () => {
  const [selected] = React.useState(2);

  return (
    <>
      <Stack
        width="100vw"
        justifyContent="flex-start"
        alignItems="center"
        bgcolor="#E2F6D1"
        padding="16px"
      >
        <Stack
          className={css.backicon}
          direction="row"
          alignItems="center"
          width="100%"
          paddingX="16px"
        >
          <Stack
            className={css.backicon}
            direction="row"
            alignItems="center"
            width="100%"
          >
            <IconButton sx={{ color: "#5C8E89" }}>
            <ArrowBackIosIcon style={{ fontSize: "16px" }} />
          </IconButton>
          <Typography
            variant="body2"
            fontWeight="500"
            sx={{ color: "#5C8E89" }}
          >
            Tilbake
          </Typography>
        </Stack>

          <div className={css.crossIcon}>
            <IconButton sx={{ color: "#5C8E89" }}>
              <ClearIcon sx={{ fontSize: "16px" }} />
            </IconButton>
            <Typography variant="body2" fontWeight="500" sx={{ color: "#5C8E89" }}>
              Lukk
            </Typography>
          </div>
        </Stack>

        <Stack
          gap={"28px"}
          sx={{
            width: "394px",
            marginTop: "-25px",
            minHeight: "195px",
          }}
        >
          <h3 className={css.title}
          >
          Miljo
          </h3>

          <Stepper activeStep={selected} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": {
                      marginTop: "0px !important",
                      fontWeight: 500,
                      "&.Mui-active": {
                        fontWeight: 600,
                      },
                    },
                    ".MuiStepLabel-iconContainer": { height: "28px" },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Stack>
      <Stack
        width="100vw"
        justifyContent="flex-start"
        alignItems="center"
        marginBottom="57px"
        padding={{ xs: "16px", sm: "32px" }}
      >
        <div className={css.Miljo}>
          <div className={css.card}>
            <div className={css.iconText}>
              <div className={css.icon}>1</div>
              <div className={css.cardText}>
                <Typography className={css.title}>
                  Bilde av valpebinge
                </Typography>
                <Typography className={css.desc}>
                  Last opp bilde av din valpebinge som viser det trygge og sunne miljoet valpene vokser opp i.
                </Typography>
              </div>
            </div>
          </div>
        </div>

        <Stack
          width="100%"
          spacing={2}
          sx={{ maxWidth: "487px", marginTop: "20px" }}
        >
          <Typography
            variant="subtitle1"
            fontWeight="600"
            sx={{ color: "#065C53", textAlign: "left" }}
          >
            Last opp bilde av valpebinge:
          </Typography>

          <UploadButton
            sx={{
              backgroundColor: "secondary.main",
              "&:hover": {
                backgroundColor: "secondary.main",
              },
              h6: {
                color: "#065C53",
              },
              p: {
                color: "#555555",
              },
              height: "200px",
              width: "100%",
              maxWidth: "487px",
              borderRadius: "10px",
            }} accept={""} onChange={(e:any)=> console.log("first", e)}
            />
          <Button
            variant="contained"
            disabled
            sx={{
              width: "100%",
              maxWidth: "345px",
              height: "56px",
              borderRadius: "56px",
            }}
          >
            Fullfør
          </Button>
        </Stack>
      </Stack>
    </>
  );
};

export default Miljo;
