import Axios from "axios";
import { AuthApi } from "./AuthApi";

export const BASEURL =
  process.env.REACT_APP_API_BASE_URL + "/api" ??
  "https://pond-api.codify.no/api";
export const axios = Axios.create({
  baseURL: BASEURL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
  timeout: 60000,
});

export const authApi = new AuthApi(axios);

// axios.interceptors.request.use(authApi.requestInterceptor);

// axios.interceptors.response.use(authApi.responseInterceptor);
