import React from "react";
import css from "../styles/Input.module.scss";

export interface Props {
  value: string;
  rows?: number;
  cols?: number;
  maxLength?: number;
  onChange: (value: string) => void;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  onBlur?: () => void;
}

const TextArea: React.FC<Props> = function ({
  className,
  onChange,
  errorMessage,
  ...rest
}) {
  return (
    <div className={css.Input}>
      <textarea
        className={`${css.container} ${className}`}
        onChange={({ target: { value } }) => onChange(value)}
        {...rest}
      />
      <div className={css.error}>{errorMessage}</div>
    </div>
  );
};

export default TextArea;
