import React, { useEffect } from "react";
import css from "../../styles/Login.module.scss";
import { useHistory } from "react-router";
import LoadingScreen from "../../components/LoadingScreen";

const ApplicationConfirmation: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    history.push("/min-pond/mine-soknader");
  }, []);

  return (
    <div className={css.Login}>
      <LoadingScreen />
    </div>
  );
};

export default ApplicationConfirmation;
