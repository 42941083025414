import * as React from "react";
import { SxProps, Theme, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton, Stack } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
});

interface IProps {
    label?: string;
    subLabel?: string;
    accept: string;
    onChange: any;
    sx?: SxProps<Theme>;
}

const UploadButton: React.FC<IProps> = (props) => {
    const { label, subLabel, accept, onChange, sx } = props;
    const [selected, setSelected] = React.useState<File>();
    const inputRef = React.useRef<HTMLInputElement>(null);

    return (
        <Button className="c-upload-btn" component="label" variant="contained" startIcon={<img src="/images/icons/upload-icon.svg" />}
            sx={{
                flexDirection: selected? "row": "column",
                border: "1px dashed",
                borderRadius: "8px",
                gap: "8px",
                ...sx,
            }}
        >
            <VisuallyHiddenInput ref={inputRef} type="file" accept={accept}
                onChange={e=> {
                    const file = e.target.files?.[0];
                    file && setSelected(file); onChange(file);
                }}
            />
            <Stack alignItems="center" sx={{h6: {maxWidth: {xs: 198, sm: 400}}}}>
                <h6 style={{overflow: "hidden", textOverflow: "ellipsis"}}>{selected? selected.name:(label || "Last opp filer")}</h6>
                <p style={{ fontSize: 12, fontWeight: 400 }}>
                    {selected? "Last opp":(subLabel || "Aksepterte formater: JPEG, PNG")}
                </p>
            </Stack>
            {selected && <IconButton aria-label="delete" size="small"
                onClick={e=> {
                    e.preventDefault();
                    setSelected(undefined);
                    onChange(undefined);
                    if (inputRef.current) {
                        inputRef.current.value = "";
                    }
                }}>
                <DeleteIcon color="error" fontSize="inherit" />
            </IconButton>}
        </Button>
    );
};

export default UploadButton;
