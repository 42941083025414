import React, { useEffect } from "react";
import css from "../../styles/Info.module.scss";
import DOMPurify from "dompurify";
import {
  useUmbracoContentItemQuery,
} from "../../hooks/useUmbracoQuery";
import {
  TextPageContentModel,
} from "../../api/umbracoApi";
import { Helmet } from "react-helmet-async";

const TermsOfUse: React.FC = function () {
 const { data: contents } = useUmbracoContentItemQuery<any>(
    "betingelser-og-vilkar"
  );
  useEffect(() => {
    window.analytics.page("Brukervilkår for POND");
  }, []);
  const sanitizedContent = DOMPurify.sanitize(
    contents?.properties?.pageBody?.markup ?? ""
  );
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  function urlFor(source: any) {
    return baseUrl + source;
  }
  const currentUrl = window.location.href;
  return (
    <>
     <Helmet>
      <meta charSet="utf-8" />
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={
            contents?.properties?.metaKeywords
              ? contents?.properties?.metaKeywords
              : "Pond.no"
          }
          />
          <meta property="og:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl}  />
          <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta property="og:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"}  />
          {contents?.properties?.ogImage && (
          <meta name="og:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {contents?.properties?.ogImage && (
          <meta name="og:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeywords : "Pond.no"} />
          <meta property="twitter:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl} />
          <meta name="twitter:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta name="twitter:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {contents?.properties?.ogImage && (
          <meta name="twitter:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
          {contents?.properties?.ogImage && (
            <meta name="twitter:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
            )}
        </Helmet>
    <div className={css.infoPage}>
      <h1>{contents?.properties?.pageTitle}</h1>
      <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
    </div>
    </>
  );
};

export default TermsOfUse;
