import React from "react";
import { Stack, Box, IconButton, Typography, Stepper, Step, StepLabel, Button, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ClearIcon from "@mui/icons-material/Clear";
import css from "../styles/Kunnskap.module.scss";
import { useHistory } from "react-router-dom";

const steps = ["Åpenhet", "Kunnskap", "Miljo"];

const Kunnskap: React.FC = () => {
  const [selected] = React.useState(1);
  const history = useHistory();

  const handleStepClick = (index: number) => {
    const routes = ["/openhet", "/kunnskap", "/miljo"];
    history.push(routes[index]);
  };
  return (
    <>
      <Stack
        width="100vw"
        justifyContent="flex-start"
        alignItems="center"
        bgcolor="#E2F6D1"
        padding="16px"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          paddingX="16px"
        >
          <Stack
            className={css.backicon}
            direction="row"
            alignItems="center"
            width="100%"
          >
            <IconButton sx={{ color: "#5C8E89" }}>
              <ArrowBackIosIcon style={{ fontSize: "16px" }} />
            </IconButton>
            <Typography variant="body2" fontWeight="500" sx={{ color: "#5C8E89", marginLeft:"-8px" }}>
              Tilbake
            </Typography>
          </Stack>

          <div className={css.crossIcon}>
            <IconButton sx={{ color: "#5C8E89" }}>
              <ClearIcon sx={{ fontSize: "16px" }} />
            </IconButton>
            <Typography variant="body2" fontWeight="500" sx={{ color: "#5C8E89" }}>
              Lukk
            </Typography>
          </div>
        </Stack>

        <Stack
          gap={"28px"}
          sx={{
            width: "394px",
            marginTop: "-25px",
            minHeight: "195px",
          }}
        >
          <h3 className={css.title}
        >
          Kunnskap
        </h3>

          <Stepper activeStep={selected} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={label} onClick={() => handleStepClick(index)}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": {
                      marginTop: "0px !important",
                      fontWeight: 500,
                      "&.Mui-active": {
                        fontWeight: 600,
                      },
                    },
                    ".MuiStepLabel-iconContainer": { height: "28px" },
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Stack>

      <Box className={css.box} sx={{ gap: "10px" }}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" className={css.label}>
            I gjennomsnitt, hvor mange kull har tispene dine før de pensjoneres?
          </FormLabel>

          <RadioGroup defaultValue="1-3" sx={{ color: "#065C53" }}>
            {["1-3", "3-5", "6-8", "9+"].map((value) => (
              <FormControlLabel
                key={value}
                value={value}
                control={<Radio />}
                label={value}
                sx={{
                  "& .MuiFormControlLabel-label": {
                    fontSize: "16px",
                    fontWeight: "700",
                  },
                }}
              />
            ))}
          </RadioGroup>

          <Button
            variant="contained"
            sx={{
              marginTop: "40px",
              marginBottom: "60px",
              width: "100%",
              maxWidth: "399px",
              height: "56px",
              borderRadius: "56px",
              backgroundColor: "#065C53",
            }}
          >
            Gå videre
          </Button>
        </FormControl>
      </Box>
    </>
  );
};

export default Kunnskap;
