import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { axios } from "../../../api/authenticated-axios";
import useToast from "../../../components/Toast/hooks";


const useVerifyEmail = ()=> {
    const { showToast } = useToast();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = params.get("id");
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [isEmailVerifying, setIsEmailVerifying] = useState(false);
    useEffect(()=> {
        verify();
    }, []);

    const verify = async ()=> {
        try {
            setIsEmailVerifying(true);
            const res: any = await axios.get(`memberlist/verifyemail?id=${userId}`);
            setIsEmailVerifying(false);
            // localStorage.setItem('accessToken', res.data.accessToken || '');
            // localStorage.setItem('refreshToken', res.data.refreshToken || '');
            setIsEmailVerified(true);
        } catch(e: any) {
            showToast("Your email verification link has been expired!", "error");
            setIsEmailVerified(false);
        }
    };

    return { userId, verify, isEmailVerified, isEmailVerifying };
};

export default useVerifyEmail;