import css from "../styles/BreedOverview.module.scss";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import React from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { Close } from "@mui/icons-material";
import Button from "./Button";

interface SearchFilterProps {
  onClose?: () => void;
  selectSize: (sizes: string[]) => void;
  selectedSizes: string[];
  sizeData: any;
  selectActivity: (activities: string[]) => void;
  selectedActivities: string[];
  activityData: any;

  selectCoop: (activities: string[]) => void;
  selectedCoops: string[];
  coopData: any;

  selectFurCare: (activities: string[]) => void;
  selectedFurCares: string[];
  furCareData: any;

  setFilterOptions: (options: string[]) => void;
  filterOptions: string[];
}

const SearchFilter: React.FC<SearchFilterProps> = ({
  onClose,
  selectSize,
  selectedSizes,
  sizeData,
  selectActivity,
  selectedActivities,
  activityData,
  
  selectCoop,
  selectedCoops,
  coopData,

  selectFurCare,
  selectedFurCares,
  furCareData,
  setFilterOptions,
  filterOptions,
}: 
SearchFilterProps) => {
  const { isMobile } = useIsMobile(1024);
  const sizeFilterLabel = "Størrelse";
  const activityFilterLabel = "Aktivitetsnivå";
  const coopFilterLabel = "Samarbeid";
  const furCareFilterLabel = "Pelspleie";
  const onCheckboxChange = (
    selectedItems: string[],
    currentItem: string,
    filterLabel: string,
    setItem: any
  ) => {
    if (selectedItems.includes(currentItem)) {
      const result = selectedItems.filter((i: string) => i !== currentItem);
      setItem(result);
      const filter = filterOptions.filter(
        (i) => i !== `${filterLabel}: ${currentItem}`
      );
      setFilterOptions(filter);
    } else {
      setItem([...selectedItems, currentItem]);
      setFilterOptions([...filterOptions, `${filterLabel}: ${currentItem}`]);
    }
  };

  const renderFilter = (
    list: any,
    filterLabel: string,
    selectedItems: string[],
    selectItem: any
  ) => {
    return (
      <div className={css.searchFiltersSection}>
        <div className={css.searchFiltersTitle}>{filterLabel}</div>
        <FormGroup>
          {list?.map((item: any) => {
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <Checkbox
                    checked={selectedItems.includes(item.name)}
                    onChange={(e) =>
                      onCheckboxChange(
                        selectedItems,
                        item.name,
                        filterLabel,
                        selectItem
                      )
                    }
                  />
                }
                label={item.name}
              />
            );
          })}
        </FormGroup>
      </div>
    );
  };

  return (
    <div className={css.searchFilters}>
      <div className={css.closeFilter}>
        {isMobile && (
          <Close
            fontSize="large"
            onClick={onClose}
            className={css.closeButton}
          />
        )}
      </div>
     
      {sizeData && renderFilter(
        sizeData,
        sizeFilterLabel,
        selectedSizes,
        selectSize
      )}
      {activityData && renderFilter(
        activityData,
        activityFilterLabel,
        selectedActivities,
        selectActivity
      )}
      {coopData && renderFilter(
        coopData,
        coopFilterLabel,
        selectedCoops,
        selectCoop
      )}
      {furCareData && renderFilter(
        furCareData,
        furCareFilterLabel,
        selectedFurCares,
        selectFurCare
      )}
      {isMobile && (
        <div className={css.searchButtonContainer}>
          <Button
            label="Søk"
            className={css.searchButton}
            skin="light"
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
};

export default SearchFilter;
