import React, { useState } from "react";
import { Stack, Typography, ToggleButtonGroup, Button } from "@mui/material";
import SubscriptionSuccessCard from "./SubscriptionSuccessCard.tsx";

const plans = [
  {
    title: "Gratis",
    description: "For deg som ønsker en enkel start. Utforsk grunnleggende funksjoner uten kostnad.",
    price: "Kr. 0",
    duration: "For alltid",
    features: [
      "Egen hjemmeside (kennelprofil)",
      "Planlagt/forventet kull",
      "Valpeannonser",
      "Rasebibliotek",
      "Varsle potensielle valpekjøpere",
      "Motta valpesøknader",
      "Avlshunder",
    ],
    unavailableFeatures: [
      "Utvidet kennelprofil",
      "Tilbakemeldinger",
      "Prioritert kundeservice",
      "Ekspertstøtte",
    ],
    buttonText: "Bli med gratis",
    buttonColor: "#065C53",
  },
  {
    title: "Premium",
    description: "For deg som ønsker avanserte funksjoner og bedre støtte.",
    price: "Kr. 89",
    duration: "Månedlig",
    features: [
      "Egen hjemmeside (kennelprofil)",
      "Planlagt/forventet kull",
      "Valpeannonser",
      "Rasebibliotek",
      "Varsle potensielle valpekjøpere",
      "Motta valpesøknader",
      "Utvidet kennelprofil",
    ],
    unavailableFeatures: [],
    availableFeatures: [
      "Utvidet kennelprofil",
      "Tilbakemeldinger",
      "Prioritert kundeservice",
      "Ekspertstøtte",
    ],
    buttonText: "Velg Premium",
    buttonBgColor: "#065C53",
    buttonColor: "#FFFFFF",
  },
];

const SubscriptionSuccessForm: React.FC = () => {
  const [subscriptionType, setSubscriptionType] = useState<string>("Månedlig");

  return (
    <Stack
      marginBottom="45px"
      width="100vw"
      justifyContent="flex-start"
      alignItems="center"
      bgcolor="#E2F6D1"
      padding={{ xs: "16px", md: "32px" }}
    >
         <Stack
          width="100%"
          maxWidth="898px"
          gap={{ xs: "30px", md: "30px" }}
          justifyContent="center"
          padding="16px"
        >
          <Typography
            fontSize={{ xs: "40px", md: "80px" }}
            fontWeight="700"
            lineHeight={{ xs: "55px", md: "110px" }}
            letterSpacing="-1.5px"
            textAlign="center"
            color="#065C53"
          >
            Velg et abonnement
          </Typography>
          <Typography
            fontSize={{ xs: "18px", md: "26px" }}
            fontWeight="400"
            lineHeight={{ xs: "28px", md: "42px" }}
            textAlign="center"
            color="#065C53"
          >
            Finn det abonnementet som gir deg de funksjonene og fordelene du
            trenger for å administrere din kennel på best mulig måte.
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            gap="20px"
            flexWrap="wrap"
          >
            <ToggleButtonGroup
              value={subscriptionType}
              exclusive
              onChange={(event, newValue) => setSubscriptionType(newValue)}
              sx={{
                width: "310px",
                height: "56px",
                border: "2px solid #065C53",
                backgroundColor: "#FFFFFF",
                padding: "6px",
                borderRadius: "8px",
              }}
            >
              <Button
                value="Månedlig"
                sx={{
                  width: "117px",
                  borderRadius: "8px",
                  border: "none",
                  padding: "10px 16px",
                  backgroundColor:
                    subscriptionType === "Månedlig" ? "#065C53" : "transparent",
                  color: subscriptionType === "Månedlig" ? "#FFFFFF" : "#065C53",
                  "&:hover": {
                    backgroundColor: "#065C53",
                    color: "#FFFFFF",
                  },
                }}
              >
                Månedlig
              </Button>
              <Button
                value="Årlig (Spar 20%)"
                sx={{
                  height: "44px",
                  borderRadius: "8px",
                  border: "none",
                  padding: "10px 16px",
                  color:
                    subscriptionType === "Årlig (Spar 20%)"
                      ? "#FFFFFF"
                      : "#065C53",
                  backgroundColor:
                    subscriptionType === "Årlig (Spar 20%)"
                      ? "transparent"
                      : "transparent",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  letterSpacing: "-0.4px",
                }}
              >
                Årlig (Spar 20%)
              </Button>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
      <Stack
        direction="row"
        flexWrap="wrap"
        gap="20px"
        width="100%"
        maxWidth="1450px"
        justifyContent="center"
        padding={{ xs: "16px", md: "32px" }}
      >
        {plans.map((plan, index) => (
          <SubscriptionSuccessCard
            key={index}
            title={plan.title}
            description={plan.description}
            price={plan.price}
            duration={plan.duration}
            features={plan.features}
            unavailableFeatures={plan.unavailableFeatures}
            availableFeatures={plan.availableFeatures || []}
          >
            <Button
              sx={{
                borderRadius: "56px",
                border: "1px solid #065C53",
                height: "56px",
                fontWeight: "700",
                letterSpacing: "1.5%",
                color: plan.buttonColor,
                backgroundColor: plan.buttonBgColor,
                "&:hover": {
                  backgroundColor: plan.buttonBgColor,
                  opacity: 0.8,
                },
              }}
            >
              {plan.buttonText}
            </Button>
          </SubscriptionSuccessCard>
        ))}
      </Stack>
    </Stack>
  );
};

export default SubscriptionSuccessForm;
