import { format } from "date-fns";
import useAuth0 from "../../hooks/useAuth0";
import Button from "../../components/Button";
import Input from "../../components/NewInput";
import { DogBreeds } from "../../model/DogBreeds";
import css from "../../styles/NewDog.module.scss";
import { useIsMobile } from "../../hooks/useIsMobile";
import { useHistory, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import DatePickerProvider from "../../components/DatePickerProvider";
import { Button as ButtonSubmit, Stack } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Select,
  FormControl,
  InputLabel,
  Autocomplete,
  TextField,
  Alert,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import {
  Availability,
  Gender,
  useAdPuppiesQueryQuery,
  useCreateDogAdMutationMutation,
  useFindMyDogsQueryQuery,
  useMyAdsWithParentsQuery,
} from "../../api/graphql.gen";
import { deleteFile, uploadFile } from "../../api/services/file";
import { FileType } from "../../enums";
import ProfileImage from "../../components/ProfileImage";
import dayjs from "dayjs";

interface RouteParams {
  type: string;
  id: string;
}

enum puppyProperty {
  name = "name",
  registration = "registration",
  color = "color",
  gender = "gender",
  image = "image",
  availability = "availability",
}

const NewPuppy: React.FC = () => {
  const defaultImage = "/images/placeholder-dog.png";
  const [Loading, setLoading] = useState(false);
  const { type } = useParams<RouteParams>();
  const { isMobile } = useIsMobile();
  const [image, setImage] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [other, setOther] = useState(false);
  const [numberOfPuppies, setNumberOfPuppies] = useState("0");
  const [names, setNames] = useState<string[]>([]);
  const [colors, setColors] = useState<string[]>([]);
  const [genders, setGenders] = useState<string[]>([]);
  const [registrationNumbers, setRegistrationNumbers] = useState<string[]>([]);
  const [statuses, setStatuses] = useState<string[]>([]);
  const [images, setImages] = useState<string[]>([]);
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const { id } = useParams<RouteParams>();
  const { isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const [fileSelectedArray, setFileSelectedArray] = useState<(File | null)[]>(
    Array(parseInt(numberOfPuppies || "0")).fill(null)
  );
  const [isImageRemovedArray, setIsImageRemovedArray] = useState<boolean[]>(
    Array(parseInt(numberOfPuppies || "0")).fill(false)
  );
  const [imagesArray, setImagesArray] = useState<string[]>(
    Array(parseInt(numberOfPuppies || "0")).fill("")
  );

  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  // UI/form management
  const [uploading, setUploading] = useState(false);

  const { mutate: createAd } = useCreateDogAdMutationMutation({
    onSuccess: () => {
      history.replace("/min-pond/mine-valper");
      setLoading(false);
    },
    onError: () => setLoading(false),
  });

  useEffect(() => {
    window.analytics.page("Ny valpeannonse");
  }, []);

  const { data: myDogs } = useFindMyDogsQueryQuery();
  const { data: myAds } = useMyAdsWithParentsQuery();
  const { data: adPuppies } = id
    ? useAdPuppiesQueryQuery({ adId: parseInt(id) })
    : { data: null };

  const myDogAds = myAds?.dogAdQuery.myAdsWithParents;
  const myDogParents = myDogs?.dogParentQuery.findMyDogs;
  const myAdPuppies = adPuppies?.puppyQuery.adPuppies;

  const ad = myDogAds?.find((item) => item.id === parseInt(id));
  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, [isAuthenticated]);
  useEffect(() => {
    if (id) {
      const prevFather = ad?.parents?.find((item) => item.gender === "male");
      const prevMother = ad?.parents?.find((item) => item.gender === "female");
      if (ad) {
        setImage(
          ad.fileRecord?.url
            ? `${process.env.REACT_APP_API_BASE_URL}${ad.fileRecord?.url}?width=410&height=410`
            : "/images/placeholder-dog.png"
        );
        setValue("healthCheck", !!ad.healthCheck);
        setValue("wormTreatment", !!ad.wormTreatment);
        setValue("vaccinated", !!ad.vaccinated);
        setValue("pedigree", !!ad.pedigree);
        setValue("puppyPack", ad.puppyPack ?? false);
        setValue("expLitter", ad.expLitter);
        setValue("advising", ad.advising ?? false);
        setValue("breed", ad.breed);
        setValue("birthDate", format(new Date(ad.birthDate), "yyyy-MM-dd"));
        setValue("idTag", ad.idTag);
        /* other checkbox */
        setOther(ad.other);
        setValue("other", ad.other ?? false);
        setValue("otherText", ad.otherText);
        setValue("father", prevFather ?? "");
        setValue("mother", prevMother ?? "");
        setValue("adName", ad.title);

        setNumberOfPuppies(
          ad?.numberOfPuppies?.toString() ??
            myAdPuppies?.length.toString() ??
            "1"
        );
        setValue(
          "numberOfPuppies",
          ad?.numberOfPuppies?.toString() ??
            myAdPuppies?.length.toString() ??
            "1"
        );
        setValue("price", ad?.price?.toString() ?? "");
        setValue(
          "readyForDelivery",
          format(new Date(ad.readyForDelivery), "yyyy-MM-dd")
        );
        setValue("otherDetails", ad?.expLitter ?? "");
      }
    }
  }, [myDogAds, id]);
  const availabilityLabels: { [key in Availability]: string } = {
    [Availability.Available]: "Tilgjengelig",
    [Availability.Reserved]: "Reservert",
    [Availability.Sold]: "Solgt",
    [Availability.Coming]: "",
    [Availability.Deleted]: "",
    [Availability.Planned]: "",
  };

  const getLabelFromValue = (value: Availability) => {
    return availabilityLabels[value] || "";
  };
  useEffect(() => {
    const puppies = myAdPuppies;
    const puppyNames: string[] = [];
    const puppyReg: string[] = [];
    const puppyGenders: string[] = [];
    const puppyStatuses: string[] = [];
    const puppyColors: string[] = [];
    const puppyImages: string[] = [];
    const puppySelectedFiles: any = [];
    const puppyRemovedFiles: boolean[] = [];
    if (puppies && puppies.length > 0) {
      puppies.map((puppy, index) => {
        setValue(`puppies[${index}].name`, puppy.name);
        setValue(`puppies[${index}].registration`, puppy.registrationNumber);
        setValue(`puppies[${index}].gender`, puppy.gender.toUpperCase());
        setValue(`puppies[${index}].availability`, puppy.availability);
        setValue(`puppies[${index}].color`, puppy.color);
        puppyNames.push(puppy.name);
        puppyReg.push(puppy.registrationNumber);
        puppyGenders.push(puppy.gender.toUpperCase());
        puppyStatuses.push(puppy.availability);
        puppyImages.push(
          puppy.fileRecord?.url && puppy.fileRecord.url != ""
            ? `${process.env.REACT_APP_API_BASE_URL}${puppy.fileRecord?.url}?width=410&height=410`
            : !id
            ? ""
            : ""
        );
        puppyColors.push(puppy.color);
        puppySelectedFiles.push(null);
        puppyRemovedFiles.push(false);
      });

      setNames(puppyNames);
      setRegistrationNumbers(puppyReg);
      setGenders(puppyGenders as Gender[]);
      setStatuses(puppyStatuses as Availability[]);
      setImages(puppyImages);
      setColors(puppyColors);
      setFileSelectedArray(puppySelectedFiles);
      setIsImageRemovedArray(puppyRemovedFiles);
    }
  }, [adPuppies, id, numberOfPuppies]);

  const uploadAd = async () => {
    setLoading(true);

    try {
      const fatherId = getValues("father") && getValues("father").id;
      const motherId = getValues("mother") && getValues("mother").id;
      const media = await uploadFileAsync();
      const puppyImages = await uploadFileArrayAsync();

      if (parseInt(id) !== -1) {
        await createAd({
          adInputModel: {
            adId: parseInt(id) ?? undefined,
            title: getValues("adName"),
            fatherId: fatherId != "" ? fatherId : 0,
            motherId: motherId != "" ? motherId : 0,
            breed: getValues("breed"),
            femaleImages: [],
            maleImages: [],
            males: [],
            females: [],
            birthDate: getValues("birthDate"),
            readyForDelivery:
              getValues("readyForDelivery") ?? getValues("birthDate"), // added birthdate where field is not showing
            breedCombination: "",
            expBuyer: "",
            expLitter: getValues("otherDetails") || "",
            nkkGuidelines: true,
            offerBuyer: "",
            other,
            otherText: other ? getValues("otherText") : "",
            insurance: false,
            idTag: getValues("idTag") || false,
            price: parseFloat(getValues("price") || "0"),
            healthCheck: getValues("healthCheck") || false,
            healthCertificate: getValues("healthCertificate") || false,
            advising: getValues("advising") || false,
            numberOfPuppies:
              type === "fodt"
                ? parseInt(getValues("numberOfPuppies") || "0")
                : 0,
            pedigree: getValues("pedigree") || false,
            vaccinated: getValues("vaccinated") || false,
            puppyNames: names,
            puppyColors: colors,
            puppyGenders: genders as Gender[],
            puppyReg: registrationNumbers,
            puppyImages:
              puppyImages?.map((image) => image?.toString() ?? "") || [],
            puppyStatus: statuses as Availability[],
            wormTreatment: getValues("wormTreatment") || false,
            puppyPack: getValues("puppyPack") || false,
            adImage: media?.toString(),
            adType:
              type === "fodt"
                ? ("AVAILABLE" as Availability)
                : type === "planlagt"
                ? ("PLANNED" as Availability)
                : ("COMING" as Availability),
            fatherNKK: getValues("dadId"),
            motherNKK: getValues("momId"),
          },
        });
      }
    } catch (error) {
      console.error("Error uploading ad:", error);
    }
  };

  const updateArray = (array: any[], index: number, value: any): any[] => {
    const updatedArray = [...array];
    updatedArray[index] = value;
    return updatedArray;
  };

  const updatePuppyData = (
    property: puppyProperty,
    index: number,
    value: string
  ) => {
    switch (property) {
      case puppyProperty.color:
        setColors(updateArray(colors, index, value));
        break;
      case puppyProperty.gender:
        setGenders(updateArray(genders, index, value));
        break;
      case puppyProperty.registration:
        setRegistrationNumbers(updateArray(registrationNumbers, index, value));
        break;
      case puppyProperty.name:
        setNames(updateArray(names, index, value));
        break;
      case puppyProperty.availability:
        setStatuses(updateArray(statuses, index, value));
        break;
      case puppyProperty.image:
        setImages(updateArray(images, index - 1, value));
        break;
    }
  };

  const allBreeds = DogBreeds.sort();

  const createMediaJson = (media: any) => {
    if (!media) return null;
    const file = {
      id: media.mediaId,
      url: media.mediaUrl,
    };
    return JSON.stringify(file);
  };

  const uploadFileAsync = async () => {
    if (!fileSelected && isImageRemoved && id && ad?.fileRecord?.id) {
      console.log("remove image api call", ad?.fileRecord?.id);
      await deleteFile(parseInt(ad?.fileRecord?.id?.toString() ?? ""));
      return null;
    }

    if (!fileSelected) {
      console.log("No file selected.", ad?.fileRecord?.id);
      const fileResult = {
        mediaId: ad?.fileRecord?.id,
        mediaKey: ad?.fileRecord?.id,
        mediaUrl: ad?.fileRecord?.url,
      };

      return createMediaJson(fileResult);
    }

    const fileResult = await uploadFile(
      fileSelected,
      FileType.DogAd,
      ad?.fileRecord?.id?.toString()
    );
    setFileSelected(null);
    return createMediaJson(fileResult);
  };

  const createMediaJsonArray = (mediaArray: any[]) => {
    if (!mediaArray || mediaArray.length === 0) return null;

    const files = mediaArray.map((media) => {
      if (!media) return null;

      const file = {
        id: media.mediaId,
        url: media.mediaUrl,
      };

      return JSON.stringify(file);
    });
    //.filter(Boolean);

    return files;
  };

  const uploadFileArrayAsync = async () => {
    const uploadedFiles = await Promise.all(
      fileSelectedArray.map(async (selectedFile, index) => {
        const imageRemoved = isImageRemovedArray[index];

        if (
          !selectedFile &&
          imageRemoved &&
          id &&
          myAdPuppies?.[index].fileRecord?.id
        ) {
          console.log(
            "Remove image API call",
            myAdPuppies?.[index].fileRecord?.id?.toString()
          );
          await deleteFile(
            parseInt(myAdPuppies?.[index].fileRecord?.id?.toString() ?? "")
          );
          return null;
        }

        if (!selectedFile) {
          console.log("No file selected for index", index);
          return images[index]
            ? {
                mediaId: myAdPuppies?.[index].fileRecord?.id,
                mediaKey: myAdPuppies?.[index].fileRecord?.id?.toString(),
                mediaUrl: myAdPuppies?.[index].fileRecord?.url?.toString(),
              }
            : null;
        }

        const fileResult = await uploadFile(
          selectedFile,
          FileType.Puppy,
          myAdPuppies?.[index]?.fileRecord?.id?.toString() ?? ""
        );

        const newFileSelected = [...fileSelectedArray];
        newFileSelected[index] = null;
        setFileSelectedArray(newFileSelected);
        return {
          mediaId: fileResult?.mediaId,
          mediaKey: fileResult?.mediaKey,
          mediaUrl: fileResult?.mediaUrl,
        };
      })
    );
    return createMediaJsonArray(uploadedFiles);
  };

  const handleSetFileSelected = (index: number, file: File | null) => {
    const updatedFileSelected = [...fileSelectedArray];
    updatedFileSelected[index] = file;
    setFileSelectedArray(updatedFileSelected);
  };

  const handleSetIsImageRemoved = (index: number, isRemoved: boolean) => {
    const updatedIsImageRemoved = [...isImageRemovedArray];
    updatedIsImageRemoved[index] = isRemoved;
    setIsImageRemovedArray(updatedIsImageRemoved);
  };

  const handleFatherSelection = (fatherId: number) => {
    const father = myDogParents?.find((item) => item.id === fatherId);
    setValue("dadId", father?.nkkId || "");
  };

  const handleMotherSelection = (motherId: number) => {
    const mother = myDogParents?.find((item) => item.id === motherId);
    setValue("momId", mother?.nkkId || "");
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
    control,
    watch,
  } = useForm();
  return (
    <div className={css.NewDog}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <DashboardBanner
        image="/images/icons/dashboard/dog-face-circle.svg"
        percentage={60}
        title="Mine valper"
        info="Her kan du legge til valpekull. Du kan legge til fødte, kommende og planlagte, og administrere tidligere kull."
      />
      <div className={css.mainContainer}>
        <div className={css.leftContainer}>
          <ProfileImage
            defaultImage={defaultImage}
            setFileSelected={setFileSelected}
            url={image}
            setIsImageRemoved={setIsImageRemoved}
          />
        </div>
        <div className={css.rightContainer}>
          {type === "fodt" && <h2>Tilgjengelig valpekull</h2>}
          {type === "planlagt" && <h2>Planlagt valpekull</h2>}
          {type === "kommende" && <h2>Kommende valpekull</h2>}
          {type !== "planlagt" && (
            <div className={css.dualContainer}>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="father"
                  control={control}
                  rules={{ required: "Vennligst velg en far" }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="father"
                      className={css.dualInput}
                      value={field.value || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue);
                        handleFatherSelection(newValue?.id); // Trigger dadId change
                      }}
                      options={
                        myDogParents?.filter(
                          (parent) => parent.gender === "male"
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Far"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : ""
                          }
                        />
                      )}
                    />
                  )}
                />
              </FormControl>

              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="mother"
                  control={control}
                  rules={{ required: "Vennligst velg en mor" }}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id="mother"
                      className={css.dualInput}
                      value={field.value || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue);
                        handleMotherSelection(newValue?.id); // Trigger momId change
                      }}
                      options={
                        myDogParents?.filter(
                          (parent) => parent.gender === "female"
                        ) ?? []
                      }
                      getOptionLabel={(option) => option.name || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mor"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : ""
                          }
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </div>
          )}
          {(type === "fodt" || type === "kommende") && (
            <Button
              label="Gå til avlshunder"
              onClick={() => history.push("/min-pond/avlshunder")}
            />
          )}
          <div className={css.singleContainer}>
            <TextField
              label="Navn på kull"
              variant="outlined"
              fullWidth
              error={!!errors.adName}
              helperText={errors.adName ? errors.adName.message : ""}
              {...register("adName", {
                required: "Navn på kull er påkrevd",
              })}
              InputLabelProps={{ shrink: true }}
            />
          </div>
          {/* {type === "fodt" && (
  <div className={css.dualContainer}>
    <Controller
      name="numberOfPuppies"
      control={control}
      defaultValue={numberOfPuppies || ""} 
      render={({ field, fieldState }) => (
        <TextField
          {...field} 
          id="numberOfPuppies"
          type="number"
          label="Antall valper"
          className={css.dualInput}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ""}
          onChange={(e:any) => {
            if (e >= 16) return;
            field.onChange(e);
            setNumberOfPuppies(e);
          }}
          fullWidth
        />
      )}
    />
    <Controller
      name="price"
      control={control}
      defaultValue={price || ""}
      rules={{
        required: "Pris er påkrevd",
        pattern: {
          value: /^[0-9]+(\.[0-9]{1,2})?$/,
          message: "Pris må være et gyldig tall",
        },
        validate: (value) =>
          !isNaN(Number(value)) || "Pris må være et tall",
      }}
      render={({ field, fieldState }) => (
        <TextField
          id="price"
          type="number"
          label="Pris"
          className={css.dualInput}
          error={!!fieldState.error}
          helperText={fieldState.error ? fieldState.error.message : ""}
          {...field}
        />
      )}
    />
    </div>
  )} */}

          {type === "fodt" && (
            <div className={css.dualContainer}>
              <Controller
                name="numberOfPuppies"
                control={control}
                defaultValue={numberOfPuppies || ""}
                render={({ field }) => (
                  <Input
                    {...field}
                    id="numberOfPuppies"
                    type="number"
                    placeholder="Antall valper"
                    className={css.dualInput}
                    onChange={(e) => {
                      if (e >= 16) return;
                      field.onChange(e);
                      setNumberOfPuppies(e);
                    }}
                  />
                )}
              />
              <Controller
                name="price"
                control={control}
                defaultValue={""}
                rules={{
                  required: "Pris er påkrevd",
                  pattern: {
                    value: /^[0-9]+(\.[0-9]{1,2})?$/,
                    message: "Pris må være et gyldig tall",
                  },
                  validate: (value) =>
                    !isNaN(Number(value)) || "Pris må være et tall",
                }}
                render={({ field, fieldState }) => (
                  <Input
                    id="price"
                    type="number"
                    placeholder="Pris"
                    className={css.dualInput}
                    errorMessage={
                      fieldState.error ? fieldState.error.message : ""
                    }
                    {...field}
                    error={!!fieldState.error}
                  />
                )}
              />
            </div>
          )}
          <Stack direction={{ xs: "column", sm: "row" }} gap="16px">
            {type === "fodt" ? (
              <Controller
                name="birthDate"
                control={control}
                rules={{ required: "Fødselsdato er påkrevd" }}
                render={({ field, fieldState }) => (
                  <DatePickerProvider>
                    <DatePicker
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(c: any) => {
                        field.onChange(c.format("YYYY-MM-DD"));
                      }}
                      slotProps={{
                        textField: {
                          label: "Født",
                          variant: "outlined",
                          fullWidth: true,
                          error: !!fieldState.error,
                          helperText: fieldState.error
                            ? fieldState.error.message
                            : "",
                          sx: {
                            "& .MuiInputLabel-shrink": {
                              transform: "translate(14px, -4px) scale(0.75)",
                            },
                          },
                        },
                      }}
                    />
                  </DatePickerProvider>
                )}
              />
            ) : (
              <Controller
                name="birthDate"
                control={control}
                rules={{ required: "Fødselsdato er påkrevd" }}
                render={({ field, fieldState }) => (
                  <DatePickerProvider>
                    <DatePicker
                      views={["year", "month"]}
                      value={field.value ? dayjs(field.value, "YYYY-MM") : null}
                      onChange={(c: any) => {
                        field.onChange(c.format("YYYY-MM"));
                      }}
                      slotProps={{
                        textField: {
                          label: "Født",
                          variant: "outlined",
                          fullWidth: true,
                          error: !!fieldState.error,
                          helperText: fieldState.error
                            ? fieldState.error.message
                            : "",
                          sx: {
                            "& .MuiInputLabel-shrink": {
                              transform: "translate(14px, -4px) scale(0.75)",
                            },
                          },
                        },
                      }}
                    />
                  </DatePickerProvider>
                )}
              />
            )}
            {type === "fodt" && (
              // <div>
              <Controller
                name="readyForDelivery"
                control={control}
                rules={{
                  required: type === "fodt" && "Dato for levering er påkrevd",
                  validate: {
                    afterBirthDate: (value) => {
                      const birthDateValue = getValues("birthDate"); // get the birthDate value
                      if (!birthDateValue) {
                        return "Fødselsdato må være satt";
                      }
                      const birthDate = new Date(birthDateValue);
                      const readyDate = new Date(value);
                      // Calculate the date 8 weeks after the birth date
                      const eightWeeksLater = new Date(birthDate);
                      eightWeeksLater.setDate(birthDate.getDate() + 56); // 56 days = 8 weeks
                      return (
                        readyDate >= eightWeeksLater ||
                        "Leveringsdato må være minst 8 uker etter fødselsdato"
                      );
                    },
                  },
                }}
                render={({ field, fieldState }) => (
                  <DatePickerProvider>
                    <DatePicker
                      value={field.value ? dayjs(field.value) : null}
                      onChange={(c: any) => {
                        field.onChange(c.format("YYYY-MM-DD")); // store full date
                      }}
                      slotProps={{
                        textField: {
                          label: "Dato for levering",
                          variant: "outlined",
                          fullWidth: true,
                          error: !!fieldState.error,
                          helperText: fieldState.error
                            ? fieldState.error.message
                            : "",
                          sx: {
                            "& .MuiInputLabel-shrink": {
                              transform: "translate(14px, -4px) scale(0.75)",
                            },
                          },
                        },
                      }}
                    />
                  </DatePickerProvider>
                )}
              />
              // </div>
            )}
          </Stack>
          <div>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="breed"
                control={control}
                rules={{ required: "Vennligst velg en rase" }}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    id="breed"
                    className={css.dualInput}
                    value={field.value || null}
                    onChange={(_, newValue) => field.onChange(newValue)}
                    options={allBreeds}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Rase"
                        error={!!fieldState.error}
                        helperText={
                          fieldState.error ? fieldState.error.message : ""
                        }
                      />
                    )}
                  />
                )}
              />
            </FormControl>
          </div>
          {/* {type === "kommende" && (
            <div className={css.dualContainer}>
              <TextField
                label="Fars registreringsnummer"
                variant="outlined"
                className={css.dualInput}
                {...register("dadId")}
                value={watch("dadId") || ""}
                disabled
              />
              <TextField
                label="Mors registreringsnummer"
                variant="outlined"
                className={css.dualInput}
                {...register("momId")}
                value={watch("momId") || ""}
                disabled
              />
            </div>
          )} */}

          {type === "fodt" && (
            <div className={css.includedContainer}>
              <p>Inkludert med valpen</p>
              <FormGroup className={css.formGrid}>
                <Controller
                  name="pedigree"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Stamtavle"
                    />
                  )}
                />
                <Controller
                  name="healthCheck"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Helsebok"
                    />
                  )}
                />
                <Controller
                  name="puppyPack"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Valpepakke"
                    />
                  )}
                />
                <Controller
                  name="idTag"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="ID-merking"
                    />
                  )}
                />
                <Controller
                  name="wormTreatment"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Ormekur"
                    />
                  )}
                />
                <Controller
                  name="vaccinated"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value ?? false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Vaksine"
                    />
                  )}
                />
                <Controller
                  name="advising"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value || false}
                          onChange={(e) => field.onChange(e.target.checked)}
                        />
                      }
                      label="Rådgiving"
                    />
                  )}
                />
                <Controller
                  name="other"
                  control={control}
                  render={({ field }) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={field.value || false}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                            setOther(e.target.checked);
                          }}
                        />
                      }
                      label="Annet?"
                    />
                  )}
                />
              </FormGroup>

              {errors.checkboxGroup && <p>{errors.checkboxGroup.message}</p>}
              {other && (
                <div className={css.singleContainer}>
                  <Controller
                    name="otherText"
                    control={control}
                    rules={{ required: other && "Om kullet er påkrevd" }}
                    render={({ field, fieldState }) => (
                      <Input
                        id="otherText"
                        type="text"
                        errorMessage={
                          fieldState.error ? fieldState.error.message : ""
                        }
                        {...field}
                        error={!!fieldState.error}
                        className={css.singleInput}
                        placeholder="Hvis annet, hva er inkludert?"
                        sx={{ width: "100%", marginTop: "8px" }}
                      />
                    )}
                  />
                </div>
              )}
            </div>
          )}
          {type === "fodt" && (
            <div className={css.singleContainer}>
              <Controller
                name="otherDetails"
                control={control}
                defaultValue={""}
                rules={{ required: "Om kullet er påkrevd" }}
                render={({ field, fieldState }) => (
                  <Input
                    id="otherDetails"
                    type="text"
                    error={!!fieldState.error} // Determine if there's an error
                    errorMessage={
                      fieldState.error ? fieldState.error.message : ""
                    } // Display error message
                    {...field} // Spread field props
                    className={css.singleInput}
                    placeholder="Om kullet"
                    multiline // Allow for multiline input
                    sx={{ width: "100%" }} // Set width to 100%
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
      {numberOfPuppies !== "" &&
        type === "fodt" &&
        [...Array(parseInt(numberOfPuppies) || 0)].map((_item, index) => (
          <div className={css.mainContainer} key={index}>
            <div className={css.leftContainer}>
              <ProfileImage
                defaultImage={defaultImage}
                setFileSelected={(file) => handleSetFileSelected(index, file)}
                url={images[index]}
                setIsImageRemoved={(isRemoved) =>
                  handleSetIsImageRemoved(index, isRemoved)
                }
              />
            </div>
            <div className={css.rightContainer}>
              <h2>Valp {index + 1}</h2>
              <div className={css.singleContainer}>
                <Controller
                  name={`puppies[${index}].name`}
                  control={control}
                  defaultValue={""}
                  rules={{ required: "Midlertidig navn er påkrevd" }}
                  render={({ field, fieldState }) => (
                    <Input
                      id={`puppies[${index}].name`}
                      type="text"
                      {...field}
                      placeholder="Midlertidig navn"
                      className={css.singleInput}
                      error={!!fieldState.error}
                      sx={{ width: "100%" }}
                      errorMessage={
                        fieldState.error ? fieldState.error.message : ""
                      }
                      onChange={(event) => {
                        field.onChange(event);
                        updatePuppyData(puppyProperty.name, index, event);
                      }}
                    />
                  )}
                />
              </div>

              <div className={css.dualContainer}>
                <Controller
                  name={`puppies[${index}].gender`}
                  control={control}
                  rules={{ required: "Kjønn er påkrevd" }}
                  defaultValue={""}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id={`puppies[${index}].gender`}
                      className={css.dualInput}
                      value={field.value || null}
                      onChange={(_, newValue) => {
                        field.onChange(newValue);
                        updatePuppyData(puppyProperty.gender, index, newValue);
                      }}
                      options={[Gender.Female, Gender.Male]}
                      getOptionLabel={(option) => option || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kjønn"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : ""
                          }
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  name={`puppies[${index}].registration`}
                  control={control}
                  defaultValue={""}
                  //rules={{ required: "Registreringsnummer er påkrevd" }}
                  render={({ field, fieldState }) => (
                    <Input
                      id={`registrationsNummer-${index}`}
                      type="text"
                      {...field}
                      placeholder="Registreringsnummer"
                      className={css.dualInput}
                      error={!!fieldState.error}
                      errorMessage={
                        fieldState.error ? fieldState.error.message : ""
                      }
                      onChange={(event) => {
                        field.onChange(event);
                        updatePuppyData(
                          puppyProperty.registration,
                          index,
                          event
                        );
                      }}
                    />
                  )}
                />
              </div>

              <div className={css.dualContainer}>
                <Controller
                  name={`puppies[${index}].color`}
                  control={control}
                  defaultValue={""}
                  render={({ field, fieldState }) => (
                    <Input
                      id={`colors-${index}`}
                      type="text"
                      {...field}
                      placeholder="Farge"
                      className={css.dualInput}
                      error={!!fieldState.error}
                      errorMessage={
                        fieldState.error ? fieldState.error.message : ""
                      }
                      onChange={(event) => {
                        field.onChange(event);
                        updatePuppyData(puppyProperty.color, index, event);
                      }}
                    />
                  )}
                />

                <Controller
                  name={`puppies[${index}].availability`}
                  control={control}
                  rules={{ required: "Tilgjengelighet er påkrevd" }}
                  defaultValue={null}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      id={`puppies[${index}].availability`}
                      className={css.dualInput}
                      value={
                        field.value
                          ? {
                              title: getLabelFromValue(field.value),
                              value: field.value,
                            }
                          : null
                      }
                      onChange={(_, newValue) => {
                        const newAvailability = newValue ? newValue.value : "";
                        field.onChange(newAvailability);
                        updatePuppyData(
                          puppyProperty.availability,
                          index,
                          newAvailability
                        );
                      }}
                      options={[
                        { title: "Tilgjengelig", value: "AVAILABLE" },
                        { title: "Reservert", value: "RESERVED" },
                        { title: "Solgt", value: "SOLD" },
                      ]}
                      getOptionLabel={(option) => option.title || ""}
                      isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                      } // Ensure correct comparison between options and selected value
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Tilgjengelighet"
                          error={!!fieldState.error}
                          helperText={
                            fieldState.error ? fieldState.error.message : ""
                          }
                        />
                      )}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        ))}
      {errorMessage && (
        <Alert severity="error" sx={{ marginTop: "16px" }}>
          {errorMessage}
        </Alert>
      )}

      <div style={{ marginTop: "16px" }}>
        <Button
          disabled={Loading}
          label="Tilbake"
          skin="lightGreen"
          onClick={() => history.goBack()}
        />
        <ButtonSubmit
          disabled={Loading}
          variant="contained"
          size="large"
          style={{ width: "9rem" }}
          onClick={handleSubmit(uploadAd)}
        >
          {Loading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            "Fortsett"
          )}
        </ButtonSubmit>
      </div>
    </div>
  );
};

export default NewPuppy;
