import Fade from "react-reveal/Fade";
import { useQuery } from "react-query";
import AdCard from "../components/AdCard";
import css from "../styles/AllAds.module.scss";
import React, { useEffect, useState } from "react";
import { useIsMobile } from "../hooks/useIsMobile";
import { axios } from "../api/authenticated-axios";
import { useInView } from "react-intersection-observer";
import {
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  CircularProgress,
  Stack,
} from "@mui/material";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import {
  ValperTilSalgsContentModel,
  IApiContentResponsePagedViewModel,
  AvailabilityItemContentResponseModel,
} from "../api/umbracoApi";
import LoadingScreen from "../components/LoadingScreen";
import { Helmet } from "react-helmet-async";

const AllAds: React.FC = () => {
  const { ref, inView } = useInView();
  const [pageNumber, setPageNumber] = useState(1);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false);
  const [hasNextPage, setHasNextPage] = useState<any>(Number);
  const [allAds, setAllAds] = useState<any>([]);
  const { isMobile } = useIsMobile();

  const [availability, setAvailability] = useState<string>("");
  const [breed, setBreed] = useState<string>("");

  const { isLoading, isFetching } = useQuery(
    ["DogAdsSearch", pageNumber, breed, availability],
    () => {
      const params: any = {
        Page: pageNumber,
        Availability: availability == "Alle" ? "" : availability,
        Breed: breed == "Alle" ? "" : breed,
      };

      return axios
        .post("/DogAd/GetDogWithFilter", params)
        .then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (pageNumber == 1) {
          setAllAds(() => [...data.items]);
        } else {
          setAllAds((prev: any) => [...prev, ...data.items]);
        }
        setHasNextPage(data?.totalPages > pageNumber);
      },
    }
  );

  const { data: contents } =
    useUmbracoContentItemQuery<any>("valper-til-salgs");
  const { data: allBreeds } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:dogBreedItem"],
    });
  const { data: availabilityTypes } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:availabilityItem"],
    });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const fetchNextPage = () => {
    if (hasNextPage && !isFetchingNextPage) {
      setIsFetchingNextPage(true);
      setPageNumber((prev) => prev + 1);
      setIsFetchingNextPage(false);
    }
  };

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);
  function urlFor(source: any) {
    return baseUrl + source;
  }
  const currentUrl = window.location.href;

  return (
    <div className={css.AllAds}>
      <Helmet>
      <meta charSet="utf-8" />
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={
            contents?.properties?.metaKeywords
              ? contents?.properties?.metaKeywords
              : "Pond.no"
          }
          />
          <meta property="og:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl}  />
          <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta property="og:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"}  />
          {contents?.properties?.ogImage && (
          <meta name="og:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {contents?.properties?.ogImage && (
          <meta name="og:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeywords : "Pond.no"} />
          <meta property="twitter:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl} />
          <meta name="twitter:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta name="twitter:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {contents?.properties?.ogImage && (
          <meta name="twitter:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
          {contents?.properties?.ogImage && (
          <meta name="twitter:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
        </Helmet>
      <div className={css.intro}>
        {contents?.properties && (
          <div className={css.intro}>
            <h1>{contents.properties.title}</h1>
            <p>{contents.properties.description}</p>
          </div>
        )}
      </div>
      <div className={css.filterContainer}>
        <FormControl
          size="small"
          sx={
            isMobile
              ? { width: "48%", fontFamily: "Poppins" }
              : { width: "20%", fontFamily: "Poppins" }
          }
        >
          <InputLabel
            id="availability-select-label"
            sx={{
              fontFamily: {
                xs: "Poppins",
                sm: "Poppins",
              },
            }}
          >
            Tilgjengelighet
          </InputLabel>

          <Select
            labelId="availability-select-label"
            id="availability-select"
            value={availability}
            autoWidth
            label="Tilgjengelighet"
            onChange={(e) => {
              setPageNumber(1);
              setAvailability(e.target.value);
            }}
          >
            <MenuItem value={"Alle"}>Alle</MenuItem>

            {availabilityTypes?.items?.map(
              (item: AvailabilityItemContentResponseModel) => (
                <MenuItem
                  key={item.id as string}
                  value={item.properties?.title as string}
                >
                  {item.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
        <FormControl
          size="small"
          sx={isMobile ? { width: "48%" } : { width: "20%" }}
        >
          <InputLabel
            id="breed-select-label"
            sx={{
              ".MuiInputLabel-root": {
                fontFamily: { sm: "Sans-Pro", xs: "Poppins" },
              },
              ".MuiInputLabel": {
                fontFamily: { sm: "Sans-Pro", xs: "Poppins" },
              },
            }}
          >
            Rase
          </InputLabel>
          <Select
            labelId="breed-select-label"
            id="breed-select"
            value={breed}
            label="Rase"
            autoWidth
            onChange={(e) => {
              setPageNumber(1);
              setBreed(e.target.value);
            }}
          >
            <MenuItem value={"Alle"}>Alle</MenuItem>
            {allBreeds?.items?.sort().map((item) => (
              <MenuItem key={item.id} value={item.name || ""}>
                {item.name || ""}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      {isFetching && <LoadingScreen />}
      <div className={isMobile ? undefined : css.gridContainer}>
        {allAds?.map((ad: any, index: number) => {
          const adImage =
            ad.adImage == "/images/placeholder-dog.png" ||
            ad.adImage == "" ||
            !ad.adImage
              ? ""
              : JSON.parse(ad.adImage);
          return (
            <div className={css.cardContainer} key={index}>
              <Fade bottom>
                <AdCard
                  imgSrc={
                    adImage
                      ? urlFor(adImage?.url + "?width=600&height=600")
                      : "/images/placeholder-dog.png"
                  }
                  isMobile={isMobile}
                  className={css.card}
                  status={ad.adType}
                  imgAlt={ad.title ?? ""}
                  breedName={ad.breed}
                  breederName={ad.kennelName ?? ""}
                  price={ad.price ?? 0}
                  totalDogsAvailable={ad.availablePuppy ?? 0}
                  city={ad.memberCity ?? ""}
                  to={`/annonse/${ad.id}`}
                />
              </Fade>
            </div>
          );
        })}
      </div>
      {!isFetching && allAds?.length <= 0 && (
        <div className={css.notFound}>
          <Fade bottom>
            <img src="/images/ouch.svg" alt="Ingen resultat" />
            <h2>Fant ingen annonser</h2>
          </Fade>
        </div>
      )}

      <div ref={ref}>
        {isFetchingNextPage || hasNextPage ? <LoadingScreen /> : null}
      </div>
    </div>
  );
};

export default AllAds;
