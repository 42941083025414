import { useState } from "react";
import { axios } from "../../../api/authenticated-axios";
import useToast from "../../../components/Toast/hooks";
import { FileType } from "../../../enums";
import { UploadedMediaModel } from "../../../model/UploadedMediaModel";

const useVerification = () => {
  const { showToast } = useToast();
  const [selected, setSelected] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const onSuccess = async (step: number, data: any) => {
    const tempData = { ...stepsData, ...data };

    if (step === 3) {
      try {
        setIsLoading(true);
        const puppyPic = new FormData();
        puppyPic.append("file", tempData.PuppyPictureId);
        puppyPic.append("fileType", FileType.BreederVerification.toString());

        const uploadResponse: any = await axios.post(
          "/file/upload",
          puppyPic
        );
        const res = uploadResponse.data as UploadedMediaModel;
        await axios.post("/breederverification/create", {
          ...tempData,
          DogRetirementRange: Number(tempData.DogRetirementRange) + 1,
          DogBreedingStartAgeRange:
            Number(tempData.DogBreedingStartAgeRange) + 1,
          PuppyPictureId: res.mediaId
        });
        showToast("Verified Succcessfully.", "success");
      } catch (e: any) {
        console.log(e);
        setIsLoading(false);
        showToast(
          e?.response?.data?.title ||
            "Error: try contacting your administrator",
          "error"
        );
        return;
      }
    }
    setSelected(step);
    setStepsData(tempData);
  };

  return {
    selected,
    setSelected,
    stepsData,
    setStepsData,
    isLoading,
    onSuccess,
  };
};

export default useVerification;
