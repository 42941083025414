import { useForm, Controller } from "react-hook-form";
import { axios } from "../../api/authenticated-axios";
import { CircularProgress, Stack, Button as ButtonSubmit } from "@mui/material";
import { validatePassword } from "../../utils";
import Button from "../../components/Button";
import useToast from "../../components/Toast/hooks";
import PasswordField from "../custom-register/components/PasswordField";
import { useState } from "react";

type formType = {
  OldPassword: string;
  Password: string;
  ConfirmPassword: string;
};
interface IProps {
  onClose?: () => void;
}
const ChangePassword = ({ onClose }: IProps) => {
  const [Loading, setLoading] = useState(false);
  const { showToast } = useToast();
  const { control, handleSubmit, reset, watch } = useForm<formType>({});
  const password = watch("Password");

  const onSubmit = async (data: formType) => {
    setLoading(true);
    try {
      await axios.post("/memberlist/ResetPassword", data);
      showToast("Password has been successfully changed.", "success");
      reset();
      onClose && onClose();
    } catch (error: any) {
      if (!error.response?.status) return;
      if (error.response.status === 401) {
        showToast(error.response.data.toString(), "error");
      }
      if (error.response.status === 400) {
        showToast(error.response.data.toString(), "error");
      }
    }
    setLoading(false);
  };

  return (
    <>
      <form>
        <Stack gap={3}>
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Gammelt passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="OldPassword"
            control={control}
            rules={{ required: "Gammelt passord er påkrevd" }}
          />
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Nytt passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="Password"
            control={control}
            rules={{
              required: "Nytt passord er påkrevd",
              validate: validatePassword,
            }}
          />
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Bekrefte passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="ConfirmPassword"
            control={control}
            rules={{
              required: "Bekreftelse av passord er påkrevd",
              validate: (value) =>
                value === password || "Passordene stemmer ikke overens.",
            }}
          />
          <ButtonSubmit
            disabled={Loading}
            variant="contained"
            size="large"
            onClick={handleSubmit(onSubmit)}
          >
            {Loading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              "Endre passord"
            )}
          </ButtonSubmit>
        </Stack>
      </form>
    </>
  );
};

export default ChangePassword;
