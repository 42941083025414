import DOMPurify from "dompurify";

const ArticleTextContent = ({ content, css }: any) => {
  return (
    <div className={css.blockContainer}>
      <h3>{content.properties.heading}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(content.properties.detail?.markup || ""),
        }}
      />
    </div>
  );
};

export default ArticleTextContent;
