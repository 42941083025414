import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Collapse from "@mui/material/Collapse";
import css from "../../styles/Profile.module.scss";
import { DogParentDto } from "../../api/graphql.gen";

interface DogModalProps {
  show: boolean;
  onHide: () => void;
  selectedParent: DogParentDto | DogParentDto[];
}

const DogModal: React.FC<DogModalProps> = ({
  show,
  onHide,
  selectedParent,
}) => {
  const [visibleSections, setVisibleSections] = useState<{
    [parentId: number]: { [section: number]: boolean };
  }>({});

  // Helper function to toggle the visibility of a section for a specific parent
  const toggleSection = (parentId: number, section: number) => {
    setVisibleSections((prevVisibleSections) => ({
      ...prevVisibleSections,
      [parentId]: {
        ...prevVisibleSections[parentId],
        [section]: !prevVisibleSections[parentId]?.[section],
      },
    }));
  };
  const renderParentDetails = (parent: DogParentDto) => (
    <div key={parent.id} className={css.parentContainer}>
      <div className={css.gender}>
        {parent.gender === "male" ? "Hannhund" : "Tispe"}
      </div>
      <img
        src={
          parent.fileRecord && parent.fileRecord.url
            ? `${process.env.REACT_APP_API_BASE_URL}${parent.fileRecord.url}?width=410&height=410`
            : "/images/placeholder-dog.png"
        }
        alt="Bilde av forelder"
      />
      <div className={css.name}>{parent.name}</div>
      <div className={css.details}>
        <div className={css.detailsGrid}>
          <div>Rase</div>
          <div className={css.value}>{parent.breed}</div>
          <div>Reg nummer</div>
          <div className={css.value}>{parent.nkkId}</div>
          <div>Farge</div>
          <div className={css.value}>{parent.color}</div>
          <div>Vekt</div>
          <div className={css.value}>{parent.weight}</div>
        </div>
        <div className={css.grid}>
          <div className={css.section}>
            <h2
              className={css.textImage}
              onClick={() => toggleSection(parent.id, 1)}
              style={{ cursor: "pointer" }}
            >
              Om hunden
              <img
                src={
                  visibleSections[parent.id]?.[1]
                    ? "/images/icons/closeIcon.svg"
                    : "/images/icons/openIcon.svg"
                }
                alt=""
                className={css.icon}
              />
            </h2>
            <Collapse in={visibleSections[parent.id]?.[1]} timeout="auto">
              <p>
                {parent.description.length > 0
                  ? parent.description
                  : "Ingen registrering funnet"}
              </p>
            </Collapse>
          </div>
          <div className={css.section}>
            <h2
              className={css.textImage}
              onClick={() => toggleSection(parent.id, 2)}
              style={{ cursor: "pointer" }}
            >
              Attest
              <img
                src={
                  visibleSections[parent.id]?.[2]
                    ? "/images/icons/closeIcon.svg"
                    : "/images/icons/openIcon.svg"
                }
                alt=""
                className={css.icon}
              />
            </h2>
            <Collapse in={visibleSections[parent.id]?.[2]} timeout="auto">
              <div className={css.attest}>
                <div className={css.head}>Sjekket 12 måneder før parring:</div>
                <div className={css.subhead}>
                  {parent.dogHealthChecked ? "Ja" : "Nei"}
                </div>
              </div>
              <div className={css.attest}>
                <div className={css.head}>Helseattest:</div>
                <div className={css.subhead}>
                  {parent.healthCertificateUrl ? (
                    <img src="/images/home/darkCheck.svg" alt="Checkmark" />
                  ) : (
                    <img src="/images/home/cross.svg" alt="cross" />
                  )}
                </div>
              </div>
            </Collapse>
          </div>

          <div className={css.section}>
            <h2
              className={css.textImage}
              onClick={() => toggleSection(parent.id, 3)}
              style={{ cursor: "pointer" }}
            >
              Helsetester
              <img
                src={
                  visibleSections[parent.id]?.[3]
                    ? "/images/icons/closeIcon.svg"
                    : "/images/icons/openIcon.svg"
                }
                alt=""
                className={css.icon}
              />
            </h2>
            <Collapse in={visibleSections[parent.id]?.[3]} timeout="auto">
              <div className={css.attestGroup}>
                {parent.healthDocuments && parent.healthDocuments.length > 0 ? (
                  parent.healthDocuments.map((healthdoc: any, index: any) => (
                    <div key={index}>
                      <div className={css.attest}>
                        <div className={css.head}>Navn på helsetest:</div>
                        <div className={css.subhead}>{healthdoc.testName}</div>
                      </div>
                      <div className={css.attest}>
                        <div className={css.head}>Resultat:</div>
                        <div className={css.subhead}>
                          {healthdoc.testResult}
                        </div>
                      </div>
                      <div className={css.attest}>
                        <div className={css.head}>Dokumentasjon:</div>
                        <div className={css.subhead}>
                          {healthdoc.testFileUrl ? (
                            <img
                              src="/images/home/darkCheck.svg"
                              alt="Checkmark"
                            />
                          ) : (
                            <img src="/images/home/cross.svg" alt="cross" />
                          )}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>Ingen registrering funnet</p>
                )}
              </div>
            </Collapse>
          </div>

          <div className={css.section}>
            <h2
              className={css.textImage}
              onClick={() => toggleSection(parent.id, 4)}
              style={{ cursor: "pointer" }}
            >
              Titler
              <img
                src={
                  visibleSections[parent.id]?.[4]
                    ? "/images/icons/closeIcon.svg"
                    : "/images/icons/openIcon.svg"
                }
                alt=""
              />
            </h2>
            <Collapse in={visibleSections[parent.id]?.[4]} timeout="auto">
              <div className={css.champContainer}>
                {parent.championTitlesList &&
                parent.championTitlesList.length > 0 ? (
                  parent.championTitlesList?.map((title: any, index: any) => (
                    <div key={index} className={css.championContainer}>
                      <img
                        className={css.image}
                        src="/images/icons/award.svg"
                        alt="Prisikon"
                      />
                      <p>{title}</p>
                    </div>
                  ))
                ) : (
                  <p>Ingen registrering funnet</p>
                )}
              </div>
            </Collapse>
          </div>
        </div>
      </div>
    </div>
  );

  const renderParentContent = () => {
    if (Array.isArray(selectedParent)) {
      return selectedParent.map((parent) => renderParentDetails(parent));
    } else {
      return renderParentDetails(selectedParent);
    }
  };

  return (
    <Modal show={show} onHide={onHide} fullscreen animation centered>
      <Modal.Header closeButton />
      <Modal.Body>
        <div className={css.parentModalContainer}>
          {selectedParent && renderParentContent()}
          <div className={css.background} />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DogModal;
