import React from "react";
import { RouteProps } from "react-router-dom";
import css from "../styles/Layout.module.scss";
import Header from "./Header";

type Props = RouteProps;

const Layout: React.FC<Props> = function ({ children }: RouteProps) {
  return (
    <div className={css.Layout}>
      <Header />
      <div className={css.content}>{children}</div>
    </div>
  );
};

export default Layout;
