import React, { useState } from "react";
import { Collapse } from "@mui/material"; // Assuming you're using Material UI's Collapse component
import { useHistory } from "react-router-dom"; // For navigation
import Button from "../../Button";
import DOMPurify from "dompurify";

const FaqSection = ({ title, limitedSections, faqPageLink, css }: any) => {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const history = useHistory();

  return (
    <div className={css.faq}>
      <div className={css.url}>
        <h3>{title}</h3>
        <div style={{ marginBottom: "1rem" }}>
          {limitedSections?.map(
            (section: any, index: any) =>
              section.properties.isFeature && (
                <div
                  key={section?.properties.faqQuestion}
                  className={css.questionContainer}
                >
                  <div
                    className={css.dropDown}
                    onClick={() =>
                      setVisibleIndex(visibleIndex === index ? null : index)
                    }
                  >
                    <h4 className={css.question}>
                      {section.properties.faqQuestion}
                    </h4>
                    <img
                      src={
                        visibleIndex === index
                          ? "/images/icons/arrowup.svg"
                          : "/images/icons/arrowdown.svg"
                      }
                      alt="Åpne ikon"
                    />
                  </div>
                  <div>
                    <Collapse in={visibleIndex === index} timeout="auto">
                      <p className={css.answer}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              section.properties.faqAnswer.markup ?? ""
                            ),
                          }}
                        />
                      </p>
                    </Collapse>
                  </div>
                </div>
              )
          )}
        </div>
        <Button
          onClick={() => history.push(faqPageLink[0].url)}
          label={faqPageLink[0].title}
          skin="dark"
        />{" "}
      </div>
    </div>
  );
};

export default FaqSection;
