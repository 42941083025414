import * as React from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Step1 from "../Step1";
import Step2 from "../Step2";
import Step3 from "../Step3";
import useAuth from "../../../../hooks/useAuth0";
import { Button, CircularProgress } from "@mui/material";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { isEmpty } from "../../../../utils";

interface IProps {
  selected: number;
  setSelected: (step: number)=> void;
  onSuccess: (step: number, data: any) => void;
  isLoading: boolean;
}

const OnboardingSteps: React.FC<IProps> = ({ selected, setSelected, isLoading, onSuccess }) => {
  const { user } = useAuth();
  const steps = [
    { label: "Om deg", component: Step1 },
    ...(user.breeder
      ? [
        { label: "Ditt oppdrett", component: Step2 },
        { label: "Kennelprofil", component: Step3 }
      ]
      : [{ label: "Favorittraser", component: Step2 }]),
    ];

  return (
    <Stack maxWidth="60%" height="100vh" alignItems="center" position="relative" marginTop="52px">
      {isEmpty(user)? <CircularProgress color="inherit" />: <>
      <Stack gap={"28px"} sx={{ width: "394px", minHeight: "195px", justifyContent: "center" }}>
        {selected >= 1 && <Button sx={{position: "absolute", left: {sm:"0px", xs:"-3rem"}, top: {sm: "26px", xs: "-1.5rem"}}} onClick={()=> setSelected(selected-1)} startIcon={<KeyboardArrowLeftIcon />}>Tilbake</Button>}
        <h3
          style={{
            textAlign: "center",
            fontWeight: 600,
            color: "#065C53",
          }}
        >
          {steps[selected].label}
        </h3>
        <Stepper activeStep={selected} alternativeLabel>
          {steps
            .filter((s) => s.label)
            .map((step) => (
              <Step key={step.label}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": {
                      marginTop: "0px !important",
                      fontWeight: 500,
                      "&.Mui-active": {
                        fontWeight: 600,
                      },
                    },
                    ".MuiStepLabel-iconContainer": { height: "28px" },
                  }}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Stack>
      <Stack
        sx={{
          width: "100vw",
          height: "100%",
          padding: '32px',
          backgroundColor: "white",
          alignItems: "center",
        }}
      >
        <Step1 show={selected===0} onSuccess={onSuccess} />
        <Step2 show={selected===1} onSuccess={onSuccess} />
        <Step3 show={selected===2} isLoading={isLoading} onSuccess={onSuccess} />
      </Stack></>}
    </Stack>
  );
};

export default OnboardingSteps;
