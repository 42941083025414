export const fetchBreedImages = async (breed: string) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const breedWords = breed.split(" ");
  const breedFilter = breedWords
    .map((word) => `filter=name:${encodeURIComponent(word)}`)
    .join("&");

  const response = await fetch(
    `${baseUrl}/umbraco/delivery/api/v2/content?contentType=dogBreedItem&${breedFilter}&fields=properties[profilePhoto]`
  );

  if (!response.ok) {
    throw new Error(
      `Error fetching data for breed "${breed}": ${response.statusText}`
    );
  }

  return response.json();
};
