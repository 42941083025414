import React from "react";
import { Card, Stack, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";

interface IProps {
  title: string;
  description: string;
  price: string;
  duration: string;
  features: string[];
  availableFeatures: string[];
  unavailableFeatures: string[];
  children?: React.ReactNode;
}

const SubscriptionSuccessCard: React.FC<IProps> = ({
  title,
  description,
  price,
  duration,
  features,
  availableFeatures,
  unavailableFeatures,
  children,
}) => {
  return (
    <Card
      sx={{
        gap: "32px",
        width: { xs: "100%", sm: "462px" },
        borderRadius: "24px",
        border: "2px solid #B4CECB",
        backgroundColor: "#FFFFFF",
        padding: "16px",
      }}
    >
      <Stack
        padding="32px"
        justifyContent="space-between"
        sx={{ borderBottom: "1px solid #B4CECB" }}
      >
        <Typography fontSize={{ xs: "24px", sm: "28px" }} fontWeight="700" color="#065C53">
          {title}
        </Typography>
        <Typography fontSize={{ xs: "14px", sm: "16px" }} fontWeight="400" color="#065C53">
          {description}
        </Typography>
        <Typography fontSize={{ xs: "32px", sm: "56px" }} fontWeight="700" color="#065C53" marginTop="20px">
          {price}
        </Typography>
        <Typography fontSize={{ xs: "14px", sm: "16px" }} fontWeight="600" color="#065C53">
          {duration}
        </Typography>
      </Stack>
      <Stack gap="16px" padding="16px">
        {features.map((feature, idx) => (
          <Stack direction="row" gap="12px" key={idx}>
            <CheckCircleIcon sx={{ color: "#065C53" }} />
            <Typography variant="body2" fontWeight="500" fontFamily="Inter">
              {feature}
            </Typography>
          </Stack>
        ))}
        {availableFeatures.length > 0 && (
          <>
            <Stack borderTop="1px solid #B4CECB" />
            <Stack gap="16px">
              {availableFeatures.map((availableFeature, idx) => (
                <Stack direction="row" gap="12px" key={idx}>
                  <CheckCircleIcon sx={{ color: "#065C53" }} />
                  <Typography variant="body2" fontWeight="500">
                    {availableFeature}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {unavailableFeatures.length > 0 && (
          <>
            <Stack borderTop="1px solid #B4CECB" />
            <Stack gap="16px">
              {unavailableFeatures.map((unavailableFeature, idx) => (
                <Stack direction="row" gap="12px" key={idx}>
                  <ClearRoundedIcon
                    sx={{
                      color: "#ABC6C2",
                      width: "19px",
                      height: "19px",
                    }}
                  />
                  <Typography variant="body2" fontWeight="500">
                    {unavailableFeature}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </>
        )}
        {children}
      </Stack>
    </Card>
  );
};

export default SubscriptionSuccessCard;
