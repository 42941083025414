import css from "../../../styles/sanity-styles/TextWithButton.module.scss";
import { TextWithDescriptionElementModel } from "../../../api/umbracoApi";

const TextWithDescription = ({ properties }: any) => {
  return (
    <div
      className={css.textWithButton}
      style={{ background: properties?.backgroundColor as string }}
    >
      <div className={css.textWithButtonContent}>
        <h2>
          <strong>{properties?.title as string}</strong>
        </h2>
        <p>{properties?.description as string}</p>
        <div className={css.textWithButtonButtons}></div>
      </div>
    </div>
  );
};

export default TextWithDescription;
