import DOMPurify from "dompurify";
import css from "../../../styles/Article.module.scss";
import { FC } from "react";
import ArticleTextContent from "./ArticleTextContent";
import ArticleImages from "./ArticleImages";
import ArticleContent from "./ArticleContent";

interface IProps {
  content: any;
  baseUrl: any;
}

const RenderArticlePage: FC<IProps> = ({ content, baseUrl }) => {
  switch (content.contentType) {
    case "articleTextContent":
      return <ArticleTextContent content={content} css={css} />;

    case "articleImages":
      return <ArticleImages content={content} css={css} baseUrl={baseUrl} />;

    case "articleContent":
      return <ArticleContent content={content} css={css} />;

    default:
      return (
        <div key={content.id}>
          <p>Unsupported content type: {content.contentType}</p>
        </div>
      );
  }
};

export default RenderArticlePage;
