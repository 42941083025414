import { CircularProgress } from "@mui/material";
import React from "react";

const LoadingScreen: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        //height: "60vh",
        width: "100%",
        margin: "2rem",
      }}
    >
      <CircularProgress color="primary" variant="indeterminate" />
    </div>
  );
};

export default LoadingScreen;
