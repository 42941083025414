import React, { useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { axios } from "../../api/authenticated-axios";
import useToast from "../../components/Toast/hooks";
import "./index.scss";
import PasswordField from "../custom-register/components/PasswordField";
import { validatePassword } from "../../utils";

const NewPassword: React.FC = (): JSX.Element => {
  const { handleSubmit, control, watch } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const password = watch("Password");
  const history = useHistory();
  const { showToast } = useToast();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const memberId = queryParams.get("id");

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const isSuccess = await axios.post(
        `/memberlist/CreatePassword?id=${memberId}`,
        { Password: data.Password, ConfirmPassword: data.ConfirmPassword }
      );
      if (isSuccess) {
        showToast(isSuccess.data.toString(), "success");
        history.push("/login");
      }
    } catch (error: any) {
      showToast(error?.response?.data?.errors?.Password[0], "error");
    }
    setIsLoading(false);
  };

  return (
    <Stack className="new-password">
      <form className="card" onSubmit={handleSubmit(onSubmit)}>
        <img src="/images/Pond_green.svg" alt="POND logo" />
        <Typography className="heading" fontSize="1.5rem">
          Glemt passord?
        </Typography>
        <Typography className="sub-heading" fontSize="0.875rem">
          Skriv inn et nytt passord og bekreft det nedenfor.
        </Typography>
        {/* Password field with validation */}
        <Controller
          render={({ field, fieldState }) => (
            <PasswordField
              label="Nytt passord*"
              error={!!fieldState.error}
              onChange={(val) => field.onChange(val)}
              helperText={fieldState.error ? fieldState.error.message : ""}
            />
          )}
          name="Password"
          control={control}
          rules={{
            required: "Passord er påkrevd",
            validate: validatePassword,
          }}
        />

        {/* Confirm password with validation */}
        <Controller
          render={({ field, fieldState }) => (
            <PasswordField
              label="Betreft passord*"
              error={!!fieldState.error}
              onChange={(val) => field.onChange(val)}
              helperText={fieldState.error ? fieldState.error.message : ""}
            />
          )}
          name="ConfirmPassword"
          control={control}
          rules={{
            required: "Bekreftelse av passord er påkrevd",
            validate: (value) =>
              value === password || "Passordene stemmer ikke overens",
          }}
        />
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          size="large"
        >
          {isLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            "Fortsett"
          )}
        </Button>
      </form>
    </Stack>
  );
};

export default NewPassword;
