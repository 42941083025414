const AgriaAgents = [
  {
    id: 1,
    name: "Anne Wiker",
    location: "Bjørkelangen",
    image:
      "https://www.agria.no/imagevault/publishedmedia/iggigse55bzb0xbcg8ds/Anne_Wiker.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/anne-wiker/",
  },
  {
    id: 2,
    name: "Anja Huserbråten",
    location: "Åsgreina",
    image:
      "https://www.agria.no/imagevault/publishedmedia/4ty37efjyo0n6x8ayy2h/anja-huserbraaten-med-hest.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/anja-huserbraten/",
  },
  {
    id: 3,
    name: "Agnete Torstensrud",
    location: "Rossland",
    image:
      "https://www.agria.no/contentassets/ec611e1c3b8540e4b3736c966a9ca62f/agnete2022.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/agnete-torstensrud/",
  },
  {
    id: 4,
    name: "Anne-Stine Bakke",
    location: "Lier",
    image:
      "https://www.agria.no/imagevault/publishedmedia/ordhgjx4fkfojnmi2a3m/anne-stine-nordstrand-bakke-med-hest.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/anne-stine-norstrand-bakke/",
  },
  {
    id: 5,
    name: "Beate Pilskog",
    location: "Ulsteinvik",
    image:
      "https://www.agria.no/imagevault/publishedmedia/sqmuja3lwknrui5z70s7/Beathe-Pilskog.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/beathe-pilskog/",
  },
  {
    id: 6,
    name: "Birgitte Børresen",
    location: "Åsen",
    image:
      "https://www.agria.no/imagevault/publishedmedia/yu65kh7fpxfsj5gd5uy5/birgitte-borresen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/birgitte-borresen/",
  },
  {
    id: 7,
    name: "Christina Denice Wulff-Pedersen",
    location: "Vinterbro",
    image:
      "https://www.agria.no/imagevault/publishedmedia/hyxfmwczwvlk2pxcgg7w/Forsikringsagent_Christina_Denice_Wulff-Pedersen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/christina-denice-wulff-pedersen/",
  },
  {
    id: 8,
    name: "Cecilia Wallin",
    location: "Fjellstrand",
    image:
      "https://www.agria.no/imagevault/publishedmedia/wtngo9pg4mrkaxpsclwm/Cecilia_Wallin_med_hest.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/cecilia-wallin/",
  },
  {
    id: 9,
    name: "Evy Marie Brinch",
    location: "Maura",
    image:
      "https://www.agria.no/imagevault/publishedmedia/e6o5o6esuheox14e4vcl/evy_agent.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/evy-marie-brinch/",
  },
  {
    id: 10,
    name: "Elizabeth Nordhammer",
    location: "Tveit",
    image:
      "https://www.agria.no/imagevault/publishedmedia/3cg0i6gb6fsyyg7c2fjv/elizabeth-nordhammer-med-hest.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/elizabeth-nordhammer",
  },
  {
    id: 11,
    name: "Frida Kristine Berg",
    location: "Skodje",
    image:
      "https://www.agria.no/contentassets/e4eca90edd4b4bd2b7f6c598a43de6cc/frida-berg_redigert.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/frida-kristine-berg",
  },
  {
    id: 12,
    name: "Irene Meland",
    location: "Austmarka",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/86a8nh8yurr3q38zmlgg/Irene_Meland.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/irene-meland",
  },
  {
    id: 13,
    name: "Inger Marie Meen",
    location: "Ramnes",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/2muvspk866r8yu12mp0t/Inger_Marie_Meen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/inger-marie-meen",
  },
  {
    id: 14,
    name: "Kari Anne Lundberg",
    location: "Bardu",
    image:
      "https://www.agria.no/contentassets/25e700c5a8e340bc82079ab61f796602/karianne2022.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/kari-anne-lundberg",
  },
  {
    id: 15,
    name: "Kirsten Næss",
    location: "Elverum",
    image:
      "https://www.agria.no/contentassets/fd0003754ef340aeac43a6298a847dca/kirstennass2.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/kirsten-nass",
  },
  {
    id: 16,
    name: "Kristian Nordstrøm",
    location: "Oslo",
    image:
      "https://www.agria.no/contentassets/709a6b357be049008007b38e2a37224a/kristian-nordstrom.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/kristian-nordstrom/",
  },
  {
    id: 17,
    name: "Kjersti Olsen",
    location: "Hurdal",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/dux85o7qfu1wglogh8a0/Kjersti_Olsen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/kjersti-olsen/",
  },
  {
    id: 18,
    name: "Knut Steinar Oftedal",
    location: "Klepp Stasjon",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/aqq3ljlaqexw0cza2en5/Knut_Steinar_Oftedal.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/knut-steinar-oftedal",
  },
  {
    id: 19,
    name: "Linn Hege Depui-Bakke",
    location: "Kristiansand S",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/5fdett8aw5hypjoi30v8/Linn_Hege_Depui-Bakke.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/linn-hege-bakke/",
  },
  {
    id: 20,
    name: "Linda Ødegård",
    location: "Strømmen",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/2lmk1kt7w4rk36o71630/Linda_-deg-rd.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/linda-odegard/",
  },
  {
    id: 21,
    name: "Lene Sparbo Vanvik",
    location: "Mogreina",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/1cy99k98j339akgdk4kt/Lena_Sparbo_Vanvik.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/lena-sparbo-vanvik",
  },
  {
    id: 22,
    name: "Lene N. Kalleklev",
    location: "Søreidgrend",
    image:
      "https://cms.agria.no/imagevault/media/vxdyk87za9vewg5t1hho/Lene_Kalleklev2.jpeg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/lene-kalleklev",
  },
  {
    id: 23,
    name: "Marit Rui",
    location: "Hørte",
    image:
      "https://www.agria.no/contentassets/f2af2313688547e39a4010a85d7e8b5f/marit-rui_redigert.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/marit-rui",
  },
  {
    id: 24,
    name: "Mary Jane Lie",
    location: "Eina",
    image:
      "https://www.agria.no/contentassets/61d1a368262c4252a6263d83d713f4ba/maryjanelie_1.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/mary-jane-lie",
  },
  {
    id: 25,
    name: "Mona Eliassen",
    location: "Seljord",
    image:
      "https://www.agria.no/contentassets/9d006d9cb6114c6aa8360a225679e1fa/mona-eliassen_agent.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/mona-eliassen",
  },
  {
    id: 26,
    name: "Mette Gade Larsen",
    location: "Fevik",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/l9rlt935qxvv7rjoqf81/Mette_Gade_Larsen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/mette-gade-larsen/",
  },
  {
    id: 27,
    name: "May Berg",
    location: "Vatne",
    image:
      "https://www.agria.no/imagevault/publishedmedia/up8cjoi5q03bk9gbro0r/Forsikringsagent_May_Berg.jpeg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/may-berg/",
  },
  {
    id: 28,
    name: "Marianne Schau Eriksen",
    location: "Grålum",
    image:
      "https://www.agria.no/contentassets/a7058e12df0c4c1a80d01491dcea43f2/marianne-schau-eriksen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/marianne-schau-eriksen/",
  },
  {
    id: 29,
    name: "Mariell Nordli Kristiansen",
    location: "Tangen",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/tpddcqv6rdsvvrqex5av/Mariell_Kristiansen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/mariell-nordli-kristiansen",
  },
  {
    id: 30,
    name: "Petter Steen",
    location: "Maura",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/cooufac6crl7kxfmach2/Petter_Steen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/petter-steen",
  },
  {
    id: 31,
    name: "Rita Gulliksen",
    location: "Steinsdalen",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/28jdk718c3fg8lkchlgc/Rita_Gulliksen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/rita-gulliksen/",
  },
  {
    id: 32,
    name: "Roar Nilssen",
    location: "Aurskog",
    image:
      "https://www.agria.no/imagevault/publishedmedia/t5f0qgitxr9c9h9rykam/Roar_Nilssen.jpeg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/roar-nilssen",
  },
  {
    id: 33,
    name: "Roy Utgaard",
    location: "Namnå",
    image:
      "https://www.agria.no/imagevault/publishedmedia/oba1l6hivrv3tgtlc834/Roy_Utgaard.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/roy-utgaard",
  },
  {
    id: 34,
    name: "Sissel Hvitsand",
    location: "Ler",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/99uhpw8xuojonc3p4xll/Sissel_Hvitsand.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/sissel-hvitsand/",
  },
  {
    id: 35,
    name: "Siv Helen Hennig-Till",
    location: "Eidsvoll",
    image:
      "https://www.agria.no/ImageVault/publishedmedia/q7ezysj7j0w5pv330nti/Siv-Helen_Hennig-Till.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/siv-helen-hennig-till/",
  },
  {
    id: 36,
    name: "Wenche Elisabeth Jensen",
    location: "Halden",
    image:
      "https://www.agria.no/imagevault/publishedmedia/p0g6tog5ampek4k4wjvm/wenche-jensen.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/wenche-jensen/",
  },
  {
    id: 37,
    name: "Aasa Kristin Sandøy",
    location: "Vikebukt",
    image:
      "https://www.agria.no/imagevault/publishedmedia/fyqm7x3alr4535s32dis/aasa-kristin-sand-y-med-hest.jpg",
    web: "https://www.agria.no/kontakt-oss/agrias-agenter/aasa-kristin-sandoy/",
  },
];

export default AgriaAgents;
