import React from "react";
import Button from "../../Button";
import css from "../../../styles/sanity-styles/TextWithIllustration.module.scss";
import { useIsMobile } from "../../../hooks/useIsMobile";
import cssButton from "../../../styles/sanity-styles/TextWithButton.module.scss";
import { Stack } from "@mui/material";
//import { PortableText } from "@portabletext/react";

const handleRedirect = (url: any) => {
  if (url) {
    window.location.href = url;
  }
};

const TextWithImage: React.FC<any> = ({ properties }: any) => {
  const { isMobile } = useIsMobile();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <div
      className={css.textWithIllustration}
      style={{ backgroundColor: properties.backgroundColor }}
    >
      <div
        className={css.textWithIllustrationContent}
        style={{
          flexDirection:
            !isMobile && properties.imageDirection === "Venstre"
              ? "row-reverse"
              : isMobile
              ? "column"
              : isMobile
              ? "column"
              : "row",
        }}
      >
        <div>
          <div className={css.textWithIllustrationContentText}>
            <h2>
              <strong>{properties.title}</strong>
            </h2>
            <p>{properties.description}</p>
          </div>

          <div className={css.textWithButtonButtons}>
            <Stack gap={2} paddingX="3rem" marginTop="4rem">
              {properties.primaryLink?.length > 0 && (
                <Button
                  label={properties.primaryLink[0].title}
                  skin="dark"
                  style={{ width: 240 }}
                  onClick={() => handleRedirect(properties.primaryLink[0].route.path)}
                />
              )}
              {properties.secondaryLink?.length > 0 && (
                <Button
                  label={properties.secondaryLink[0].title}
                  skin="light"
                  style={{ width: 240 }}
                  onClick={() => handleRedirect(properties.secondaryLink[0].route.path)}
                />
              )}
            </Stack>
          </div>
        </div>
        <div className={css.textWithIllustrationImage}>
          {/* <img
            className={css.desktopImage}
            src={baseUrl + properties.uploadImage[0].url}
            alt={properties.title}
          /> */}
          {/* <picture>
            <source
              media="(max-width: 800px)"
              srcSet={
                baseUrl + properties.uploadImage[0].url
                  ? `${
                      baseUrl + properties.uploadImage[0].url
                    }?width=387&height=387`
                  : ""
              }
            />
            <img
              className={css.desktopImage}
              src={
                baseUrl + properties.uploadImage[0].url
                  ? `${
                      baseUrl + properties.uploadImage[0].url
                    }?width=520&height=520`
                  : ""
              }
              alt={properties.title || "Om Oss Image"}
            />
          </picture> */}
          <picture>
            <source
              media="(max-width: 800px)"
              srcSet={
                properties?.uploadImage?.[0]?.url
                  ? `${
                      baseUrl + properties.uploadImage[0].url
                    }?width=387`
                  : ""
              }
            />
            <img
              className={css.desktopImage}
              src={
                properties?.uploadImage?.[0]?.url
                  ? `${
                      baseUrl + properties.uploadImage[0].url
                    }?width=520`
                  : ""
              }
              alt={properties?.title || "Om Oss Image"}
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default TextWithImage;
