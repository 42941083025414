import { FC } from "react";
import { Stack, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import "./index.scss"
import useVerifyEmail from "./hooks/useVerifyEmail";
import LoadingScreen from "../../components/LoadingScreen";

const VerifyEmail: FC = ()=> {
    const history = useHistory();
    const { isEmailVerified, isEmailVerifying } = useVerifyEmail();

    if(isEmailVerifying) {
        return <Stack className="custom-register">
            <Stack maxWidth={564} gap={4} py={2} px={1} textAlign="center" alignItems="center" margin="auto">
                <LoadingScreen />
            </Stack>
        </Stack>
    }
    return (
        <>{ isEmailVerified? <Stack className="custom-register">
            <Stack maxWidth={564} gap={4} py={2} px={1} textAlign="center" alignItems="center" margin="auto">
                <Typography fontSize="50px" color="primary.main" fontWeight="400">Din e-postadresse er verifisert!</Typography>
                <Typography color="primary.main" fontSize="21px" fontWeight="300" lineHeight="42px">Du er nå bare tre enkle steg unna å fullføre oppsettet av din profil. La oss komme i gang!</Typography>
                <img src="/images/husky.png" alt="dog" style={{height: 211}} />
                <Stack alignItems="center" gap={"18px"}>
                    <Button height="56px" label="Kom i gang!" skin="dark" onClick={()=> history.push("/on-boarding")} style={{ width: 345 }} />
                </Stack>
            </Stack>
        </Stack>: <Stack className="custom-register">
            <Stack maxWidth={564} gap={4} py={2} px={1} textAlign="center" alignItems="center" margin="auto">
                {/* <Typography fontSize="50px" color="primary.main" fontWeight="400">Din e-postadresse er verifisert!</Typography>
                <Typography color="primary.main" fontSize="21px" fontWeight="300" lineHeight="42px">Du er nå bare tre enkle steg unna å fullføre oppsettet av din profil. La oss komme i gang!</Typography>
                <img src="/images/husky.png" alt="dog" style={{height: 211}} /> */}
                <Stack alignItems="center" gap={"18px"}>
                    <Button height="56px" label="Back To Login" skin="dark" onClick={()=> history.push("/login")} style={{ width: 345 }} />
                </Stack>
            </Stack>
        </Stack>}</>
    );
};

export default VerifyEmail;