import React from "react";
import { Typography, Stack, Card } from "@mui/material";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";

interface Iprops {
  title: string;
  subtitle: string;
  description: string;
  price: string;
  children?: React.ReactNode;
  features: any;
}

const SubscriptionCard: React.FC<Iprops> = ({
  title,
  subtitle,
  description,
  price,
  features,
  children,
}: Iprops) => (
  <Card
    sx={{
      width: "100%",
      maxWidth: { xs: "100%", md: "429px" },
      borderRadius: "24px",
      border: "2px solid #B4CECB",
      padding: { xs: "20px", md: "32px" },
      marginTop: "20px",
    }}
  >
    <Stack gap="8px">
      <Typography
        fontSize={{ xs: "24px", md: "28px" }}
        fontWeight="700"
        lineHeight={{ xs: "34px", md: "40px" }}
        color="#065C53"
      >
        {title}
      </Typography>
      <Typography
        fontSize={{ xs: "14px", md: "16px" }}
        fontWeight="400"
        lineHeight={{ xs: "22px", md: "26px" }}
        color="#3E3E59"
      >
        {description}
      </Typography>
    </Stack>
    <Typography
      fontSize={{ xs: "42px", md: "56px" }}
      color="#065C53"
      fontWeight="700"
      lineHeight={{ xs: "64px", md: "84px" }}
    >
      {price}
    </Typography>
    <Typography fontWeight="400" fontSize="16px" lineHeight="30px">
      {subtitle}
    </Typography>
    <Stack gap="20px">
      {features?.items?.map((feature:any, index:number) => {

        if(feature?.content?.properties?.featureIncluded){
          return(
          <Stack key={index} gap="12px" direction="row" alignItems="center">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 28" fill="none">
            <circle cx="13.5" cy="14" r="13.5" fill="#065C53"/>
            <path d="M8 14L11.5 17.5L18.5 10.5" stroke="#FDFCFB"/>
          </svg>
          
        
         <Typography
            fontSize="16px"
            fontWeight="500"
            lineHeight="32px"
            color="#065C53"
          >
            {feature?.content?.properties?.title}
          </Typography>
        </Stack>

        )  }else{
          return(

          <Stack key={index} gap="12px" direction="row" alignItems="center">
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 28" fill="none">
              <circle cx="13.5" cy="14" r="13.5"  stroke="#ABC6C2"/>
              <path d="M8 8L19 19" stroke="#ABC6C2"/>
              <path d="M8 19L19 8" stroke="#ABC6C2"/>
          </svg>
        
         <Typography
            fontSize="16px"
            fontWeight="500"
            lineHeight="32px"
            color="#065C53"
          >
            {feature?.content?.properties?.title}
          </Typography>
        </Stack>
      )}

        // <Stack key={index} gap="12px" direction="row" alignItems="center">
        //   <DoneOutlinedIcon sx={{ fontSize: "16px" }} />
        //   <Typography
        //     fontSize="16px"
        //     fontWeight="500"
        //     lineHeight="32px"
        //     color="#065C53"
        //   >
        //     {feature}
        //   </Typography>
        // </Stack>
      })}
    </Stack>
    {children}
  </Card>
);

export default SubscriptionCard;
