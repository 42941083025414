import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import DashboardCard from "../../components/Dashboard/DashboardCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/Dashboard.module.scss";
import Footer from "./Footer";
import LoadingScreen from "../../components/LoadingScreen";
import { Alert, AlertTitle } from "@mui/material";
import useAuth from "../../hooks/useAuth0";

const Dashboard: React.FC = () => {
  const { logout, user, isAuthenticated, getUserInfo, isUserInfoLoading } =
    useAuth();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isMobile } = useIsMobile();
  const history = useHistory();
  // get latest user info
  useEffect(() => {
    window.analytics.page("Min POND");
    getUserInfo();
  }, []);

  useEffect(() => {
    if (!isAuthenticated) {
      history.push("/");
    }
    setIsLoading(false);
  }, [isAuthenticated]);

  useEffect(() => {
    if (user && user.isRegisterStepsCompleted == false) {
      history.push("/on-boarding");
    }
    setIsLoading(false);
  }, [user]);

  if (isLoading || isUserInfoLoading) {
    return <LoadingScreen />;
  }
  const isBreeder = user?.breeder;

  const dashboardText = isBreeder
    ? "Her har du full oversikt over ditt oppdrett, tilrettelagt for å gjøre det enklest mulig for deg"
    : "";

  return (
    <div className={css.Dashboard}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Dashboard" next="Min profil >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image={user?.avatarImage ?? null}
          title={`Velkommen tilbake, ${user?.firstName ?? ""}!`}
          percentage={60}
          subTitle="Min POND"
          info={dashboardText}
          customWidth="243px"
        />
        {isBreeder && (
          <Alert
            variant="outlined"
            severity="info"
            sx={{ marginTop: 2 }}
            icon={false}
          >
            <AlertTitle>{`QualiPet rabattkode`}</AlertTitle>
            {`«PondQP40» - Denne vil gi 40% rabatt på alle produkter, bortsett fra allerede nedsatte varer, og vil heller ikke kunne brukes i kombinasjon med andre koder.`}
          </Alert>
        )}
        <div className={css.cardContainer}>
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Min profil"
              icon={
                <img src="/images/icons/dashboard/user.svg" alt="Person ikon" />
              }
              description="Se hvordan profilen din ser ut for andre!"
              onClick={() => history.push(`/oppdretter/${user?.memberId}`)}
            />
          )}
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Rediger kennel"
              icon={
                <img
                  src="/images/icons/dashboard/edit.svg"
                  alt="Rediger ikon"
                />
              }
              description="Endre din kennel."
              onClick={() => history.push(`/min-pond/rediger-profil`)}
            />
          )}
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Valpekull"
              icon={
                <img
                  src="/images/icons/dashboard/puppies.svg"
                  alt="To poter ikon"
                />
              }
              description="Legg til og administrer valpekull."
              onClick={() => history.push(`/min-pond/mine-valper`)}
            />
          )}
          {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Avlshunder"
              icon={
                <img src="/images/icons/dashboard/paw.svg" alt="Pote ikon" />
              }
              description="Avlshunder og oppdretters hunder."
              onClick={() => history.push(`/min-pond/avlshunder`)}
            />
          )}
          <DashboardCard
            className={css.card}
            title="Favoritter"
            icon={
              <img
                src="/images/icons/dashboard/paw.svg"
                alt="Innstillinger ikon"
              />
            }
            onClick={() => history.push(`/min-pond/favoritter`)}
            description="Oversikt over dine favioritter."
          />

          <DashboardCard
            className={css.card}
            title="Mine søknader"
            icon={<img src="/images/icons/dashboard/file.svg" alt="Fil ikon" />}
            onClick={() => history.push(`/min-pond/mine-soknader`)}
            description="Oversikt over dine søknader."
          />
          {/* {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Referanser"
              icon={
                <img
                  src="/images/icons/dashboard/smile.svg"
                  alt="Innstillinger ikon"
                />
              }
              onClick={() => {
                history.push(`/min-pond/referanser`);
              }}
              description="Oversikt over alle dine referanser."
            />
          )} */}
          <DashboardCard
            className={css.card}
            title="Kontoinnstillinger"
            icon={
              <img
                src="/images/icons/dashboard/settings.svg"
                alt="Innstillinger ikon"
              />
            }
            onClick={() => history.push(`/min-pond/kontoinnstillinger`)}
            description="Endre din profil."
          />
          {/* {isBreeder && (
            <DashboardCard
              className={css.card}
              title="Abonnement"
              icon={
                <img
                  src="/images/icons/dashboard/credit-card.svg"
                  alt="credit-card icon"
                />
              }
              onClick={() => history.push(`/price-subscription`)}
              description="Administrer ditt Pond-abonnement."
            />
          )} */}
        </div>

        {isMobile && (
          <div className={css.buttonContainer}>
            {/* {isBreeder ? (
              <Button label="Bytt til vanlig bruker" skin="transparent" />
            ) : (
              <Button label="Bytt til oppdretter bruker" skin="transparent" />
            )} */}
            <Button label="Logg ut" skin="lightGreen" onClick={logout} />
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

export default Dashboard;
