import useAuth0 from "../../hooks/useAuth0";
import { Tabs, Tab, Stack, CircularProgress } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import MyPuppiesCard from "../../components/Dashboard/MyPuppiesCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/MyPuppies.module.scss";
import Footer from "./Footer";
import {
  Availability,
  useDeactivateAdMutationMutation,
  useMyAdsWithParentsQuery,
  useUpdateAdStatusMutationMutation,
} from "../../api/graphql.gen";

const MyPuppies: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [tab, setTab] = useState("1");
  const [selectedAd, setSelectedAd] = useState(0);
  const { isLoading } = useAuth0();
  const { data, refetch } = useMyAdsWithParentsQuery();
  const mydogads = data?.dogAdQuery.myAdsWithParents;
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const history = useHistory();
  const { mutate: deactivate } = useDeactivateAdMutationMutation({
    onSuccess: () => {
      refetch();
      setShowDelete(false);
    },
  });

  const { mutate: updateStatus } = useUpdateAdStatusMutationMutation({
    onSuccess: (result) => {
      history.push(
        `/min-pond/mine-valper/rediger/${
          result.dogAdMutation.updateAdStatus.id
        }/${
          result.dogAdMutation.updateAdStatus.adType === "PLANNED"
            ? "kommende"
            : "fodt"
        }`
      );
    },
  });

  useEffect(() => {
    window.analytics.page("Mine valper");
  }, []);

  useEffect(() => {
    window.analytics.page("Mine valper");
  }, []);

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const [showDelete, setShowDelete] = useState(false);

  const deleteAd = (id: number) => {
    deactivate({ adId: id });
  };

  const activeAds = mydogads?.filter((item) => item.adType !== "DELETED");

  const deletedAds = mydogads?.filter((item) => item.adType === "DELETED");
  const renderTabs = () => (
    <div>
      {tab === "1" ? (
        <div>
          {activeAds?.length ? ( // Ensure activeAds is being used for checking length
            <div className={css.puppiesContainer}>
              {activeAds?.map((ad:any) => {
                const adImage = ad.adImage == "/images/placeholder-dog.png" || ad.adImage =="" || !ad.adImage ? "" : JSON.parse(ad.adImage);
               return (
               <MyPuppiesCard
                  key={ad.title}
                  title={ad.title}
                  birthDate={format(new Date(ad.birthDate), "dd-MM-yyyy")}
                  imgSrc={adImage ? urlFor(adImage?.url+"?width=500&height=500") : "/images/placeholder-dog.png"}
                  imgAlt="Annonse bilde"
                  soldButton
                  status={ad.adType}
                  changeType={() =>
                    updateStatus({
                      adStatus: ad.adType as Availability,
                      adId: ad.id,
                    })
                  }
                  onClick={() =>
                    history.push(
                      `/min-pond/mine-valper/rediger/${ad.id}/${
                        ad.adType === "AVAILABLE"
                          ? "fodt"
                          : ad.adType === "PLANNED"
                          ? "planlagt"
                          : "kommer"
                      }`
                    )
                  }
                  onDelete={() => {
                    setSelectedAd(ad.id);
                    setShowDelete(true);
                  }}
                />
              
              )})}
              <Button
                label="Legg til"
                skin="lightGreen"
                onClick={() => history.push("/nytt-valpekull")}
              />
            </div>
          ) : (
            <div className={css.noPuppies}>
              <p>Du har ingen registrerte valpekull </p>
              <Button
                label="Legg til"
                skin="lightGreen"
                onClick={() => history.push("/nytt-valpekull")}
              />
            </div>
          )}
        </div>
      ) : null}
      {tab === "2" && (
        <>
          <div>
            {deletedAds?.length ? (
              <div className={css.puppiesContainer}>
                {deletedAds?.map((ad) => (
                  <MyPuppiesCard
                    key={ad.title}
                    title={ad.title}
                    birthDate={format(new Date(ad.birthDate), "dd-MM-yyyy")}
                    imgSrc={ad.adImage ?? "/images/placeholder-dog.png"}
                    imgAlt="Annonse bilde"
                    onDelete={() => {
                      setSelectedAd(ad.id);
                      setShowDelete(true);
                    }}
                  />
                ))}
              </div>
            ) : (
              <div className={css.noPuppies}>
                <p>Du har ingen tidligere valpekull</p>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );

  return (
    <div className={css.MyPuppies}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Mine valper" next="Avlshunder >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/dog-face-circle.svg"
          percentage={60}
          title="Mine valper"
          info="Her kan du legge til valpekull. Du kan legge til fødte, kommende og planlagte, og administrere tidligere kull."
          customWidth={""}
        />
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <Tabs
            value={tab}
            onChange={(_event, value) => setTab(value)}
            variant="fullWidth"
          >
            <Tab value="1" label="Aktive" />
            <Tab value="2" label="Slettet" />
          </Tabs>
        )}
        {renderTabs()}
      </div>
      <Footer />
      <ConfirmationAlert
        title="Sletter annonse"
        content="Er du sikker på at du vil slette annonsen?"
        open={showDelete}
        handleCancel={() => setShowDelete(false)}
        handleClose={() => setShowDelete(false)}
        handleConfirm={() => deleteAd(selectedAd)}
      />
    </div>
  );
};

export default MyPuppies;
