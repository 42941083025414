import { createContext, useContext, FC, ReactElement, useState, useEffect } from "react";
import { axios } from "../api/authenticated-axios";
import { useUmbracoContentQuery } from "../hooks/useUmbracoQuery";
import { IApiContentResponsePagedViewModel } from "../api/umbracoApi";

type IProp = {
    children: ReactElement;
};

interface IOnBoardingProps {
    agentItems: any,
    breedItems: any,
    clubItems: any,
    testItems: any
}

export const OnBoarding = createContext({} as IOnBoardingProps);

export const useOnBoardingContext = ()=> useContext(OnBoarding);

export const OnBoardingContextProvider: FC<IProp> = ({ children }) => {
    const { data: agriaAgents } =  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
        filter: ["contentType:agriaAgentItem"],
      });
      
      const agentItems = agriaAgents?.items;
    
      const { data: breeds } =  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
        filter: ["contentType:dogBreedItem"],
      });
      
      const breedItems = breeds?.items;
    
      const { data: clubs } =  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
        filter: ["contentType:clubItem"],
      });
      
      const clubItems = clubs?.items;

      const { data: tests } =  useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
        filter: ["contentType:medicalTestItem"],
      });

    const testItems = tests?.items;    

    const defaultContext = {
        agentItems,
        breedItems,
        clubItems,
        testItems
        
    };
    return (
        <OnBoarding.Provider value={defaultContext}>
            {children}
        </OnBoarding.Provider>
    );
};