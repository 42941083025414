import React from "react";
import css from "../../../styles/sanity-styles/TextWithButton.module.scss";

const TextWithButton = ({ properties }: any) => {
  return (
    <div
      className={css.textWithButton}
      style={{ backgroundColor: properties.backgroundColor }}
    >
      <div className={css.textWithButtonContent}>
        <h3>{properties.textContent}</h3>
        <div className={css.textWithButtonButtons}></div>
      </div>
    </div>
  );
};

export default TextWithButton;
