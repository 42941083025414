import {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  ReactElement,
  useCallback,
  useMemo,
} from "react";
import { BASEURL } from "../api/authenticated-axios";
import publicAxios from "axios";
import { axios } from "../api/authenticated-axios";
import { useQuery } from "react-query";

interface AuthContextInterface {
  isLoading: boolean;
  isAuthenticated: boolean;
  isExternalLogin: boolean;
  setIsAuthenticated: (auth: boolean) => void;
  user: any;
  login: (route: string, params: any, isExternalLogin: boolean) => Promise<boolean>;
  logout: () => void;
  loginWithRedirect: (ob?: any) => void;
  ApiCallWithToken: (
    route: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any
  ) => Promise<any>;
  getUserInfo: () => void;
  initAuth: () => void;
  isUserInfoLoading: boolean;
}
const AuthContext = createContext<AuthContextInterface>(
  {} as AuthContextInterface
);
export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isExternalLogin, setIsExternalLogin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const key = ["userProfileInfo"];
  const {
    data,
    refetch,
    isLoading: isUserInfoLoading,
  } = useQuery<any>(key, () => axios.get("memberList/MyProfile/me"), {
    enabled: localStorage.getItem("accessToken") != null,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    initAuth();
  }, []);

  useEffect(() => {
    if (data) {
      setUser(data.data);
    }else{
      setUser({})
    }
  }, [data]);

  const initAuth = useCallback(async () => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      setIsAuthenticated(true);
      await getUserInfo();
    }else{
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  const getUserInfo = useCallback(async () => {
    try {
      refetch();

      //const res: any = await axios.get("memberList/MyProfile/me");
      //setUser(data);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsAuthenticated(false);
      setIsLoading(false);
    }
  }, []);

  const login = async (route: string, params: any, isExternalLogin = false) => {
    try {
      setIsLoading(true);
      const response = await publicAxios.post(`${BASEURL}${route}`, params);
      localStorage.setItem("accessToken", response.data["accessToken"]);
      localStorage.setItem("refreshToken", response.data["refreshToken"]);
      setIsLoading(false);
      setIsAuthenticated(true);
      setIsExternalLogin(isExternalLogin);
      return true;
    } catch (error: any) {
      setIsAuthenticated(false);
      setIsLoading(false);
      throw error;
    }
  };

  const ApiCallWithToken = async (
    route: string,
    method: "GET" | "POST" | "PUT" | "DELETE",
    body?: any
  ) => {
    const accessToken = localStorage.getItem("accessToken");

    try {
      const response = await axios({
        url: route,
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: accessToken ? `Bearer ${accessToken}` : "",
        },
        data: body,
      });
      return response.data;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser({});
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
  };

  const loginWithRedirect = () => {
    console.log("login with rediect");
  };
  const contextValue = useMemo(
    () => ({
      isAuthenticated,
      setIsAuthenticated,
      initAuth,
      user,
      login,
      logout,
      loginWithRedirect,
      ApiCallWithToken,
      isLoading,
      getUserInfo,
      isUserInfoLoading,
      isExternalLogin
    }),
    [
      isAuthenticated,
      setIsAuthenticated,
      initAuth,
      user,
      login,
      logout,
      loginWithRedirect,
      ApiCallWithToken,
      isLoading,
      getUserInfo,
      isUserInfoLoading,
      isExternalLogin
    ]
  );
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default useAuth;
