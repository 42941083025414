import { OpenAPI, ContentResource } from "./umbracoApi";

// Update the OpenAPI base URL to allow us to connect to the API
// My environment variable here is the http version of my local URL

OpenAPI.BASE = process.env.REACT_APP_API_BASE_URL ??"https://pond-api.codify.no"; //import.meta.env.PUBLIC_BASE_URL;

//const umbracoDeliverApiUrl = "umbraco/delivery/api/v2/content/item/";
const getUmbracoContentItem = async (filter: string): Promise<any> => {
  const res = await ContentResource.getContentItemById20({ id: filter });
  return res;
};

const getUmbracoContent = async ({
  fetch,
  filter,
  sort,
  skip,
  take = 10,
  expand,
  fields,

}: {
  fetch?: string;
  filter?: Array<string>;
  sort?: Array<string>;
  skip?: number;
  take?: number;
  expand?: string;
  fields?: string;
  startItem?: string;
}): Promise<any> => {
  const res = await ContentResource.getContent20({
    fetch: fetch,
    filter,
    sort,
    skip,
    expand,
    fields,
    take: 1000,
  });
  return res;
};

export { getUmbracoContent, getUmbracoContentItem };
