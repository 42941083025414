import { Stack, Typography } from "@mui/material";


const EmailSuccess = ({email = ''}:{email?:string}) => {

    return (
        <Stack maxWidth={564} gap={4} py={2} px={1} textAlign="center" alignItems="center" margin="auto">
            <Typography fontSize='50px' color="primary.main" fontWeight='400'>Takk for at du registrerer deg!</Typography>
            <Stack gap={4} alignItems="center">
                <Typography color="primary.main" fontSize="21px" fontWeight='300' lineHeight="42px">
                    Vi har sendt en e-post til {email} med en lenke for å bekrefte din e-postadresse. Vennligst klikk på lenken for å aktivere din konto og fortsette prosessen.
                </Typography>
                <Typography color="primary.main" fontSize="21px" fontWeight='300' lineHeight="42px">
                    Finner du ikke e-posten? Sjekk spam-mappen.
                </Typography>
                <img src="/images/girl-on-laptop.png" alt="dog" style={{height: 232}} />
            </Stack>
        </Stack>
    );
};

export default EmailSuccess;