import React from "react";
import Button from "../../Button"; // Assuming you have a Button component
import { useHistory } from "react-router-dom";

const MultipleTextSection = ({
  properties,
  isMobile,
  loginWithRedirect,
  css,
  isAuthenticated,
  readMoreRef,
}: any) => {
  const multipleTextItems = properties.multipleTextItems;
  const redirectUrl = properties.redirectUrl;
  const title = properties.title;
  const { items } = multipleTextItems;
  
  const history = useHistory();

  const handleButtonClick = () => {
    if (isAuthenticated) {
      window.location.reload();
    } else {
      history.push('/login');
    }
  };
  return (
    <div className={css.whySection} ref={readMoreRef}>
      <div className={css.content}>
        <h2>{title}</h2>
        {!isMobile && <p>{properties.detail}</p>}
        {!isMobile && (
          <Button
            label={redirectUrl[0]?.title}
            onClick={handleButtonClick}
          />
        )}
        <div className={css.cardContainer}>
          {items.map((item: any, index: number) => (
            <div
              key={item.content.id}
              className={
                item.content.properties.type === "Dark"
                  ? css.breederCard
                  : css.buyerCard
              }
            >
              <h4>{item.content.properties.title}</h4>
              <div>
                {item.content.properties.multipleStrings.map(
                  (text: any, idx: any) => (
                    <p key={idx}>
                      <img
                        src={
                          item.content.properties.type === "Dark"
                            ? "/images/home/darkCheck.svg"
                            : "/images/home/lightCheck.svg"
                        }
                        alt="Checkmark"
                      />
                      {text}
                    </p>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
        {isMobile && (
          <Button
            label={redirectUrl[0]?.title}
            onClick={() => {
              loginWithRedirect({
                openUrl: async (url: any) => window.location.replace(url),
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default MultipleTextSection;
