import { useQuery } from "react-query";
import {
  getUmbracoContent,
  getUmbracoContentItem,
} from "../api/umbracoApiService";

export function useUmbracoContentItemQuery<T>(id: string) {
  const key = [id == "" ? "home" : id];
  const result = useQuery<T>(key, () => getUmbracoContentItem(id));
  return result;
}

export function useUmbracoContentQuery<T>({
  fetch,
  filter,
  sort,
  skip,
  take = 10,
  expand,
  fields,
}: {
  fetch?: string;
  filter?: Array<string>;
  sort?: Array<string>;
  skip?: number;
  take?: number;
  expand?: string;
  fields?: string;
}) {
  const key = filter ? filter : fetch ?? "key";
  const result = useQuery<T>(key, () => getUmbracoContent({
    fetch,
    filter,
    sort,
    skip,
    take,
    expand,
    fields,
  }));
  return result;
}
