export enum FileType {
  Kennel,
  Puppy,
  DogAd,
  DogParent,
  Avatar,
  HealthCertificate,
  HealthTestDocument,
  BreederVerification,
}
