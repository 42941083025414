import { useQuery, useMutation, useQueryClient } from "react-query";
import { axios } from "../api/authenticated-axios";
import {
  getFilteredFeedbacks,
  getMyFeedbacks,
  getMyFeedbacksById,
  updateFeedBackStatus,
} from "../api/feedback.service";

// Interface Definitions
interface UpdateDogFeedbackParams {
  id: number;
  feedbackStatus: number;
}

interface DogFeedbackParams {
  id?: string;
  userId?: string;
  feedbackUserId?: string;
  dogRegistrationNumber?: string;
  feedbackDescription?: string;
  feedbackDate?: string;
  feedbackStatus?: string;
}

export interface ReceiverDogFeedback {
  feedbackId: number;
  senderName?: string;
  registrationNumber?: string;
  feedbackDescription?: string;
  feedbackStatus: boolean;
}

// Hook for Fetching Feedback List
export const useMyFeedbackList = () => {
  try {
    const key = ["getMyFeedbacks"];
    return useQuery(key, getMyFeedbacks);
  } catch (error) {
    console.error("Error fetching feedback list:", error);
    throw error;
  }
};

// Hook for Fetching Filtered Feedback List
export const useFilteredFeedbackList = (params: any) => {
  try {
    const key = ["getMyFeedbacks"];
    return useQuery(key, () => getFilteredFeedbacks(params));
  } catch (error) {
    console.error("Error fetching feedback list:", error);
    throw error;
  }
};

// Hook for Fetching Feedback by ID
export const useMyFeedbackListById = (params: any) => {
  const key = ["getMyFeedbacksById", params];

  return useQuery(key, () => getMyFeedbacksById(params), {
    onError: (error) => {
      console.error("Error fetching feedback by ID:", error);
    },
  });
};

// Hook for Managing Feedback (Create, Update, Update Status)
const useDogFeedback = () => {
  const queryClient = useQueryClient();

  // Mutation for Creating Feedback
  const createFeedback = async (params: DogFeedbackParams) => {
    try {
      const response = await axios.post("/dogfeedback/create", params);
      return response.data;
    } catch (error) {
      console.error("Error creating feedback:", error);
      throw error;
    }
  };

  // Mutation for Updating Feedback
  const updateFeedback = async (params: UpdateDogFeedbackParams) => {
    try {
      const response = await axios.put("/dogfeedback/update", params);
      return response.data;
    } catch (error) {
      console.error("Error updating feedback:", error);
      throw error;
    }
  };

  // Mutation for Updating Feedback Status
  const updateFeedbackStatus = useMutation(
    async (params: { id: number; feedbackStatus: number }) => {
      return await updateFeedBackStatus(params);
    },
    {
      onSuccess: () => {
        // Invalidate the feedback list to refetch updated data
        queryClient.invalidateQueries("getMyFeedbacks");
      },
    }
  );

  return {
    createFeedback,
    updateFeedback,
    updateFeedbackStatus,
  };
};

export default useDogFeedback;
