import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { axios } from "../../../api/authenticated-axios";
import useAuth from "../../../hooks/useAuth0";
import useToast from "../../../components/Toast/hooks";
import { FileType } from "../../../enums";

const useOnBoarding = () => {
  const history = useHistory();
  const [selected, setSelected] = useState(0);
  const [stepsData, setStepsData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { setIsAuthenticated, initAuth } = useAuth();
  const { showToast } = useToast();

  useEffect(() => {
    const token = localStorage.getItem("accessToken") || "";
    if (token) {
      //setIsAuthenticated(true);
      initAuth(); 
    } else {
      history.push("/login");
    }
  }, []);

  const createMediaJson = (media: any) => {
    if (!media) return null;
    const file = {
      id: media.mediaId,
      url: media.mediaUrl,
    };
    return JSON.stringify(file);
  };

  const onSuccess = async (step: number, data: any) => {
    const tempData = { ...stepsData, ...data };
    if (step === 3) {
      try {
        setIsLoading(true);
        if (tempData.isBreeder) {
          const promiseArray = [];
          if (tempData.BannerImage) {
            const banner = new FormData();
            banner.append("file", tempData.BannerImage);
            banner.append("fileType", FileType.Kennel.toString());
            promiseArray.push(axios.post("/file/upload", banner));
          }
          if (tempData.ProfilePicture) {
            const profile = new FormData();
            profile.append("file", tempData.ProfilePicture);
            profile.append("fileType", FileType.Avatar.toString());
            promiseArray.push(axios.post("/file/upload", profile));
          }
          let res: any = [{ data: "" }, { data: "" }];
          if (promiseArray.length !== 0) res = await Promise.all(promiseArray);
          const BannerImage = createMediaJson(res[0].data);
          const requestData = {
              ...tempData,
              BannerImage: BannerImage,
              ProfilePicture: res && res.length >1? res[1].data?.mediaUdi?.uriValue?.toString()?? null : null,
              Breeds: tempData?.Breeds|| [],
              MedicalTests: tempData?.MedicalTests || [],
              FirstYear: Number(tempData.FirstYear),
              AgriaAgentId: tempData?.AgriaAgentId.toString(),
              NKKCheck: tempData?.NKKId?.length > 0 || false
            };
          await axios.post("/register/userregister", requestData);
        } else {
          await axios.post("/register/userregister", {
            ...tempData,
            Breeds: tempData?.Breeds|| [],
             });
        }
      } catch (e: any) {
        console.log("error", e);
        setIsLoading(false);
        showToast(
          e?.response?.data?.title ||
            "Error: try contacting your administrator",
          "error"
        );
        return;
      }
    }
    setSelected(step);
    setStepsData(tempData);
  };

  return { selected, setSelected, onSuccess, isLoading };
};

export default useOnBoarding;
