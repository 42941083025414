import React from "react";
import { useHistory } from "react-router-dom";
import css from "../styles/ArticleCard.module.scss";

interface ArticleCardProps {
  img: string;
  articleType: string;
  title?: string | undefined | null;
  url: string;
  mobile: boolean;
  breed: string | null;
  date: Date;
  className?: string;
}

const ArticleCard: React.FC<ArticleCardProps> = ({
  img,
  title,
  url,
  articleType,
  date,
  breed,
  className,
  mobile = false,
}: ArticleCardProps) => {
  const history = useHistory();
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return (
    <div
      className={`${
        mobile ? css.articleCardMobile : css.articleCard
      } ${className}`}
      onClick={() => history.push(`${url}`)}
    >
      {/* <picture>
        <source
          media="(max-width: 800px)"
          srcSet={baseUrl + img ? `${baseUrl + img}?width=387&height=200` : ""}
        />
        
      </picture> */}
      <img
        style={{ borderTopRightRadius: "10px", borderTopLeftRadius: "10px" }}
        src={baseUrl + img ? `${baseUrl + img}` : ""}
        alt={articleType}
      />
      <div className={css.content}>
        <div>
          <p className={css.type}>{articleType ?? ""}</p>
          <h3 className={css.title}>{title}</h3>
          <p className={css.breed}>{breed}</p>
        </div>
        {!mobile && (
          <div className={css.button} onClick={() => history.push(`${url}`)}>
            Les mer
          </div>
        )}
      </div>
    </div>
  );
};

export default ArticleCard;
