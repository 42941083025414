import React from "react";
import { useHistory } from "react-router-dom";
import css from "../../styles/DashboardBreadcrumbs.module.scss";

interface BreadcrumbsProps {
  prev: string;
  next: string;
}

const DashboardBreadcrumbs: React.FC<BreadcrumbsProps> = ({
  prev,
  next,
}: BreadcrumbsProps) => {
  const history = useHistory();

  return (
    // <div className={css.DashboardBreadcrumbs}>
    //   <div
    //     className={css.breadCrumbs}
    //     onClick={() => history.replace("/min-pond")}
    //   >
    //     {prev}
    //   </div>
    //   <div className={css.breadCrumbsRight}>{next}</div>
    // </div>
    <></>
  );
};

export default DashboardBreadcrumbs;
