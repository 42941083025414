import { ReceiverDogFeedback } from "../hooks/useDogFeedback";
import { axios } from "./authenticated-axios";

const getMyFeedbacks = async (): Promise<ReceiverDogFeedback[]> => {
  const response = await axios.get("/dogfeedback/list");
  const json = response.data;
  return json;
};

const getFilteredFeedbacks = async (
  params: any
): Promise<ReceiverDogFeedback[]> => {
  const response = await axios.get("/dogfeedback/filteredList", {
    params: {
      receiverId: params,
    },
  });
  const json = response.data;
  return json;
};

const getMyFeedbacksById = async (params: any): Promise<any> => {
  const response = await axios.get("/dogfeedback/get", params);
  const json = response.data;
  return json;
};
const updateFeedBackStatus = async (params: any): Promise<any> => {
  const response = await axios.put("/dogfeedback/UpdateFeedbackStatus", params);
  return response.data;
};

const createFeedbacks = async (params: any): Promise<any> => {
  const response = await axios.post("/dogfeedback/create", params);
  return response.data;
};

export {
  getMyFeedbacks,
  getFilteredFeedbacks,
  createFeedbacks,
  getMyFeedbacksById,
  updateFeedBackStatus,
};
