import React, { useEffect, useRef, useState } from "react";
import Button from "./Button";

export interface Props {
  url?: string;
  title?: string;
  defaultImage?: string;
  setFileSelected: (file: File | null) => void;
  setIsImageRemoved: (isImageRemoved: boolean) => void;
}
const ProfileImage: React.FC<Props> = function ({ url, title, defaultImage, setFileSelected, setIsImageRemoved }) {
  //console.log("ProfileImage",url);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [profilePicture, setProfilePicture] = useState(url || "");
 useEffect(() => {
    setProfilePicture(url || "");
  },[url]);
  const onFileChange = (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setProfilePicture(previewUrl);
      setFileSelected(file);
      setIsImageRemoved(false);
    }
  };

  const clearFile = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = ''; // Clears the file input value
    }
    setProfilePicture("")
    setFileSelected(null);
    setIsImageRemoved(true);
  };
  //console.log("profilePicture",profilePicture);
  return (
    <>
      <img src={profilePicture != "" ? profilePicture : defaultImage} alt="Annonsebilde" />
      {profilePicture == "" && (
        <Button
          label="Last opp bilde"
          onClick={() => {
            setFileSelected(null);
            hiddenFileInput.current && hiddenFileInput.current.click();
          }}
        />
      )}
      <input type="file" hidden ref={hiddenFileInput} onChange={onFileChange} />
      {profilePicture && (
        <Button label="Slett bilde" skin="noColor" onClick={clearFile} />
      )}
    </>
  );
};

export default ProfileImage;
