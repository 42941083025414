import DOMPurify from "dompurify";
import Collapse from "@mui/material/Collapse";

interface FaqSectionProps {
  items: any;
  css: any;
  visibleIndex: number | null;
  setVisibleIndex: any;
}

const FaqSection: React.FC<FaqSectionProps> = ({
  items,
  css,
  visibleIndex,
  setVisibleIndex,
}) => {
  return (
    <div>
      {Object.keys(items).map((categoryName, groupIndex) => (
        <div
          key={groupIndex}
          className={
            categoryName === "Konto" ? css.accountSection : css.aboutPond
          }
        >
          <div className={css.grid}>
            <h3>{categoryName}</h3>
            <div>
              {items[categoryName].map((item: any) => (
                <div key={item.id} className={css.questionContainer}>
                  <div
                    className={css.dropDown}
                    onClick={() =>
                      setVisibleIndex(visibleIndex === item.id ? null : item.id)
                    }
                  >
                    <h5 className={css.question}>
                      {item.properties.faqQuestion}
                    </h5>
                    <img
                      src={
                        visibleIndex === item.id
                          ? "/images/icons/close.svg"
                          : "/images/icons/open.svg"
                      }
                      alt="Åpne ikon"
                    />
                  </div>
                  <Collapse in={visibleIndex === item.id} timeout="auto">
                    <p
                      className={css.answer}
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          item.properties.faqAnswer.markup ?? ""
                        ),
                      }}
                    ></p>
                  </Collapse>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FaqSection;
