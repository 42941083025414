import React from "react";
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom";
import useAuth0 from "../hooks/useAuth0";

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const location = useLocation();
  const { pathname } = location;

  const { isAuthenticated,  isLoading, user, isExternalLogin } = useAuth0();

  if (pathname === "/min-pond" && user?.isRegisterStepsCompleted === false && isExternalLogin) {
    return <Redirect to="/registrer" />;
  }
  if (pathname === "/min-pond" && user?.isRegisterStepsCompleted === false) {
    return <Redirect to="/on-boarding" />;
  }
 
  if (isLoading) {
    return null; 
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
