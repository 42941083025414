import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import Button from "../../components/Button";
import css from "../../styles/Info.module.scss";
import useAuth0 from "../../hooks/useAuth0";

const BecomeOwnerInfo: React.FC = function () {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    window.analytics.page("Bli en POND’er");
  }, []);

  return (
    <div className={css.infoPage}>
      <h4>Bli en POND’er</h4>
      <p>
        Ved å registrere deg hos POND kan du kontakte gode og ansvarlige
        oppdrettere. Gjennom chatten kan dere bli kjent med hverandre, og hvis
        dere begge er enige om at du og hunden er en god match, kan dere til
        slutt avtale overlevering, fylle ut en av våre digitale kjøpekontrakter
        og gjøre et trygt kjøp gjennom POND.
      </p>
      <div>
        <Button
          label="Opprett bruker"
          className={css.button}
          onClick={loginWithRedirect}
        />
        <Button
          label="Les mer"
          skin="light"
          className={css.button}
          onClick={() => history.push("/slik-fungerer-det")}
        />
      </div>
    </div>
  );
};

export default BecomeOwnerInfo;
