import useAuth0 from "../../hooks/useAuth0";
import { Tabs, Tab, Stack, CircularProgress } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetMyDogsQuery } from "../../api/graphql.gen-old";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import MyDogsCard from "../../components/Dashboard/MyDogsCard";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/MyDogs.module.scss";
import {
  useDeleteDogParentMutationMutation,
  useFindMyDogsQueryQuery,
} from "../../api/graphql.gen";
import useToast from "../../components/Toast/hooks";

const MyDogs: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [tab, setTab] = useState("1");
  const { isAuthenticated, isLoading } = useAuth0();
  // const { data } = useGetMyDogsQuery();
  const { data, refetch } = useFindMyDogsQueryQuery();
  const history = useHistory();
  const dogs = data?.dogParentQuery.findMyDogs.filter((item) => item.isOwner);
  const otherDogs = data?.dogParentQuery.findMyDogs.filter(
    (item) => !item.isOwner
  );
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { showToast } = useToast();

  const { mutate: deleteDogParent } = useDeleteDogParentMutationMutation({
    onSuccess: () => {
      showToast("Dog Parent Deleted succcessfully.", "success");
      refetch();
    },
    onError: () => {
      showToast("Dog Parent Deletion Failed", "error");
    },
  });

  useEffect(() => {
    window.analytics.page("Avlshunder");
  }, []);

  const renderTabs = () => (
    <div>
      {tab === "1" ? (
        <div>
          {dogs?.length ? (
            <div className={css.dogsContainer}>
              {dogs.map((dog) => {
                const dogImage = dog.fileRecord?.url ? baseUrl +dog.fileRecord?.url+"?width=565&height=565" : "";
                return(
                <MyDogsCard
                  key={dog.id}
                  imgSrc={dogImage}
                  imgAlt="Bilde av hund"
                  title={dog.name}
                  nkkNumber={dog.nkkId ?? "N/A"}
                  breedName={dog.breed}
                  birthDate={format(new Date(dog.born), "dd.MM.yyyy")}
                  onClick={() => history.push(`avlshunder/rediger/${dog.id}`)}
                  onDelete={() => deleteDogParent({ id: dog.id })}
                />)
              }
              )}
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          ) : (
            <div className={css.noDogs}>
              <p>Du har ingen registrerte hunder</p>
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          {otherDogs?.length ? (
            <div className={css.dogsContainer}>
              {otherDogs.map((dog) => (
                <MyDogsCard
                  key={dog.id}
                  imgSrc={dog.fileRecord?.url ? baseUrl +dog.fileRecord?.url+"?width=565&height=565" : ""}
                  imgAlt="Bilde av hund"
                  title={dog.name}
                  nkkNumber={dog.nkkId ?? "N/A"}
                  breedName={dog.breed}
                  birthDate={format(new Date(dog.born), "dd.MM.yyyy")}
                  onClick={() => history.push(`avlshunder/rediger/${dog.id}`)}
                  onDelete={() => deleteDogParent({ id: dog.id })}
                />
              ))}
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          ) : (
            <div className={css.noDogs}>
              <p>Du har ingen registrerte hunder</p>
              <Button
                label="+ Legg til ny avlshund"
                skin="dark"
                onClick={() => history.push("avlshunder/ny")}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );

  return (
    <div className={css.MyDogs}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/paw-circle.svg"
          percentage={60}
          title="Avlshunder"
          info="Her kan du legge til dine egne hunder og hunder fra andre oppdrettere som du bruker i avl."
          customWidth={""}
        />
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <Tabs
            sx={{
              ".MuiTab-root": {
                width: "197px",
              },
            }}
            value={tab}
            onChange={(_event, value) => setTab(value)}
            variant="fullWidth"
          >
            <Tab value="1" label="Mine hunder" />
            <Tab value="2" label="Andres hunder" />
          </Tabs>
        )}
        {renderTabs()}
      </div>
    </div>
  );
};

export default MyDogs;
