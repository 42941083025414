import React from "react";
export const PartnersSection = ({ partners, css }: any) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return (
    <div className={css.partners}>
      {partners.items.map((partner: any, index: any) => {
        const { title, image, redirectLink } = partner.content.properties;

        return (
          <div key={index}>
            <a
              href={redirectLink[0].url}
              target={redirectLink[0].target}
              rel="noreferrer"
              onClick={() => window.analytics.track(`${title} redirect`)}
            >
              
              <picture>
                  <source
                    media="(max-width: 800px)"
                    srcSet={baseUrl + image[0].url? `${baseUrl + image[0].url }?width=172&height=75` : ""}
                  />
                  <img
                    src={baseUrl  + image[0].url? `${baseUrl + image[0].url}?width=288&height=126` : ""}
                    alt={title}
                  />
                </picture>                 
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default PartnersSection;
