import React, { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Button } from '@mui/material';
import { useHistory } from "react-router-dom"; // Import useHistory
import Step1 from "./components/Step1";
import Step2 from "./components/Step2";
import Step3 from "./components/Step3";
import useVerification from "./hooks";
import useAuth from "../../hooks/useAuth0";
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import VerificationStatus from "./components/Step4";

const steps = ["Åpenhet", "Kunnskap", "Miljo"];

const Verification: React.FC = () => {
  const history = useHistory();
  const { user } = useAuth();
  const { selected, setSelected, onSuccess, isLoading } = useVerification();

  useEffect(()=> {
    if(user.breeder && (user.verificationStatus == 0 || user.verificationStatus == 1)){
      history.push("/min-pond")
    }
  }, []);

  return (
    <>
        {selected === 3? <VerificationStatus />: <>
      <Stack
        width="100vw"
        justifyContent="center"
        alignItems="center"
        bgcolor="#E2F6D1"
        height={195}
        marginTop="26px"
      >
        <Stack width="78%" position="relative">
          {selected >= 1 && <Button sx={{position: "absolute", left: {xs:"-20px", sm:"10rem"}, top: "-14px"}} onClick={()=> setSelected(selected-1)} startIcon={<KeyboardArrowLeftIcon />}>Tilbake</Button>}
        </Stack>
        <Stack
          gap={"28px"}
          sx={{
            width: "394px",
            minHeight: "114px",
          }}
        >
          <h3
            style={{
              textAlign: "center",
              fontWeight: 600,
              color: "#065C53",
            }}
          >
            Åpenhet
          </h3>

          <Stepper activeStep={selected} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel
                  sx={{
                    ".MuiStepLabel-label": {
                      marginTop: "0px !important",
                      fontWeight: 500,
                      "&.Mui-active": {
                        fontWeight: 600,
                      },
                    },
                    ".MuiStepLabel-iconContainer": { height: "28px" },
                    cursor: "pointer"
                  }}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </Stack>
      <Step1 show={selected===0} onSuccess={onSuccess} />
      <Step2 show={selected===1} onSuccess={onSuccess} />
      <Step3 show={selected===2} isLoading={isLoading} onSuccess={onSuccess} />
    </>}
    </>
  );
};

export default Verification;
