import React, { useEffect, useState } from "react";
import {
  Stack,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { useForm } from "react-hook-form";
import useAuth0 from "../../hooks/useAuth0";
import "./index.scss";
import { useHistory } from "react-router-dom";
import useToast from "../../components/Toast/hooks";
import { axios } from "../../api/authenticated-axios";

const ForgotPassword: React.FC = (): JSX.Element => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { showToast } = useToast();
  const { user } = useAuth0();

  useEffect(() => {
    if (user.id) {
      history.push("/min-pond");
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const isSuccess = await axios.post("/memberlist/ForgetPasswordEmail", {
        email: data.email,
      });
      if (isSuccess) {
        reset();
        showToast(isSuccess.data.toString(), "success");
      } else {
        showToast("Failed to change the Email. Please try again.", "error");
      }
    } catch (error: any) {
      showToast(error.response.data, "error");
    }

    setIsLoading(false);
  };

  return (
    <Stack className="custom-forgotpass">
      <form className="card" onSubmit={handleSubmit(onSubmit)}>
        <img src="/images/Pond_green.svg" alt="POND logo" />
        <Typography className="heading" fontSize="1.5rem">
          Glemt passordet?
        </Typography>
        <Typography className="sub-heading" fontSize="0.875rem">
          Skriv inn e-postadressen din, så sender vi deg instruksjoner for å
          tilbakestille passordet.
        </Typography>
        <TextField
          label="E-postadresse*"
          error={!!errors.email}
          helperText={errors.email?.message}
          variant="outlined"
          fullWidth
          {...register("email", {
            required: "E-postadresse er påkrevd",
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: "Vennligst oppgi en gyldig e-postadresse",
            },
          })}
        />
        <Button
          disabled={isLoading}
          type="submit"
          variant="contained"
          size="large"
        >
          {isLoading ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            "Fortsett"
          )}
        </Button>
        <Typography
          className="link"
          sx={{ fontSize: "14px", cursor: "pointer" }}
          onClick={() => history.push("/login")}
        >
          Tilbake til POND
        </Typography>
      </form>
    </Stack>
  );
};

export default ForgotPassword;
