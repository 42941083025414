import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import css from "../../index.module.scss";
import { Button, Stack } from "@mui/material";
import { axios } from "../../../../api/authenticated-axios";
import { useHistory } from "react-router-dom";

interface IProps {
  show: boolean;
  onSuccess: (step: number, data: any) => void;
}

const stepCard = [
  {
    key: "HasKennelProfileCompleted",
    icon: "1",
    title: "Fullfør kennelprofil",
    desc: "Legg inn informasjon for å øke tilliten blant potensielle valpekjøpere.",
    link: "/min-pond/rediger-profil",
  },
  {
    key: "HasDogParentProfileCompleted",
    icon: "2",
    title: "Legg til 2 avlshunder",
    desc: "Last opp minst 2 avlshunder til din profil.",
    link: "/min-pond/avlshunder/ny",
  },
  {
    key: "HasPuppyProfileCompleted",
    icon: "3",
    title: "Legg til valpekull",
    desc: "Hvis du ikke har noe kull enda, kan du fullføre verifiseringen når du har et valpekull tilgjengelig.",
    link: "/nytt-valpekull",
  },
];

const Step1: React.FC<IProps> = ({ show, onSuccess }) => {
  const history = useHistory();
  const { handleSubmit, control, setValue, watch } = useForm<any>({
    defaultValues: {
      HasKennelProfileCompleted: false,
      HasDogParentProfileCompleted: false,
      HasPuppyProfileCompleted: false,
    },
  });
  const hasKennel = watch("HasKennelProfileCompleted");
  const hasDogParent = watch("HasDogParentProfileCompleted");
  const hasDogAd = watch("HasPuppyProfileCompleted");

  const fetchInitData = async () => {
    try {
      const res = (await axios.get("/breederverification/get")) || {};
      // Object.keys(res.data).map((c) => setValue(c, true));
      const data: any = res.data;

      setValue("HasKennelProfileCompleted", data.hasKennel);
      setValue("HasDogParentProfileCompleted", data.hasDogParent);
      setValue("HasPuppyProfileCompleted", data.hasDogAd);
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    fetchInitData();
  }, []);

  const onSubmit = (data: any) => {
    onSuccess(1, data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: show ? "block" : "none" }}
    >
      <Stack alignItems="center">
        <div className={css.VerificationApenhet}>
          {stepCard.map((step, index) => (
            <div
              key={index}
              className={css.stepcard}
              onClick={() => history.push(step.link)}
            >
              <div className={css.iconText}>
                <Controller
                  render={({ field: { value } }) => (
                    <div
                      className={css.icon}
                      style={{
                        backgroundColor: value ? "#065C53" : "#F6FAED",
                        color: value ? "#FFFFFF" : "#065C53",
                      }}
                    >
                      {step.icon}
                    </div>
                  )}
                  name={step.key}
                  control={control}
                  rules={{ required: true }}
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "6px",
                  }}
                >
                  <div className={css.title}>{step.title}</div>
                  <div className={css.desc}>{step.desc}</div>
                </div>
              </div>
            </div>
          ))}
          <Button
            disabled={!hasKennel || !hasDogParent || !hasDogAd}
            variant="contained"
            sx={{
              marginTop: "40px",
              marginBottom: "60px",
              width: "100%",
              maxWidth: "399px",
              padding: "0px 20px",
              height: "56px",
              borderRadius: "56px",
            }}
            type="submit"
          >
            Gå videre
          </Button>
        </div>
      </Stack>
    </form>
  );
};

export default Step1;
