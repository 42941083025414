import React from "react";
import { Avatar, Box } from "@mui/material";
import css from "../../styles/VerifiedBadge.module.scss";
interface IProps {
  mobileWidth: string;
  mobileHeight: string;
  desktopWidth: string;
  desktopHeight: string;
}

const VerifiedBadge: React.FC<IProps> = ({
  mobileWidth,
  mobileHeight,
  desktopWidth,
  desktopHeight,
}: IProps) => {
  const useSingleAvatar = true;

  return (
    <>
      {useSingleAvatar ? (
        <Box
          component="img"
          src="/images/bagde.png"
          className={css.badges}
          alt=""
          sx={{
            width: { xs: mobileWidth, sm: desktopWidth },
            height: { xs: mobileHeight, sm: desktopHeight },
          }}
          // style={{ width: width, height: height }}
        />
      ) : (
        <Box
          sx={{
            display: "flex",
            transform: "translateX(-50%)",
            padding: "0.3rem",
          }}
        >
          <Avatar
            src="/images/badge2.png"
            sx={{
              width: 62,
              height: 68,
              backgroundColor: "transparent",
              top: { xs: "5px", sm: "20px" },
            }}
            alt=""
          />
          <Avatar
            src="/images/bagde.png"
            sx={{
              width: 62,
              height: 68,
              backgroundColor: "transparent",
              top: { xs: "5px", sm: "20px" },
            }}
            alt=""
          />
          <Avatar
            src="/images/badge3.png"
            sx={{
              width: 62,
              height: 68,
              backgroundColor: "transparent",
              top: { xs: "5px", sm: "20px" },
            }}
            alt=""
          />
        </Box>
      )}
    </>
  );
};

export default VerifiedBadge;
