import React from "react";
import css from "../../styles/ProfileModalContent.module.scss";

interface CardProps {
  imgSrc: string;
  title: string;
}

const IncludedCard: React.FC<CardProps> = ({ imgSrc, title }: CardProps) => (
  <div className={css.includedCard}>
    <img
      src={imgSrc}
      alt="Ikon"
      className={css.includedImage}
      loading="eager"
    />
    <div className={css.includedTitle}>{title}</div>
  </div>
);

export default IncludedCard;
