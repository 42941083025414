import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { axios } from "../../api/authenticated-axios";
import { validatePassword } from "../../utils";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import PasswordField from "./components/PasswordField";
import useToast from "../../components/Toast/hooks";
import EmailSuccess from "./components/EmailSuccess";
import "./index.scss";
import useAuth from "../../hooks/useAuth0";

const Register: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { showToast } = useToast();
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues 
  } = useForm();
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const password = watch("Password");
  const email = watch("Email");

  useEffect(() => {
    if (user?.memberId) {
      history.push("/min-pond");
    }
  }, [user]);

  const onSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      await axios.post("memberlist/register/", {
        ...data,
        IsBreeder: data.IsBreeder === "true",
      });
      showToast("Verification email sent successfully.", "success");
      setEmailSent(true);
      setIsLoading(false);
    } catch (e: any) {
      showToast(e.response.data, "error");
      setIsLoading(false);
    }
  };

  return (
    <Stack className="custom-register">
      {!emailSent ? (
        <form className="card" onSubmit={handleSubmit(onSubmit)}>
          <img src="/images/Pond_green.svg" alt="POND logo" />
          <Typography className="heading" fontSize="1.5rem">
            Velkommen
          </Typography>
          <Typography className="sub-heading" fontSize="0.875rem">
            Registrer deg for å fortsette til POND.
          </Typography>

          {/* Select field with validation */}
          <Controller
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={!!fieldState.error}>
                <InputLabel id="du-select-label">
                  Hva beskriver deg best?*
                </InputLabel>
                <Select
                  {...field}
                  labelId="du-select-label"
                  label="Hva beskriver deg best?*"
                >
                  <MenuItem value="true">Jeg er oppdretter</MenuItem>
                  <MenuItem value="false">Jeg ønsker meg hund</MenuItem>
                </Select>
                {fieldState.error && (
                  <FormHelperText>Dette feltet er påkrevd</FormHelperText>
                )}
              </FormControl>
            )}
            name="IsBreeder"
            control={control}
            rules={{ required: "Vennligst velg et alternativ" }}
          />

          {/* Email field with validation */}
          <TextField
            label="E-postadresse*"
            variant="outlined"
            fullWidth
            error={!!errors.Email}
            helperText={errors.Email ? errors.Email.message : ""}
            {...register("Email", {
              required: "E-postadresse er påkrevd",
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Ugyldig e-postadresse",
              },
            })}
          />

          {/* Password field with validation */}
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="Password"
            control={control}
            rules={{
              required: "Passord er påkrevd",
              validate: validatePassword,
            }}
          />

          {/* Confirm password with validation */}
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Gjenta passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="ConfirmPassword"
            control={control}
            rules={{
              required: "Vennligst bekreft passordet",
              validate: (value) =>
                value === password || "Passordene stemmer ikke overens",
            }}
          />

          <Button
            disabled={isLoading}
            variant="contained"
            size="large"
            type="submit"
          >
            {isLoading ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              "Fortsett"
            )}
          </Button>
          {/* Link to login */}
          <Typography sx={{ fontSize: "14px" }}>
            Har du allerede en konto?
            <span
              className="link"
              style={{ fontSize: "0.875rem", marginLeft: "6px" }}
              onClick={() => history.push("/login")}
            >
              Logg inn
            </span>
          </Typography>

          {/* <Divider sx={{ alignItems: "flex-start", fontSize: 12 }}>
            ELLER
          </Divider> */}

          {/* Facebook button */}
          {/* <Button
            variant="outlined"
            size="large"
            startIcon={<FacebookRoundedIcon />}
            className="fb"
          >
            Fortsett med Facebook
          </Button> */}
        </form>
      ) : (
        <Stack
          bgcolor="#E2F6D1"
          height="100vh"
          width="100vw"
          justifyContent="flex-start"
          alignItems="center"
        >
          <EmailSuccess email={email}/>
        </Stack>
      )}
    </Stack>
  );
};

export default Register;
