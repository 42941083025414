export const clubNames = [
  "Aust-Agder Dachshundklubb",
  "Aust-Agder Elghundklubb",
  "Aust-Agder Harehundklubb",
  "Australian Shepherd Klubb Norge",
  "Australske Gjeterhunder Norge",
  "Beagle Ringen Norge",
  "Blodhundklubben",
  "Buskerud Elghundklubb",
  "Buskerud Harehundklubb",
  "Dalane hundeklubb",
  "Dunkerringen",
  "Finnmark Elghundklubb",
  "Finskstøverforeningen Norge",
  "Follo - Østfold Dachshundklubb",
  "Follo Og Østfold Elghundklubb",
  "Glåmdal Og Romerike Dachshundklubb",
  "Gudbrandsdal Elghundklubb",
  "Gudbrandsdal Harehundklubb",
  "Hadeland Elghundklubb",
  "Hadeland Harehundklubb",
  "Haldenstøverklubben",
  "Hallingdal Og Valdres Elghundklubb",
  "Hamiltonringen",
  "Hedmark Dachshundklubb",
  "Hedmark Elghundklubb",
  "Hedmark Harehundklubb",
  "Helgeland Elghundklubb",
  "Helgeland- Og Namdal Harehundklubb",
  "Hollandsk Gjeterhundklubb, Norge",
  "Hordaland Elghundklubb",
  "Hvit Gjeterhund Klubb",
  "Hygenringen",
  "Irsk Ulvehundklubb Norge",
  "Japansk Urhundklubb I Norge",
  "Jotunfjell-Fjordane Elghundklubb",
  "Keeshond Klubben",
  "Klubben For Bolognese Og Coton De Tulear",
  "Klubben For Gårds- Og Fjellhunder",
  "Klubben For Ungarske Vakt- Og Gjeterhundraser",
  "Luzernerringen",
  "Nord Hålogaland Elghundklubb",
  "Nord Norge Harehundklubb",
  "Nord Vest Dachshundklubb",
  "Nordland Dachshundklubb",
  "Nord-Trøndelag Dachshundklubb",
  "Nord-Trøndelag Elghundklubb",
  "Nord-Trøndelag Harehundklubb",
  "Norsk Affenpinscherklubb",
  "Norsk Akita Klubb",
  "Norsk Alaskan Malamute Klubb",
  "Norsk Alpinsk Dachsbrackeklubb",
  "Norsk American Akita Klubb",
  "Norsk Basenjiklubb",
  "Norsk Bassetklubb",
  "Norsk Bearded Collie Klubb",
  "Norsk Belgisk Fårehund Klubb",
  "Norsk Berner Sennenhundklubb",
  "Norsk Bichon Frise Klubb",
  "Norsk Bichon Havanais Klubb",
  "Norsk Boston Terrier Klubb",
  "Norsk Boxerklubb",
  "Norsk Briard Klubb",
  "Norsk Buhundklubb",
  "Norsk Bulldog Klubb",
  "Norsk Bullmastiff-Klubb",
  "Norsk Cavalierklubb",
  "Norsk Chihuahuaklubb",
  "Norsk Chinese Crested Klubb",
  "Norsk Chodsky Pes",
  "Norsk Chow Chow Klubb",
  "Norsk Collie Klubb",
  "Norsk Dalmatiner Klubb",
  "Norsk Dobermann Klub",
  "Norsk Dreverring",
  "Norsk Engelsksetterklubb",
  "Norsk Eurasierklubb",
  "Norsk Gordonsetter Klub",
  "Norsk Grand Danois Klubb",
  "Norsk Greyhound Klubb",
  "Norsk Harehundklub",
  "Norsk Hovawart Klubb",
  "Norsk Irsksetterklubb",
  "Norsk Islandshund Klubb",
  "Norsk Japansk Spisshund Klubb",
  "Norsk Kooikerklubb",
  "Norsk Lagotto Klubb",
  "Norsk Lancashire Heeler Klubb",
  "Norsk Lapphund Klubb",
  "Norsk Leonberger Klubb",
  "Norsk Lhasa Apso Klubb",
  "Norsk Lundehund Klubb",
  "Norsk Malteser Klubb",
  "Norsk Miniature American Shepherd Klubb",
  "Norsk Miniatyrhund Klubb",
  "Norsk Mops Klubb",
  "Norsk Myndeklubb",
  "Norsk Münsterländer Klubb",
  "Norsk Newfoundlandshundklubb",
  "Norsk Old English Sheepdogklubb",
  "Norsk Pinscherklubb",
  "Norsk Pointerklub",
  "Norsk Polarhundklubb",
  "Norsk Pomeranian Klubb",
  "Norsk Puddelklubb",
  "Norsk Pyrenéerhundklubb",
  "Norsk Retrieverklubb",
  "Norsk Rhodesian Ridgeback Klubb",
  "Norsk Rottweilerklubb",
  "Norsk Sankt Bernhard Klubb",
  "Norsk Sau Og Geit",
  "Norsk Schapendoesklubb",
  "Norsk Schipperke Klubb",
  "Norsk Schnauzer Bouvier Klubb",
  "Norsk Schweisshund Klubb",
  "Norsk Schäferhund Klub",
  "Norsk Shetland Sheepdog Klubb",
  "Norsk Shih Tzu Klubb",
  "Norsk Siberian Husky Klubb",
  "Norsk Spaniel Klub",
  "Norsk Spaniel Klub",
  "Norsk Spesialklubb For Finsk Spets Norrbottenspets",
  "Norsk Stabijhoun Klubb",
  "Norsk Terrier Klub",
  "Norsk Tibetansk Terrier Klubb",
  "Norsk Vorstehhundklubb",
  "Norsk Wachtelhund Klubb",
  "Norsk Weimaraner Klubb",
  "Norsk Welsh Corgi Klubb",
  "Norsk Whippetklubb",
  "Norsk Yorkshire Terrier Klubb",
  "Norske Dachshundklubbers Forbund",
  "Norske Elghundklubbers Forbund",
  "Norske Harehundklubbers Forbund",
  "Oppland Dachshundklubb",
  "Oslo Og Omegn Dachshundklubb",
  "Oslo-Områdets Elghundklubb",
  "Podencoklubben",
  "Raseklubb For Dansk/Svensk Gårdshund",
  "Raseklubben For Portugisisk Vannhund",
  "Rogaland Dachshundklubb",
  "Romerike Harehundklubb",
  "Salten Elghundklubb",
  "Schweizerringen",
  "Spansk Vannhundklubb Norge",
  "Sørlandet Harehundklubb",
  "Sør-Trøndelag Elghundklubb",
  "Telemark Dachshundklubb",
  "Telemark Elghundklubb",
  "Telemark Harehundklubb",
  "Troms Elghundklubb",
  "Troms Og Finnmark Dachshundklubb",
  "Trøndelag Dachshundklubb",
  "Trøndelag Harehundklubb",
  "Tysk Jaktterrier Klubb",
  "Ungarsk Vizsla I Norge",
  "Vest-Agder Dachshundklubb",
  "Vest-Agder Elghundklubb",
  "Vestfold - Buskerud Dachshundklubb",
  "Vestfold Elghundklubb",
  "Vestfold Harehundklubb",
  "Vestland Dachshundklubb",
  "Vestlandets Harehundklubb",
  "Vestoppland Elghundklubb",
  "Vestoppland Harehundklubb",
  "Västgötaspetsen",
  "Østerdalen Elghundklubb",
  "Østerdalen Harehundklubb",
  "Østfold Harehundklubb",
];
