import useAuth0 from "../hooks/useAuth0";
import { Avatar, Collapse, SwipeableDrawer } from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import {
  useGetUserQuery,
  DogParent,
  useGetUserByEmailQuery,
} from "../api/graphql.gen-old";
import Button from "../components/Button";
// import DogModalContent from "../components/Profile/DogModalContent";
import ProfileOurDogs from "../components/Profile/ProfileOurDogs";
import useIsVisible from "../hooks/useIsVisible";
import css from "../styles/Profile.module.scss";
import CreateUserDialog from "../components/CreateUserDialog";

import useAuth from "../hooks/useAuth0";
import {
  BreederMemberDto,
  DogParentDto,
  MemberDto,
  useMemberQueryQuery,
} from "../api/graphql.gen";
import {
  ClubItemContentResponseModel,
  IApiContentResponsePagedViewModel,
} from "../api/umbracoApi";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../hooks/useUmbracoQuery";
import { axios } from "../api/authenticated-axios";
import DogModalContent from "../components/Profile/DogModalContent";
import { useFilteredFeedbackList } from "../hooks/useDogFeedback";
import Reference from "./Dashboard/Reference";
import DogModal from "../components/Profile/DogDetailModal";
import { Helmet } from "react-helmet-async";

interface RouteParams {
  userId: string;
}

const EmptyMessage = ({ label }: { label: string }) => (
  <div className={css.emptyMessage}>
    <div>{label}</div>
  </div>
);

const ProfileMobile: React.FC = () => {
  const history = useHistory();
  const { userId } = useParams<RouteParams>();
  const { isAuthenticated, user: authUser } = useAuth();
  const { data: feebackList } = useFilteredFeedbackList(parseInt(userId));

  // const { user: authUser } = useAuth0();
  // const { data: userData } = useGetUserByEmailQuery({
  //   email: authUser?.email ?? "",
  // });
  // const currentUser = userData?.getUserByEmail.id ?? -1;
  // const id = userId ? parseInt(userId) : currentUser ? currentUser : -1;
  // const { data: user } = useGetUserQuery({ id });

  const { data: userData } = useMemberQueryQuery({ userId: parseInt(userId) });
  const user = userData?.memberQuery?.user as BreederMemberDto;
  // const [isBreeder, setIsBreeder] = useState(false);

  const { data: clubItem } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:clubItem"],
    });
  const isBreeder = user?.breeder ?? false;
  const agentId = user?.kennel?.agriaAgentId;
  const { data: AgriaAgent } = useUmbracoContentItemQuery<any>(agentId ?? "");
  const myAgent = AgriaAgent?.properties;


  const availableAd = user?.dogAds?.filter(
    (item) => item.adType === "AVAILABLE"
  );

  const myAds = user?.dogAds;

  const kennelInfo = useRef<HTMLDivElement>(null);

  const kennelIsVisible = useIsVisible(kennelInfo, "-140px");

  const [aboutVisible, setAboutVisible] = useState(true);
  const [adVisible, setAdVisible] = useState(false);
  const [agriaVisible, setAgriaVisible] = useState(false);
  const [referenceVisible, setReferenceVisible] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    "/images/default-avatar.png"
  );
  const [plannedVisible, setPlannedVisible] = useState(false);
  const [dogsVisible, setDogsVisible] = useState(false);
  const [puppyModal, setPuppyModal] = useState(false);
  const [dogModal, setDogModal] = useState(false);
  const [selectedDog, setSelectedDog] = useState<DogParentDto>();
  const [showCreateUser, setShowCreateUser] = useState(false);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // useEffect(() => {
  //   setProfilePicture(user?.profileImage ?? "/images/default-avatar.png");
  // }, [user]);

  useEffect(() => {
    if (user && !user.memberId) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (selectedDog !== undefined) {
      setDogModal(true);
    }
  }, [selectedDog, setSelectedDog]);
  const bannerImage =
    user?.kennel &&
    (user?.kennel.bannerImage == "/images/placeholder-dog.png" ||
      user?.kennel.bannerImage == "" ||
      !user.kennel.bannerImage)
      ? ""
      : JSON.parse(user?.kennel?.bannerImage ?? "{}");

  function urlFor(source: any) {
    return baseUrl + source;
  }

  function convertToEnglishUrl(text: string) {
    return text
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/å/g, "aa")
      .replace(/ø/g, "oe")
      .replace(/æ/g, "ae")
      .replace(/ /g, "-");
  }
  const currentUrl = window.location.href;
  const domain = new URL(currentUrl).hostname.replace('www.', '');
  return (
    <div className={css.BreedProfile}>
      <Helmet>
          <meta charSet="utf-8" />
          <title>{user?.kennel?.name}</title>
        <meta name="description" content={user?.kennel?.name} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={"Pond.no"}
          />
          <meta property="og:url" content={currentUrl}  />
          <meta property="og:title" content={user?.kennel?.name} />
          <meta property="og:description" content={user?.kennel?.name}  />
          {user?.profileImage && (
          <meta name="og:image:secure" content={urlFor(user?.profileImage)}  />
          )}
          {user?.profileImage && (
          <meta name="og:image" content={urlFor(user?.profileImage)}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={domain} />
          <meta property="twitter:url" content={currentUrl} />
          <meta name="twitter:title" content={user?.kennel?.name} />
          <meta name="twitter:description" content={user?.kennel?.name} />
          {user?.profileImage && (
          <meta name="twitter:image:secure" content={urlFor(user?.profileImage)}></meta>
          )}
          {user?.profileImage && (
          <meta name="twitter:image" content={urlFor(user?.profileImage)}></meta>
          )}
        </Helmet>
      <div className={css.bannerContainer}>
        {user?.kennel?.bannerImage ? (
          <div className={css.middleBanner}>
            {bannerImage && (
              <img
                src={
                  bannerImage
                    ? urlFor(bannerImage.url + "?width=768&height=440")
                    : ""
                }
                alt={user?.kennel?.name}
              />
            )}
          </div>
        ) : (
          <div className={css.noBanner} />
        )}
      </div>
      <div className={css.kennelContainer} ref={kennelInfo}>
        <div className={css.kennelHorizontalContainer}>
          <Avatar
            src={
              user?.profileImage
                ? urlFor(user?.profileImage + "?width=190&height=190")
                : "/images/default-avatar.png"
            }
            alt="Profilbilde"
            className={css.profilePic}
          />
          <div className={css.kennelInfo}>
            <div className={css.kennelName}>{user?.kennel?.name}</div>
            <div className={css.location}>
              {user?.city ? `${user?.city}, Norge` : "Norge"}
            </div>
            <div className={css.breeds}>
              {user &&
                user?.kennel &&
                user?.kennel?.breedersList &&
                user?.kennel?.breedersList.map((breed) => (
                  <div
                    key={breed}
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      history.push(`/hunderaser/${convertToEnglishUrl(breed)}`)
                    }
                  >
                    {breed}
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className={css.contact}>
          {isBreeder && user.email !== authUser.email && (
            <Button
              label="Kontakt meg"
              skin="light"
              onClick={() =>
                isAuthenticated
                  ? history.push(`/kontakt/${userId}`)
                  : setShowCreateUser(true)
              }
            />
          )}
          {isBreeder && user.email !== authUser.email && !user?.breeder && (
            <Button
              label="Send søknad"
              onClick={() =>
                isAuthenticated
                  ? history.push(`/send-soknad/${userId}`)
                  : setShowCreateUser(true)
              }
            />
          )}
        </div>
      </div>
      {!kennelIsVisible && (
        <div className={css.smallKennelContainer}>
          <div className={css.infoContainer}>
            <Avatar
              src={
                user?.profileImage
                  ? urlFor(user?.profileImage + "?width=190&height=190")
                  : "/images/default-avatar.png"
              }
              alt="Profilbilde"
              className={css.profilePic}
            />
            {isBreeder && user.email !== authUser.email && (
              <div
                className={css.chatContainer}
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/kontakt/${userId}`)
                    : setShowCreateUser(true)
                }
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 25 25"
                  fill="none"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.87706 23.2054C5.25651 22.9463 4.85242 22.3398 4.85242 21.6673V19.2835C3.08696 19.2439 1.66797 17.8004 1.66797 16.0254V6.66738C1.66797 4.82643 3.16035 3.33405 5.00129 3.33405L20.0013 3.33398C21.8422 3.33397 23.3346 4.82637 23.3346 6.66732V15.9509C23.3346 17.7919 21.8423 19.2843 20.0013 19.2843L11.2984 19.2843L7.69112 22.8523C7.21303 23.3251 6.49761 23.4644 5.87706 23.2054ZM6.51909 19.2099C6.51909 18.3305 5.80622 17.6177 4.92686 17.6177C4.0475 17.6177 3.33464 16.9048 3.33464 16.0254V6.66738C3.33464 5.74691 4.08082 5.00072 5.0013 5.00071L20.0013 5.00065C20.9218 5.00064 21.668 5.74684 21.668 6.66732V15.9509C21.668 16.8714 20.9218 17.6176 20.0013 17.6176L11.2984 17.6177C10.8595 17.6177 10.4384 17.7908 10.1264 18.0994L6.51909 21.6673V19.2099Z"
                    fill="#ABC6C2"
                  />
                </svg>
              </div>
            )}
            {isBreeder && user.email !== authUser.email && !user?.breeder && (
              <Button
                label="Send søknad"
                onClick={() =>
                  isAuthenticated
                    ? history.push(`/send-soknad/${userId}`)
                    : setShowCreateUser(true)
                }
              />
            )}
          </div>
        </div>
      )}
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAboutVisible(!aboutVisible)}
        >
          <div>Om kennel</div>
          <img
            src={
              aboutVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={aboutVisible}
          timeout="auto"
          className={aboutVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            <div className={css.textContainer}>
              <p>{`${user?.firstName} ${user?.lastName}`}</p>
              <p>{`${user?.kennel?.motivation}`}</p>
            </div>
            {(user?.kennel?.clubs?.length || user?.isAgriaMember) && (
              <div className={css.textContainer}>
                <h6>Medlem av</h6>
                {user?.kennel?.clubsList?.map((club) => {
                  const hasClub: ClubItemContentResponseModel | undefined =
                    clubItem?.items.find(
                      (item: any) =>
                        item?.properties?.clubName.toLowerCase() ===
                        club.toLowerCase()
                    );

                  return (
                    <div
                      className={css.clubContainer}
                      key={club}
                      onClick={() => {
                        hasClub?.properties.clubLink?.[0].url
                          ? window.open(hasClub?.properties.clubLink?.[0].url)
                          : undefined;
                      }}
                    >
                      <Avatar
                        src={
                          hasClub?.properties?.clubImage?.[0]?.url // Use the found club's image if available
                            ? baseUrl + hasClub.properties.clubImage[0].url
                            : "/images/icons/club.svg" // Fallback if no image exists
                        }
                        className={css.avatar}
                        alt="klubb ikon"
                      />
                      <div>{club}</div>
                    </div>
                  );
                })}
                {user.isAgriaMember && (
                  <div className={css.clubContainer}>
                    <Avatar
                      src="/images/agria-logo.png"
                      className={css.avatar}
                      alt="klubb ikon"
                    />
                    <div>Agria breeders club</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAdVisible(!adVisible)}
        >
          <div>Tilgjengelige valper</div>
          <img
            src={
              adVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={adVisible}
          timeout="auto"
          className={adVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            {availableAd?.length ? (
              <div className={css.adGrid}>
                {availableAd?.map((ad) => {
                  const image = ad.fileRecord
                    ? `${process.env.REACT_APP_API_BASE_URL}${ad.fileRecord.url}?width=410&height=410`
                    : "/images/placeholder-dog.png";

                  return (
                    <div
                      key={ad.id}
                      className={css.adCard}
                      onClick={() => history.push(`/annonse/${ad.id}`)}
                    >
                      <img src={image} alt="Annonsebilde" />

                      <div className={css.title}>{ad.title}</div>
                      <div className={css.breed}>{ad.breed}</div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <EmptyMessage label="Denne oppdretteren har ingen tilgjengelige kull" />
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setPlannedVisible(!plannedVisible)}
        >
          <div>Planlagte og kommende kull</div>
          <img
            src={
              plannedVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={plannedVisible}
          timeout="auto"
          className={plannedVisible ? css.collapse : ""}
        >
          <div className={css.plannedContainer}>
            {myAds?.filter(
              (item) => item.adType !== "AVAILABLE" && item.adType !== "DELETED"
            ).length ? (
              <div className={css.upcomingContainer}>
                {myAds
                  ?.filter((item) => item.adType === "PLANNED")
                  .map((ad) => (
                    <div key={ad.id}>
                      <div className={css.horizontalContainer}>
                        <div className={css.title}>Planlagte valper</div>
                        <div className={css.expected}>Forventet</div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.subTitle}>{ad?.breed}</div>
                        <div className={css.subTitle}>
                          {format(new Date(ad?.birthDate), "MMM yy")}
                        </div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.parents}>Mor</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "female"
                          )[0]?.nkkId ?? "N/A"}
                        </div>
                      </div>
                      <div
                        className={`${css.horizontalContainer} ${css.divider}`}
                      >
                        <div className={css.parents}>Far</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "male"
                          )[0]?.nkkId ?? "N/A"}
                        </div>
                      </div>
                    </div>
                  ))}
                {myAds
                  ?.filter((item) => item.adType === "COMING")
                  .map((ad) => (
                    <div key={ad.id}>
                      <div className={css.horizontalContainer}>
                        <div className={css.title}>Kommende valper</div>
                        <div className={css.expected}>Forventet</div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.subTitle}>{ad?.breed}</div>
                        <div className={css.subTitle}>
                          {format(new Date(ad?.birthDate), "MMM yy")}
                        </div>
                      </div>
                      <div className={css.horizontalContainer}>
                        <div className={css.parents}>Mor</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "female"
                          )[0]?.nkkId ?? "N/A"}
                        </div>
                      </div>
                      <div
                        className={`${css.horizontalContainer} ${css.divider}`}
                      >
                        <div className={css.parents}>Far</div>
                        <div className={css.parents}>
                          {ad?.parents?.filter(
                            (parent) => parent.gender === "male"
                          )[0]?.nkkId ?? "N/A"}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <EmptyMessage label="Denne oppdretteren har ingen planlagte kull" />
            )}
          </div>
        </Collapse>
      </div>
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setDogsVisible(!dogsVisible)}
        >
          <div>Mine hunder</div>
          <img
            src={
              dogsVisible ? "/images/icons/close.svg" : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={dogsVisible}
          timeout="auto"
          className={dogsVisible ? css.collapse : ""}
        >
          <div className={css.contentContainer}>
            {user?.dogParents?.length ? (
              <ProfileOurDogs
                dogs={user.dogParents}
                onClick={(dog) => {
                  setSelectedDog(dog);
                  setDogModal(true);
                }}
              />
            ) : (
              <div>
                <h6>Mine hunder</h6>
                <EmptyMessage label="Denne oppdretteren har ikke lastet opp noen hunder" />
              </div>
            )}
          </div>
        </Collapse>
      </div>
      {/* <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setReferenceVisible(!referenceVisible)}
        >
          <div>Referanser</div>
          <img
            src={
              referenceVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={referenceVisible}
          timeout="auto"
          className={referenceVisible ? css.collapse : ""}
        >
          {feebackList && feebackList.length > 0 ? (
            <div className={css.contentContainer}>
              <span>
                <Reference
                  feebackList={feebackList}
                  userId={userId}
                  isMobile={true}
                />
              </span>
            </div>
          ) : (
            <p>Denne oppdretteren har ikke Referanser</p>
          )}
        </Collapse>
      </div> */}
      <div className={css.collapseContainer}>
        <div
          className={css.mobileSection}
          onClick={() => setAgriaVisible(!agriaVisible)}
        >
          <div>Mine agria forsikringsagent</div>
          <img
            src={
              agriaVisible
                ? "/images/icons/close.svg"
                : "/images/icons/open.svg"
            }
            alt="Åpne ikon"
          />
        </div>
        <Collapse
          in={agriaVisible}
          timeout="auto"
          className={agriaVisible ? css.collapse : ""}
        >
          {agentId && myAgent ? (
            <div className={css.contentContainer}>
              <div className={css.agentCard}>
                <Avatar
                  className={css.avatar}
                  src={myAgent.image ? urlFor(myAgent.image[0].url) : ""}
                  alt="Bilde av agent"
                />
                <div>
                  <div className={css.name}>{myAgent.agentName}</div>
                  <div className={css.location}>
                    {myAgent.location + ", Norge"}
                  </div>
                  <div className={css.button}>
                    <Button
                      label="Besøk profil"
                      onClick={() => {
                        window.analytics.track("Besøk agria agents profil");
                        window.open(myAgent.webLink ? myAgent.webLink[0].url : "");
                      }}
                    />
                  </div>
                </div>
              </div>
              <img
                src="/images/agria.png"
                alt="Agra logo"
                className={css.agriaLogo}
              />
              <ul>
                <li>Personlig hjelp hvis du ønsker å kjøpe en forsikring</li>
                <li>Tilgjengelige også på kvelder og i helger</li>
                <li>Finnes i nærheten av deg</li>
              </ul>
            </div>
          ) : (
            <p>Denne oppdretteren har ikke en forsikringsagent</p>
          )}
        </Collapse>
      </div>
      {selectedDog && dogModal && (
        <DogModal
          show={dogModal}
          onHide={() => setDogModal(false)}
          selectedParent={selectedDog}
        />
      )}
      {/* {selectedDog && (
        <SwipeableDrawer
          anchor={"bottom"}
          open={dogModal}
          onClose={() => {
            setDogModal(false);
            setSelectedDog(undefined);
          }}
          onOpen={() => setDogModal(true)}
          swipeAreaWidth={100}
        >
          <DogModalContent dogData={selectedDog} />
        </SwipeableDrawer>
      )} */}
      <CreateUserDialog
        open={showCreateUser}
        onClose={() => setShowCreateUser(false)}
        description="Du må opprette bruker eller logge inn for å kontakte oppdretter"
        eventName="Registrer - Kontakt oppdretter"
      />
    </div>
  );
};

export default ProfileMobile;
