import React from "react";
import Fade from "react-reveal/Fade";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import ArticleCard from "../../ArticleCard"; // Assuming you have an ArticleCard component

interface Article {
  contentType: string;
  name: string;
  createDate: string;
  id: string;
  route: {
    path: string;
    startItem: {
      id: string;
      path: string;
    };
  };
}

interface ArticleSectionProps {
  title: string;
  articlePicker: Article[];
  articlePage: {
    url: string;
    title: string;
    linkType: string;
  }[];
  isMobile: boolean;
  css: any;
}

const ArticleSection: React.FC<ArticleSectionProps> = ({
  title,
  articlePicker,
  articlePage,
  isMobile,
  css,
}) => {
  const sortedArticles: any = articlePicker; // Assuming articles are already sorted
  return (
    <div className={css.articles}>
      <Fade bottom>
        <h2>{title}</h2>
        <div className={css.content}>
          {isMobile ? (
            <Swiper
              slidesPerView={1}
              style={{ paddingBottom: 60 }}
              spaceBetween={15}
              className="mySwiper"
              autoplay={true}
              speed={1000}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
            >
              {sortedArticles?.map((article: any) => (
                <SwiperSlide key={article.id} className={css.articleSwiper}>
                  <ArticleCard
                    title={article.properties.heading}
                    url={article.route.path}
                    // Assuming you have a way to fetch image, article type, breed, etc.
                    img={
                      article.properties.image
                        ? article.properties.image[0].url +
                          "?width=600&height=200"
                        : ""
                    } // Replace with actual image path logic
                    articleType={
                      article.properties?.articalCategory
                        ? article.properties?.articalCategory[0].name
                        : ""
                    }
                    breed={article.properties.shortDescription}
                    date={new Date(article.properties.articleDate)}
                    mobile={true}
                    className={css.mobileArticleCard}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <div className={css.articleGrid}>
              {sortedArticles?.map((article: any) => (
                <ArticleCard
                  key={article.id}
                  title={article.properties.heading}
                  url={article.route.path}
                  img={
                    article.properties.image
                      ? article.properties.image[0].url +
                        "?width=375&height=200"
                      : ""
                  } // Replace with actual image path logic
                  articleType={
                    article.properties?.articalCategory
                      ? article.properties?.articalCategory[0].name
                      : ""
                  }
                  breed={article.properties.shortDescription}
                  date={new Date(article.properties.articleDate)}
                  mobile={true}
                />
              ))}
            </div>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default ArticleSection;
