/* eslint-disable */
import React from "react";
//import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import "./index.css";
import { QueryClient, QueryClientProvider } from "react-query";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import CookieConsent from "react-cookie-consent";
import "@navikt/ds-css";
import { AuthProvider } from "./hooks/useAuth0";

const queryClient = new QueryClient();
// render(
//   <React.StrictMode>
//     <HelmetProvider>
//       <QueryClientProvider client={queryClient}>
//         <App />
//         <CookieConsent
//           location="bottom"
//           buttonText="Jeg godtar"
//           cookieName="cookieApproval"
//           style={{
//             background: "#e2f6d1",
//             color: "#065c53",
//             borderTop: "1px solid #065C53",
//             zIndex: 99999999,
//             alignItems: "center",
//           }}
//           buttonStyle={{
//             color: "#fff",
//             background: "#065c53",
//             fontSize: "12px",
//             paddingLeft: 25,
//             paddingRight: 25,
//             borderRadius: 100,
//           }}
//           expires={150}
//         >
//           Denne nettsiden bruker informasjonskapsler (cookies) for at den skal
//           fungere optimalt. Ved å fortsette å bruke siden aksepterer du at vi
//           lagrer cookies på din enhet.
//         </CookieConsent>
//       </QueryClientProvider>
//     </HelmetProvider>
//   </React.StrictMode>,
//   document.getElementById("root")
// );

const root = createRoot(document.getElementById("root") as Element);

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <App />
        </AuthProvider>
        <CookieConsent
          location="bottom"
          buttonText="Jeg godtar"
          cookieName="cookieApproval"
          style={{
            background: "#e2f6d1",
            color: "#065c53",
            borderTop: "1px solid #065C53",
            zIndex: 99999999,
            alignItems: "center",
          }}
          buttonStyle={{
            color: "#fff",
            background: "#065c53",
            fontSize: "12px",
            paddingLeft: 25,
            paddingRight: 25,
            borderRadius: 100,
          }}
          expires={150}
        >
          Denne nettsiden bruker informasjonskapsler (cookies) for at den skal
          fungere optimalt. Ved å fortsette å bruke siden aksepterer du at vi
          lagrer cookies på din enhet.
        </CookieConsent>
      </QueryClientProvider>
    </HelmetProvider>
  </React.StrictMode>
);
