//import { FavoriteBorder } from "@mui/icons-material";
import React from "react";
import css from "../styles/AdCard.module.scss";
import { Link } from "react-router-dom";

export interface props {
  imgSrc: string;
  imgAlt: string;
  breedName: string;
  breederName: string;
  price: number;
  totalDogsAvailable: number;
  to: string;
  city: string;
  status?: string;
  className?: string;
  isMobile?: boolean;
}
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// const iconStyle = {
//   color: "#ABC6C2",
//   width: "14px",
//   height: "100%",
//   fontSize: 32,
// };

const AdCard: React.FC<props> = function ({
  imgSrc,
  imgAlt,
  breedName,
  breederName,
  price,
  totalDogsAvailable,
  city,
  status,
  to,
  className,
  isMobile,
}: props) {
  if (isMobile) {
    return (
      <Link className={`${css.card} ${className}`} to={to}>
        <div className={css.imageContainer}>
          {/* <picture>
                  <source
                    media="(max-width: 800px)"
                    srcSet={ imgSrc? `${imgSrc}?width=172&height=75` : ""}
                  />
                  <img
                    src={imgSrc? `${imgSrc}?width=194&height=194` : ""}
                    alt={imgAlt || "Dog Image"}
                  />
                </picture>             */}
          <img
            src={imgSrc}
            alt={imgAlt}
            className={css.image}
            onError={(e) => {
              e.currentTarget.src = "/images/placeholder-dog.png";
            }}
          />
        </div>
        <div className={css.textContainer}>
          <div>
            <div className={css.breederName}>{breederName}</div>
            <div className={css.breedName}>{breedName}</div>
          </div>
          <div style={{ paddingBottom: 10 }}>
            <div className={css.availability}>
              {status === "AVAILABLE"
                ? "Tilgjengelig"
                : status === "PLANNED"
                ? "Planlagt"
                : "Kommende"}
            </div>
            <div className={css.city}>{city ? `${city}, Norge` : "Norge"}</div>
            <div className={css.price}>
              {price === 0 ? "Ingen pris" : `${price} NOK`}
            </div>
          </div>
        </div>
      </Link>
    );
  }
  return (
    <Link className={`${css.card} ${className}`} to={to}>
      <div className={css.breedNameContainer}>
        <div className={css.breedName}>{breedName}</div>
        {/* <span className={css.heartIcon}>
          <FavoriteBorder style={iconStyle} />
        </span> */}
      </div>
      <div className={css.imageContainer}>
        <img
          src={imgSrc}
          alt={imgAlt}
          className={css.image}
          onError={(e) => {
            e.currentTarget.src = "/images/placeholder-dog.png";
          }}
        />
        {/* <picture>
               <source
                    media="(max-width: 800px)"
                    srcSet={imgSrc? `${imgSrc}?width=172&height=75` : ""}
                  />
                  <img
                    src={imgSrc? `${imgSrc}?width=194&height=194` : ""}
                    alt={imgAlt || "Dog Image"}
                    class={css.image}
                  />
                </picture>           */}
        <div
          className={
            status === "AVAILABLE"
              ? css.availableStatus
              : status === "COMING"
              ? css.comingStatus
              : css.status
          }
        >
          {status === "AVAILABLE"
            ? "Tilgjengelig"
            : status === "PLANNED"
            ? "Planlagt"
            : "Kommende"}
        </div>
      </div>
      <div className={css.breederNameContainer}>
        <div className={css.city}>{city}</div>
        <div className={css.price}>
          {price === 0 ? "Ingen pris" : `${price} NOK`}
        </div>
      </div>
      <div className={css.breederName}>{breederName}</div>
      {totalDogsAvailable > 0 && (
        <div className={css.totalDogsAvailable}>
          {totalDogsAvailable} {totalDogsAvailable === 1 ? "valp" : "valper"}{" "}
          tilgjengelig
        </div>
      )}
    </Link>
  );
};

export default AdCard;
