import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Stack,
  TextField,
  Typography,
  Divider,
  Button,
  CircularProgress,
  Alert,
} from "@mui/material";
import useAuth0 from "../../hooks/useAuth0";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import "./index.scss";
import { Controller, useForm } from "react-hook-form";
import useToast from "../../components/Toast/hooks";
import PasswordField from "../custom-register/components/PasswordField";
import useFacebook from "../../hooks/useFacebook";

interface LoginFormInputs {
  email: string;
  password: string;
}

const Login: React.FC = (): JSX.Element => {
  const history = useHistory();
  const { login, isAuthenticated } = useAuth0();
  const { showToast } = useToast();
  const fbOptions = {
    appId: "5768982976524830", //process.env.FACEBOOK_APP_ID,
    version: "v18.0",
    autoLogAppEvents: false,
    xfbml: true,
    debug: false,
    lang: "nb_NO",
  };

  const { isFacebookSDKReady } = useFacebook(fbOptions);

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<LoginFormInputs>();
  const [isLogingin, setIsLogingin] = useState(false);
  const [isExternalLogin, setIsExternalLogin] = useState(false);
  const handleLogin = async (data: LoginFormInputs) => {
    setIsLogingin(true);
    try {
      const result: any = await login(
        "/auth/token",
        {
          UserName: data.email,
          Password: data.password,
          ClientId: "consoleApp",
          ClientSecret: "99YcruwNlumSvcLDRg0t1wjbTwmPstL6G3CBAwEs4OM=",
          Scope: "all",
        },
        false
      );
      if (result) {
        showToast("Logged in successfully.", "success");
        history.push("/min-pond");
      } else {
        throw new Error();
      }
    } catch (error: any) {
      showToast("Invalid username or password.", "error");
    } finally {
      setIsLogingin(false);
    }
  };

  const fbLogin = async () => {
    try {
      setIsExternalLogin(true);
      const res: IAuthFacebook = await new Promise(window.FB.login);
      if (!res) {
        return;
      }
      const { authResponse } = res;
      if (!authResponse?.accessToken) return;
      const result: any = await login(
        "/auth/facebook-token",
        {
          AccessToken: authResponse.accessToken,
          UserId: authResponse.userID,
          ClientId: "consoleApp",
          Scope: "all",
        },
        true
      );
      if (result) {
        history.push("/min-pond");
      }
    } catch (error: any) {
      showToast(error.response.data, "error");
    } finally {
      setIsExternalLogin(false);
    }
  };
  return (
    <Stack className="custom-login">
      <form onSubmit={handleSubmit(handleLogin)}>
        <Stack className="card">
          <img src="/images/Pond_green.svg" alt="POND logo" />
          <Typography className="heading" fontSize="1.5rem">
            Velkommen
          </Typography>

          <TextField
            label="E-postadresse*"
            variant="outlined"
            fullWidth
            {...register("email", {
              required: "E-postadresse er påkrevd",
              pattern: {
                value: /^[a-zA-Z0-9_.+-æøåÆØÅ]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                message: "Ugyldig e-postadresse",
              },
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <Controller
            render={({ field, fieldState }) => (
              <PasswordField
                label="Passord*"
                error={!!fieldState.error}
                onChange={(val) => field.onChange(val)}
                helperText={fieldState.error ? fieldState.error.message : ""}
              />
            )}
            name="password"
            control={control}
            rules={{ required: "Passord er påkrevd" }}
          />
          <Alert variant="outlined" severity="error">
            Vi har oppdatert våre systemer! Hvis du allerede har en bruker ber vi deg om å tilbakestille passordet ditt før du logger inn. Klikk på ‘Glemt passord’ for å opprette et nytt.
          </Alert>
          <Typography className="link" sx={{ fontSize: "0.875rem" }}>
            <span onClick={() => history.push("/forget-password")}>
              Glemt passord?
            </span>
          </Typography>
          <Button
            disabled={isLogingin}
            variant="contained"
            size="large"
            type="submit"
          >
            {isLogingin ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              "Fortsett"
            )}
          </Button>

          <Typography sx={{ fontSize: "14px" }}>
            Har du ikke noen konto?
            <span
              className="link"
              style={{ fontSize: "0.875rem", marginLeft: "6px" }}
              onClick={() => history.push("/register")}
            >
              Registrer deg
            </span>
          </Typography>
          <Divider sx={{ alignItems: "flex-start", fontSize: 12 }}>
            ELLER
          </Divider>

          <Button
            variant="outlined"
            size="large"
            startIcon={<FacebookRoundedIcon />}
            className="fb"
            disabled={!isFacebookSDKReady || isExternalLogin}
            type="button"
            onClick={fbLogin}
          >
            {isExternalLogin ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              " Fortsett med Facebook"
            )}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};

export default Login;
