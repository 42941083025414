import React, { useEffect, useState } from "react";
import css from "../../styles/FAQ.module.scss";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import FaqSection from "../../components/UmbracoComponents/FAQ/FaqSection";
import { IApiContentResponsePagedViewModel } from "../../api/umbracoApi";
import { Helmet } from "react-helmet-async";

const FAQ: React.FC = function () {
  const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
  const [groupedItems, setGroupedItems] = useState<any>();
  const { data: faqsPaged } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      fetch: "children:faq",
      sort: ["sortOrder:asc"],
    });
    const { data: contents } =
    useUmbracoContentItemQuery<any>("spoersmaal");

  useEffect(() => {
    window.analytics.page("FAQ");
  }, []);

  useEffect(() => {
    setGroupedItems(
      faqsPaged &&
        faqsPaged.items.reduce((acc: any, item: any) => {
          const categoryName = item.properties.faqCategory[0].name;
          if (!acc[categoryName]) {
            acc[categoryName] = [];
          }
          acc[categoryName].push(item);
          return acc;
        }, {})
    );
  }, [faqsPaged]);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }

  const currentUrl = window.location.href;
  return (
    <div className={css.FAQ}>
      <Helmet>
      <meta charSet="utf-8" />
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={
            contents?.properties?.metaKeywords
              ? contents?.properties?.metaKeywords
              : "Pond.no"
          }
          />
          <meta property="og:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl}  />
          <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta property="og:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"}  />
          {contents?.properties?.ogImage && (
          <meta name="og:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {contents?.properties?.ogImage && (
          <meta name="og:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeywords : "Pond.no"} />
          <meta property="twitter:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl} />
          <meta name="twitter:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta name="twitter:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {contents?.properties?.ogImage && (
          <meta name="twitter:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
          {contents?.properties?.ogImage && (
            <meta name="twitter:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
            )}
        </Helmet>
      {groupedItems && (
        <FaqSection
          items={groupedItems}
          setVisibleIndex={setVisibleIndex}
          visibleIndex={visibleIndex}
          css={css}
        />
      )}
    </div>
  );
};

export default FAQ;
