import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import Fade from "react-reveal/Fade";
import { useHistory } from "react-router-dom";
import AdCard from "../../AdCard";
import "swiper/css";
import "swiper/css/pagination";
import Button from "../../Button";
import { DogAd } from "../../../api/graphql.gen-old";
import { DogAdDto } from "../../../api/graphql.gen";
import { axios } from "../../../api/authenticated-axios";

interface SliderSectionProps {
  title: string;
  randomAds: DogAdDto[];
  isMobile: boolean;
  css: any;
}

const ForSaleSection: React.FC<SliderSectionProps> = ({
  title,
  randomAds,
  isMobile,
  css,
}) => {
  const history = useHistory();
  const [contents, setContents] = useState<any>([]);

  useEffect(() => {
    async function getContent() {
      const res = await axios.get("/DogAd/getLatest3DogAdList");
      setContents(res.data);
      //console.log(res.data);
    }
    getContent();
    //console.log("json=======", contents);
  }, []);

  //if (contents != null) console.log(contents);
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }
  return (
    <div className={css.ads}>
      <h2>{title}</h2>
      <div className={css.content}>
        <Fade bottom>
          {isMobile ? (
            <Swiper
              slidesPerView={1}
              spaceBetween={15}
              style={{ paddingBottom: 60 }}
              className="mySwiper"
              autoplay={true}
              speed={1000}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination, Autoplay]}
            >
              {contents?.map((ad: any) => {
                 const adImage = ad?.adImage == "/images/placeholder-dog.png" || ad?.adImage =="" || !ad?.adImage ? "" : JSON.parse(ad?.adImage??"");
                return (
                <SwiperSlide key={ad.id}>
                  <AdCard
                    isMobile={true}
                    status={ad.adType}
                    // imgSrc={
                    //   ad.malePhotos[0] ??
                    //   ad.adImage ??
                    //   "/images/placeholder-dog.png"
                    // }
                    imgSrc={adImage ? urlFor(adImage?.url+"?width=410&height=410") : "/images/placeholder-dog.png"}
                    className={css.gridItem}
                    imgAlt="Annonsebilde"
                    breedName={ad.breed}
                    breederName={ad.kennelname ?? ""}
                    price={ad.price ?? 0}
                    totalDogsAvailable={
                      ad.numberOfPuppies ?? ad.males.length + ad.females.length
                    }
                    city={ad?.city ?? ""}
                    to={`/annonse/${ad.id}`}
                  />
                </SwiperSlide>
                )
})}
            </Swiper>
          ) : (
            <div className={css.adGrid}>
              {contents?.map((ad: any) => {
                const adImage = ad?.adImage == "/images/placeholder-dog.png" || ad?.adImage =="" || !ad?.adImage ? "" : JSON.parse(ad?.adImage??"");
                return (
                <AdCard
                  key={ad.id}
                  status={ad.adType}
                  // imgSrc={
                  //   ad.malePhotos[0] ??
                  //   ad.adImage ??
                  //   "/images/placeholder-dog.png"
                  // }
                  imgSrc={adImage ? urlFor(adImage?.url+"?width=410&height=410") : "/images/placeholder-dog.png"}
                  className={css.gridItem}
                  imgAlt="Annonsebilde"
                  breedName={ad?.breed}
                  breederName={ad?.kennelName ?? ""}
                  price={ad.price ?? 0}
                  totalDogsAvailable={
                    ad.numberOfPuppies ?? ad.males.length + ad.females.length
                  }
                  city={ad?.city ?? ""}
                  to={`/annonse/${ad.id}`}
                />
                )
              })}
            </div>
          )}
        </Fade>
      </div>
      <Button
        label="Se valper til salgs"
        skin="lightGreen"
        onClick={() => history.push("/annonser")}
      />
    </div>
  );
};

export default ForSaleSection;
