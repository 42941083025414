import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router";
import css from "../styles/BreedProfile.module.scss";
import {
  useUpdateFavoritesMutation,
  useGetUserQuery,
  useGetUserByEmailQuery,
  User,
  useGetBreedByNameQuery,
} from "../api/graphql.gen-old";
import {
  Alert,
  Avatar,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Slide,
  Stack,
} from "@mui/material";
import { getBannerSrc, getBlobSrc } from "../utils/getBreedImage";
import {
  translateActivityLevel,
  translateGrooming,
  translateSize,
} from "../utils/translateBreedEnums";
import Button from "../components/Button";
import { useIsMobile } from "../hooks/useIsMobile";
import { Favorite } from "@mui/icons-material";
import useAuth0 from "../hooks/useAuth0";
import { TransitionProps } from "@mui/material/transitions";
import { Helmet } from "react-helmet-async";
import LoadingScreen from "../components/LoadingScreen";
import {
  AktivitetsnivContentModel,
  ClubItemContentResponseModel,
  DogBreedItemContentModel,
  IApiContentResponsePagedViewModel,
  PelspleieContentModel,
  SamarbeidContentModel,
  StRrelseContentModel,
} from "../api/umbracoApi";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import {
  useBreedersQueryQuery,
  useGetMeQueryQuery,
  useUpdateFavoritesMutationMutation,
} from "../api/graphql.gen";
interface RouteParams {
  breedId: string;
  breedName: string;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown, string>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BreedProfile: React.FC = () => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const { breedName } = useParams<RouteParams>();

  const formattedBreedName = breedName.replace(/-/g, " ");

  const { data: breed, isLoading } =
    useUmbracoContentItemQuery<DogBreedItemContentModel>(
      "/hunderaser/" +
        breedName +
        "?expand=properties[aktivitetsnivPicker,stRrelsePicker,samarbeidPicker,pelspleiePicker]"
    );

  const { data: breeder } = useBreedersQueryQuery({
    breed: breed?.name ?? "",
  });

  const breeders = breeder?.memberQuery.breeders;

  const {
    isAuthenticated,
    loginWithRedirect,
    isLoading: ISLOADING,
  } = useAuth0();
  // const { data: userInfo } = useGetUserByEmailQuery({
  //   email: authUser?.email ?? "",
  // });
  // const { data: userData, refetch } = useGetUserQuery({
  //   id: userInfo?.getUserByEmail.id ?? -1,
  // });

  const { data: userData, refetch } = useGetMeQueryQuery();

  const user = userData?.memberQuery.me;

  const { mutate: updateFavorite } = useUpdateFavoritesMutationMutation({
    onSuccess: () => refetch(),
  });

  const history = useHistory();

  const aboutDogsRef = useRef<HTMLDivElement>(null);
  const needsRef = useRef<HTMLDivElement>(null);
  const healthRef = useRef<HTMLDivElement>(null);
  const historyRef = useRef<HTMLDivElement>(null);
  const clubRef = useRef<HTMLDivElement>(null);
  const breedersRef = useRef<HTMLDivElement>(null);
  const { data: clubItem } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:clubItem"],
    });
  const clubInfo: ClubItemContentResponseModel | undefined =
    clubItem?.items.find(
      (club: any) =>
        club.properties?.breedTitle.toLowerCase() === breed?.name?.toLowerCase()
    );
  const profilePhoto = breed?.properties?.profilePhoto
    ? baseUrl +
      breed?.properties?.profilePhoto[0]?.url +
      "?width=200&height=200"
    : "";
  const coverPhoto = breed?.properties?.coverPhoto
    ? baseUrl + breed?.properties?.coverPhoto[0]?.url + "?width=610&height=410"
    : "";
  const { isMobile } = useIsMobile();

  useEffect(() => {
    window.analytics.page(`Raseprofil - ${breed?.name}`);
  }, []);

  const executeScroll = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({
      behavior: "smooth",
    });
  };

  const blobSrc = getBlobSrc(breed?.name ?? "");

  const [loading, setLoading] = useState(false);

  const addFavorite = async () => {
    if (!isAuthenticated && !ISLOADING) {
      setCreateUserModal(true);
    } else {
      if (breed?.name && user?.memberId) {
        const oldFavorites = user.favorites?.breedersList ?? [];
        let newFavorites: string[] = [];
        if (oldFavorites.includes(formattedBreedName)) {
          newFavorites = oldFavorites.filter(
            (item) => item !== formattedBreedName
          );
        } else {
          newFavorites = [...oldFavorites, formattedBreedName];
        }
        setLoading(true);
        try {
          await updateFavorite({ model: { breeds: newFavorites } });
          setLoading(false);
        } catch (error) {
          console.error("Error updating favorites", error);
        }
      }
    }
  };

  const [aboutVisible, setAboutVisible] = useState(false);
  const [needsVisible, setNeedsVisible] = useState(false);
  const [healthVisible, setHealthVisible] = useState(false);
  const [clubVisible, setClubVisible] = useState(false);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [createUserModal, setCreateUserModal] = useState(false);
  const [noBanner, setNoBanner] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);

  useEffect(() => {
    if (breed?.name && user?.favorites?.breeds?.includes(formattedBreedName)) {
      setIsFavorite(true);
    } else {
      setIsFavorite(false);
    }
    if (breed?.name) {
      setNoBanner(false);
    }
  }, [userData, breed]);

// function breedItemPickers(picker:any){      // basic 
//     switch(picker.contentType){
//       case"aktivitetsnivItem":
//         if(picker.properties.title=="Lav"){
//           return "Lite";
//         }else if(picker.properties.title=="Høyt"){
//           return "Mye";
//         }else{
//           return picker.properties.title;
//         }
//       case"samarbeidItem":
//         if(picker.properties.title=="Lav"){
//           return "Lite";
//         }else{
//           return picker.properties.title;
//         }
//       case"pelspleieItem":
//         if(picker.properties.title=="Middels"){
//           return "Medium";
//         }else if(picker.properties.title=="Lav"){
//           return "Lite";
//         }else{
//           return picker.properties.title;
//         }

//       default:
          
//             return picker.properties.title;
          
//     }
//   }

  function breedItemPicker(picker:any){    // refactored
    switch(picker.contentType){
      case"pelspleieItem":
        if(picker.properties.title=="Middels"){
          return "Medium";
        }else if(picker.properties.title=="Lav"){
          return "Lite";
        }else{
          return picker.properties.title;
        }
      default:
          if(picker.properties.title=="Lav"){
            return "Lite";
          }else if(picker.properties.title=="Høyt"){
            return "Mye";
          }else{
            return picker.properties.title;
          }
    }
  }

  const showAdStatus = (breeder: User) => {
    const allAds = breeder.ads;
    let adString = "Har ";
    if (allAds && allAds.filter((ad) => ad.adType !== "DELETED").length > 0) {
      if (allAds.filter((ad) => ad.adType === "AVAILABLE").length > 0) {
        adString = adString + "tilgjengelig";
      }
      if (allAds.filter((ad) => ad.adType === "PLANNED").length > 0) {
        adString =
          adString +
          (adString.includes("tilgjengelig") ? ", planlagt" : "planlagt");
      }
      if (allAds.filter((ad) => ad.adType === "COMING").length > 0) {
        adString =
          adString +
          (adString.includes("tilgjengelig") ||
          adString.includes(", planlagt") ||
          adString.includes("planlagt")
            ? ", kommende "
            : "kommende");
      }
      return adString + " kull";
    } else {
      return "Ingen kull";
    }
  };

  if (isLoading) {
    return <LoadingScreen />;
  }

  function urlFor(source: any) {
    return baseUrl + source;
  }
 
  const currentUrl = window.location.href;
  const domain = new URL(currentUrl).hostname.replace('www.', '');

  return (
    <div>
      {breed?.name && (
        <Helmet>
          <meta charSet="utf-8" />
          <title>{breed ? breed?.properties?.breedName : breedName}</title>
        <meta name="description" content={breed?.properties?.om ?? ""} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content="Pond.no"
          />
          <meta property="og:url" content={currentUrl}  />
          <meta property="og:title" content={breed ? breed?.properties?.breedName ?? "" : breedName} />
          <meta property="og:description" content={breed?.properties?.om ?? ""}  />
          {profilePhoto && (
          <meta name="og:image:secure" content={profilePhoto}  />
          )}
          {profilePhoto && (
          <meta name="og:image" content={profilePhoto}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={domain} />
          <meta property="twitter:url" content={currentUrl} />
          <meta name="twitter:title" content={breed ? breed?.properties?.breedName ?? "" : breedName} />
          <meta name="twitter:description" content={breed?.properties?.om ?? ""} />
          {profilePhoto && (
          <meta name="twitter:image:secure" content={profilePhoto}></meta>
          )}
          {profilePhoto && (
          <meta name="twitter:image" content={profilePhoto}></meta>
          )}
        </Helmet>
      )}
      {isMobile ? (
        <div className={css.BreedProfile}>
          <div className={css.bannerContainer}>
            <div className={css.middleBanner}>
              {!noBanner && (
                <div>
                  <img
                    src={getBannerSrc(breed?.name ?? "")}
                    onError={() => {
                      setNoBanner(true);
                    }}
                  />
                  {breed?.name === "Shiba" && (
                    <div className={css.imageCredit}>Bente Vedal</div>
                  )}
                  {breed?.name === "Chihuahua langhåret" && (
                    <div className={css.imageCredit}>Camilla Suzann Nilsen</div>
                  )}
                  {breed?.name === "Norsk buhund" && (
                    <div className={css.imageCredit}>
                      Ina Margrethe Gabrielsen
                    </div>
                  )}
                  {breed?.name === "Staffordshire bull terrier" && (
                    <div className={css.imageCredit}>Jeaninie Neegaard</div>
                  )}
                  {breed?.name === "Shetland sheepdog" && (
                    <div className={css.imageCredit}>
                      Silje Marie Foss Gravingen
                    </div>
                  )}
                  {breed?.name === "Tibetansk mastiff" && (
                    <div className={css.imageCredit}>Trine Møller</div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className={css.kennelContainer}>
            <div className={css.kennelHorizontalContainer}>
              <Avatar
                src={blobSrc}
                alt="Profilbilde"
                sx={{ background: "#fff" }}
                className={css.profilePic}
              />
              <div className={css.kennelInfo}>
                <div className={css.kennelName}>{breed?.name}</div>
                <div className={css.grouping}>
                  {breed?.properties?.breedSubTitle
                    ?.toString()
                    .replaceAll(",", ", ")}
                </div>
              </div>
            </div>
          </div>
          {breed?.name === "Border collie" ||
          breed?.name === "Siberian husky" ? (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ width: "90vw", marginTop: "2rem" }}
            >
              Denne rasen er ofte blant de som blir omplassert. Tenk deg grundig
              om, slik at du kan ta det beste valget for deg og for hunden.
            </Alert>
          ) : undefined}
          <div className={css.breedInfo}>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/activity.svg" />
              <div className={css.key}>Aktivitetsnivå</div>
              {breed?.properties?.aktivitetsnivPicker?.map(
                (item: AktivitetsnivContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/size.svg" />
              <div className={css.key}>Størrelse</div>
              {breed?.properties?.stRrelsePicker?.map(
                (item: StRrelseContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/coop.svg" />
              <div className={css.key}>Samarbeid</div>
              {breed?.properties?.samarbeidPicker?.map(
                (item: SamarbeidContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/grooming.svg" />
              <div className={css.key}>Pelspleie</div>
              {breed?.properties?.pelspleiePicker?.map(
                (item: PelspleieContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
          </div>

          <div className={css.container}>
            <div className={css.bottomContainer}>
              <div className={css.contentContainer}>
                <div className={css.aboutBreeder} ref={aboutDogsRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>{breed?.properties?.omTitle} hunden</h6>
                      <img
                        src={
                          aboutVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setAboutVisible(!aboutVisible)}
                      />
                    </div>
                    <Collapse
                      in={aboutVisible}
                      timeout="auto"
                      className={aboutVisible ? css.collapse : ""}
                    >
                      {breed?.properties?.om ? (
                        <div>
                          <p>{breed?.properties?.om}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={needsRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>{breed?.properties?.behovTitle}</h6>
                      <img
                        src={
                          needsVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setNeedsVisible(!needsVisible)}
                      />
                    </div>
                    <Collapse
                      in={needsVisible}
                      timeout="auto"
                      className={needsVisible ? css.collapse : ""}
                    >
                      {breed?.properties?.behov ? (
                        <div>
                          <p>{breed?.properties?.behov}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={healthRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>{breed?.properties?.helseTitle}</h6>
                      <img
                        src={
                          healthVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setHealthVisible(!healthVisible)}
                      />
                    </div>
                    <Collapse
                      in={healthVisible}
                      timeout="auto"
                      className={healthVisible ? css.collapse : ""}
                    >
                      {breed?.properties?.helse ? (
                        <div>
                          <p>{breed?.properties?.helse}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={historyRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>{breed?.properties?.historieTitle}</h6>
                      <img
                        src={
                          historyVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setHistoryVisible(!historyVisible)}
                      />
                    </div>
                    <Collapse
                      in={historyVisible}
                      timeout="auto"
                      className={historyVisible ? css.collapse : ""}
                    >
                      {breed?.properties?.historie ? (
                        <div>
                          <p>{breed?.properties?.historie}</p>
                        </div>
                      ) : (
                        <span>
                          <b>Under arbeid</b>
                          <p>
                            Vi ønsker å skrive gode og informative tekster for
                            alle raser, men dette tar tid. Kan du mye om rasen
                            og ønsker å gi oss et forslag for hva som kan stå
                            her?
                          </p>
                          <p>Send oss en tekst på kontakt@pond.no.</p>
                        </span>
                      )}
                    </Collapse>
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={clubRef}>
                  <div className={css.textContainer}>
                    <div className={css.dropDown}>
                      <h6>{breed?.properties?.klubbTitle}</h6>
                      <img
                        src={
                          clubVisible
                            ? "/images/icons/close.svg"
                            : "/images/icons/open.svg"
                        }
                        alt="Åpne ikon"
                        onClick={() => setClubVisible(!clubVisible)}
                      />
                    </div>
                    <Collapse
                      in={clubVisible}
                      timeout="auto"
                      className={clubVisible ? css.collapse : ""}
                    >
                      {clubInfo ? (
                        <div
                          className={css.clubContainer}
                          onClick={() =>
                            clubInfo.properties.clubLink?.[0].url
                              ? window.open(
                                  clubInfo.properties.clubLink?.[0].url
                                )
                              : undefined
                          }
                        >
                          <Avatar
                            src={
                              clubInfo.properties?.clubImage?.[0]?.url // Use the found club's image if available
                                ? baseUrl + clubInfo.properties.clubImage[0].url
                                : "/images/icons/club.svg"
                            }
                            alt="klubb ikon"
                            className={css.avatar}
                          />
                          <div>{clubInfo.properties?.clubName}</div>
                        </div>
                      ) : (
                        <p>{breed?.properties?.klubb}</p>
                      )}
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.breeders} ref={breedersRef}>
            <div className={css.content}>
              <h4>Kenneler</h4>
              {breeders?.length ? (
                <div className={css.breederGrid}>
                  {breeders?.map((breeder) => {
                    const bannerImage =
                      breeder?.kennel &&
                      (breeder?.kennel.bannerImage ==
                        "/images/placeholder-dog.png" ||
                        breeder?.kennel.bannerImage == "" ||
                        !breeder.kennel.bannerImage)
                        ? ""
                        : JSON.parse(breeder?.kennel?.bannerImage ?? "{}");

                    return (
                      <div className={css.breederCard} key={breeder.memberId}>
                        <div className={css.banner}>
                          {bannerImage && (
                            <img
                              src={
                                bannerImage
                                  ? urlFor(
                                      bannerImage.url + "?width=768&height=440"
                                    )
                                  : ""
                              }
                              alt={breeder?.kennel?.name}
                            />
                          )}
                        </div>
                        <Avatar
                          src={
                            breeder?.profileImage
                              ? urlFor(
                                  breeder?.profileImage +
                                    "?width=190&height=190"
                                )
                              : "/images/default-avatar.png"
                          }
                          className={css.avatar}
                        />
                        <div className={css.name}>{breeder?.kennel?.name}</div>
                        <div className={css.adInfo}>
                          {showAdStatus(breeder as User)}
                        </div>
                        <div className={css.location}>{`${
                          breeder?.city?.trim() ?? "Ukjent"
                        }, Norge`}</div>
                        <Button
                          label="Besøk profil"
                          fontSize={8}
                          onClick={() =>
                            history.push(`/oppdretter/${breeder.memberId}`)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>Ingen oppdrettere enda</p>
              )}
            </div>
          </div>
          <Fab
            aria-label="like"
            color={isFavorite ? "primary" : "secondary"}
            className={css.favorieButton}
            onClick={addFavorite}
          >
            <Favorite color={isFavorite ? "error" : "disabled"} />
          </Fab>
        </div>
      ) : (
        <div className={css.BreedProfile}>
          <div className={css.bannerContainer}>
            <div className={css.leftBanner} />
            {breed?.name ? (
              <div className={css.middleBanner}>
                <img src={coverPhoto} />
                {breed?.name === "Shiba" && (
                  <div className={css.imageCredit}>Bente Vedal</div>
                )}
                {breed?.name === "Chihuahua langhåret" && (
                  <div className={css.imageCredit}>Camilla Suzann Nilsen</div>
                )}
                {breed?.name === "Norsk buhund" && (
                  <div className={css.imageCredit}>
                    Ina Margrethe Gabrielsen
                  </div>
                )}
                {breed?.name === "Staffordshire bull terrier" && (
                  <div className={css.imageCredit}>Jeaninie Neegaard</div>
                )}
                {breed?.name === "Shetland sheepdog" && (
                  <div className={css.imageCredit}>
                    Silje Marie Foss Gravingen
                  </div>
                )}
                {breed?.name === "Tibetansk mastiff" && (
                  <div className={css.imageCredit}>Trine Møller</div>
                )}
              </div>
            ) : (
              <div className={css.noBanner} />
            )}
            <div className={css.rightBanner} />
          </div>
          <div className={css.kennelContainer}>
            <div className={css.kennelHorizontalContainer}>
              <Avatar
                src={profilePhoto}
                alt="Profilbilde"
                sx={{ background: "#fff" }}
                className={css.profilePic}
              />
              <div className={css.kennelInfo}>
                <div className={css.kennelName}>{breed?.name}</div>
                <div className={css.grouping}>
                  {breed?.properties?.breedSubTitle
                    ?.toString()
                    .replaceAll(",", ", ")}
                </div>
              </div>
            </div>
          </div>
          {breed?.name === "Border collie" ||
          breed?.name === "Siberian husky" ? (
            <Alert
              variant="outlined"
              severity="warning"
              sx={{ width: "50vw", marginTop: "2rem" }}
            >
              Dette er en rase som ofte blir omplassert. Tenk deg grundig om
              slik at du kan ta det beste valget for deg og for hunden. Sammen
              kan vi bidra til å redusere antall omplasseringer av hunder og gi
              dem et trygt og varig hjem.
            </Alert>
          ) : undefined}
          <div className={css.breedInfo}>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/activity.svg" />
              <div className={css.key}>Aktivitetsnivå</div>
              {breed?.properties?.aktivitetsnivPicker?.map(
                (item: AktivitetsnivContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/size.svg" />
              <div className={css.key}>Størrelse</div>

              {breed?.properties?.stRrelsePicker?.map(
                (item: StRrelseContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/coop.svg" />
              <div className={css.key}>Samarbeid</div>

              {breed?.properties?.samarbeidPicker?.map(
                (item: SamarbeidContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
            <div className={css.breedCard}>
              <img src="/images/breed_profile/grooming.svg" />
              <div className={css.key}>Pelspleie</div>

              {breed?.properties?.pelspleiePicker?.map(
                (item: PelspleieContentModel, index: number) => (
                  <div key={index}>{breedItemPicker(item)}</div>
                )
              )}
            </div>
          </div>

          <div className={css.container}>
            <div className={css.sidePanel}>
              <div className={css.fixedWrapper}>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(aboutDogsRef)}
                >
                  {breed?.properties?.omTitle}
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(needsRef)}
                >
                  {breed?.properties?.behovTitle}
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(healthRef)}
                >
                  {breed?.properties?.helseTitle}
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(historyRef)}
                >
                  {breed?.properties?.historieTitle}
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(clubRef)}
                >
                  {breed?.properties?.klubberTitle}
                </div>
                <div
                  className={css.panelItem}
                  onClick={() => executeScroll(breedersRef)}
                >
                  {breed?.properties?.oppdrettereTitle}
                </div>
              </div>
            </div>

            <div className={css.bottomContainer}>
              <div className={css.contentContainer}>
                <div className={css.aboutBreeder} ref={aboutDogsRef}>
                  <div
                    className={css.textContainer}
                    style={{ paddingTop: "1rem" }}
                  >
                    {breed?.properties?.om ? (
                      <div>
                        <p>{breed?.properties.om}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={needsRef}>
                  <div className={css.textContainer}>
                    <h6>{breed?.properties?.behovTitle}</h6>
                    {breed?.properties?.behov ? (
                      <div>
                        <p>{breed?.properties.behov}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={healthRef}>
                  <div className={css.textContainer}>
                    <h6>{breed?.properties?.helseTitle}</h6>
                    {breed?.properties?.helse ? (
                      <div>
                        <p>{breed?.properties.helse}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={historyRef}>
                  <div className={css.textContainer}>
                    <h6>{breed?.properties?.historieTitle}</h6>
                    {breed?.properties?.historie ? (
                      <div>
                        <p>{breed?.properties.historie}</p>
                      </div>
                    ) : (
                      <span>
                        <b>Under arbeid</b>
                        <p>
                          Vi ønsker å skrive gode og informative tekster for
                          alle raser, men dette tar tid. Kan du mye om rasen og
                          ønsker å gi oss et forslag for hva som kan stå her?
                        </p>
                        <p>Send oss en tekst på kontakt@pond.no.</p>
                      </span>
                    )}
                  </div>
                </div>
                <div className={css.aboutBreeder} ref={clubRef}>
                  <div className={css.textContainer}>
                    <h6>{breed?.properties?.klubbTitle}</h6>
                    {clubInfo ? (
                      <div
                        className={css.clubContainer}
                        onClick={() =>
                          clubInfo.properties.clubLink?.[0].url
                            ? window.open(clubInfo.properties.clubLink?.[0].url)
                            : undefined
                        }
                      >
                        <Avatar
                          src={
                            clubInfo.properties?.clubImage?.[0]?.url // Use the found club's image if available
                              ? baseUrl + clubInfo.properties.clubImage[0].url
                              : "/images/icons/club.svg"
                          }
                          alt="klubb ikon"
                          className={css.avatar}
                        />
                        <div>{clubInfo.properties?.clubName}</div>
                      </div>
                    ) : (
                      <p>{breed?.properties?.klubb}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={css.breeders} ref={breedersRef}>
            <div className={css.content}>
              <h4>Kenneler</h4>
              {breeders?.length ? (
                <div className={css.breederGrid}>
                  {breeders?.map((breeder) => {
                    const bannerImage =
                      breeder?.kennel &&
                      (breeder?.kennel.bannerImage ==
                        "/images/placeholder-dog.png" ||
                        breeder?.kennel.bannerImage == "" ||
                        !breeder.kennel.bannerImage)
                        ? ""
                        : JSON.parse(breeder?.kennel?.bannerImage ?? "{}");
                    return (
                      <div className={css.breederCard} key={breeder.memberId}>
                        <div className={css.banner}>
                          {bannerImage && (
                            <img
                              src={
                                bannerImage
                                  ? urlFor(
                                      bannerImage.url + "?width=768&height=440"
                                    )
                                  : ""
                              }
                              alt={breeder?.kennel?.name}
                            />
                          )}
                        </div>
                        <Avatar
                          src={
                            breeder?.profileImage
                              ? urlFor(
                                  breeder?.profileImage +
                                    "?width=190&height=190"
                                )
                              : "/images/default-avatar.png"
                          }
                          className={css.avatar}
                        />
                        <div className={css.name}>{breeder?.kennel?.name}</div>
                        <div className={css.adInfo}>
                          {showAdStatus(breeder as User)}
                        </div>
                        <div className={css.location}>{`${
                          breeder?.city?.trim() ?? "Ukjent"
                        }, Norge`}</div>
                        <Button
                          label="Besøk profil"
                          fontSize={14}
                          onClick={() =>
                            history.push(`/oppdretter/${breeder.memberId}`)
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>Ingen oppdrettere enda</p>
              )}
            </div>
          </div>

          <Fab
            aria-label="like"
            color={isFavorite ? "primary" : "secondary"}
            variant="extended"
            className={css.favorieButton}
            size="large"
            onClick={addFavorite}
          >
            {loading ? (
              <CircularProgress color="primary" variant="indeterminate" />
            ) : (
              <>
                <Favorite
                  sx={{ mr: 1 }}
                  color={isFavorite ? "error" : "disabled"}
                />
                {isFavorite ? "Lagret" : "Lagre"}
              </>
            )}
          </Fab>
        </div>
      )}
      <Dialog
        open={createUserModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={addFavorite}
        sx={{ borderRadius: 15 }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Opprett bruker</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Lagre denne rasen og bli varslet når nye oppdrettere registerer seg
            og nye kullannonser legges ut.
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            label="Opprett bruker"
            skin="dark"
            onClick={() => {
              window.analytics.track("Registrer - Raseprofil/favoritt");
              history.push("/login");
            }}
            style={{ marginBottom: 5, width: "90%" }}
          />

          <Button
            label="Avbryt"
            skin="transparent"
            onClick={() => setCreateUserModal(false)}
            style={{ marginBottom: 5, width: "90%" }}
          />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BreedProfile;
