export const validateName = (name: string): boolean => {
  //const regexName = new RegExp(/^[a-zA-Z]{1,}$/);
  if (name.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const validateEmail = (emailAd: string): boolean => {
  const regexEmail = new RegExp(
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  );
  if (regexEmail.test(emailAd)) {
    return true;
  } else {
    return false;
  }
};

export const validatePhone = (number: string): boolean => {
  const regexPhoneNumber = new RegExp(/^[0-9]{8,8}$/);
  if (regexPhoneNumber.test(number)) {
    return true;
  } else {
    return false;
  }
};

export const validatePassword = (text: string): boolean => {
  //const regexPassword = new RegExp(/^(?=.*?[A-Za-z0-9#!$%^&*()+=])\S{8,}$/);
  if (text.length > 7) {
    return true;
  } else {
    return false;
  }
};
