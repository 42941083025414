import React, { useEffect, useState } from "react";
import { useIsMobile } from "../../hooks/useIsMobile";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import { CircularProgress, Stack, Tab, Tabs } from "@mui/material";
import Modal from "react-bootstrap/Modal";
import css from "../../styles/MyApplications.module.scss";
import useAuth0 from "../../hooks/useAuth0";
import {
  useApproveApplicationMutation,
  useCancelApplicationMutation,
  useDenyApplicationMutation,
} from "../../api/graphql.gen-old";
import Button from "../../components/Button";
import ApplicationCard from "../../components/Dashboard/ApplicationCard";
import { useHistory } from "react-router-dom";
import {
  useApplicationQueryQuery,
  useMydogadsQuery,
} from "../../api/graphql.gen";

const MyApplications: React.FC = () => {
  const { isMobile } = useIsMobile();
  const [tab, setTab] = useState("1");
  const [denyModal, setDenyModal] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [selectedApplication, setSelectedApplication] = useState<number>();
  const { user: user, isAuthenticated, isLoading } = useAuth0();
  const { data: applications, refetch } = useApplicationQueryQuery();
  const { data: myAds, refetch: refetchAds } = useMydogadsQuery();

  const myApplications = applications?.applicationQuery.myApplications ?? [];
  const dogAds = myAds?.dogAdQuery.myAds ?? [];

  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const { mutate: denyApplication } = useDenyApplicationMutation({
    onSuccess: () => {
      setDenyModal(false);
      refetch();
    },
  });

  const { mutate: cancelApplication } = useCancelApplicationMutation({
    onSuccess: () => {
      setDenyModal(false);
      refetch();
    },
  });
  const { mutate: approveApplication } = useApproveApplicationMutation({
    onSuccess: () => {
      setApproveModal(false);
      refetch();
      refetchAds();
    },
  });
  const history = useHistory();

  const activeApplications = myApplications.filter(
    (item) => item.status === "ACTIVE"
  );

  const deniedApplications = myApplications.filter(
    (item) => item.status === "DENIED"
  );

  const acceptedApplications = myApplications.filter(
    (item) => item.status === "ACCEPTED"
  );

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, [isAuthenticated]);

  return (
    <div className={css.MyApplications}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <DashboardBanner
        image="/images/icons/dashboard/dog-face-circle.svg"
        percentage={60}
        title="Mine søknader"
        info="Her har du full oversikt over dine søknader. For å godkjenne eller avvise søknader må du inn på “se søknad” under “nye søknader”"
        customWidth={""}
      />
      <div className={css.tabContainer}>
        <Tabs
          value={tab}
          onChange={(_event, value) => setTab(value)}
          variant="fullWidth"
        >
          <Tab value="1" label="Aktive" />
          <Tab value="2" label="Valpeliste" />
          <Tab value="3" label="Avviste" />
        </Tabs>
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <>
            {tab === "1" && (
              <div className={css.tabContent}>
                {user?.breeder ? (
                  <span>
                    {dogAds.filter((ad) => ad.adType !== "DELETED").length ===
                      0 && (
                      <div className={css.noAds}>
                        <h4>Du har ingen aktive annonser</h4>
                      </div>
                    )}
                    {dogAds.map(
                      (ad) =>
                        ad.adType !== "DELETED" && (
                          <div key={ad.id}>
                            <h2>Søknader for {ad.title}</h2>
                            {ad.applications?.filter(
                              (item) => item.status === "ACTIVE"
                            )?.length ? (
                              ad.applications
                                ?.filter((item) => item.status === "ACTIVE")
                                .map((application) => (
                                  <ApplicationCard
                                    key={application.id}
                                    imgSrc={
                                      application.applicant &&
                                      application.applicant.profileImage
                                        ? urlFor(
                                            application.applicant?.profileImage
                                          )
                                        : ""
                                    }
                                    applicationDate={application.createdAt}
                                    breed={application.breed}
                                    kennel={
                                      application.breeder?.kennel?.name ?? ""
                                    }
                                    applicant={
                                      application.applicant?.firstName ?? ""
                                    }
                                    status={application.status}
                                    breeder={user?.breeder}
                                    onApprove={() => {
                                      setSelectedApplication(application.id);
                                      setApproveModal(true);
                                    }}
                                    onDelete={() => {
                                      setSelectedApplication(application.id);
                                      setDenyModal(true);
                                    }}
                                    onSeeAd={() => {
                                      if (application.breeder?.memberId) {
                                        history.push(
                                          `/oppdretter/${application.breeder?.memberId}`
                                        );
                                      }
                                    }}
                                    onSeeApplication={() =>
                                      history.push(`/soknad/${application.id}`)
                                    }
                                    onSendMessage={() =>
                                      history.push(`/kontakt/${user.memberId}`)
                                    }
                                  />
                                ))
                            ) : (
                              <div className={css.noApplication}>
                                <h4>
                                  Du har for øyeblikket ingen aktive søknader
                                </h4>
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </span>
                ) : (
                  <span>
                    {activeApplications?.length ? (
                      activeApplications.map((application) => (
                        <ApplicationCard
                          key={application.id}
                          imgSrc={
                            application.breeder &&
                            application.breeder.profileImage
                              ? urlFor(application.breeder?.profileImage)
                              : ""
                          }
                          applicationDate={application.createdAt}
                          breed={application.breed}
                          kennel={application.breeder?.kennel?.name ?? ""}
                          status={application.status}
                          applicant={application.applicant?.firstName ?? ""}
                          breeder={false}
                          onSeeAd={() => {
                            if (application.breeder?.memberId) {
                              history.push(
                                `/oppdretter/${application.breeder?.memberId}`
                              );
                            }
                          }}
                          onApprove={() => {
                            setSelectedApplication(application.id);
                            setApproveModal(true);
                          }}
                          onDelete={() => {
                            setSelectedApplication(application.id);
                            setDenyModal(true);
                          }}
                          onSeeApplication={() =>
                            history.push(`/soknad/${application.id}`)
                          }
                          onSendMessage={() =>
                            history.push(`/kontakt/${user?.memberId}`)
                          }
                        />
                      ))
                    ) : (
                      <div className={css.noApplication}>
                        <h4>Du har for øyeblikket ingen aktive søknader</h4>
                      </div>
                    )}
                  </span>
                )}
              </div>
            )}
            {tab === "2" && (
              <div className={css.tabContent}>
                {user?.breeder ? (
                  <span>
                    {dogAds.filter((ad) => ad.adType !== "DELETED").length ===
                      0 && (
                      <div className={css.noAds}>
                        <h4>Du har ingen aktive annonser</h4>
                      </div>
                    )}
                    {dogAds.map(
                      (ad) =>
                        ad.adType !== "DELETED" && (
                          <div key={ad.id}>
                            <h2>Søknader for {ad.title}</h2>
                            {ad.applications?.filter(
                              (item) => item.status === "ACCEPTED"
                            )?.length ? (
                              ad.applications
                                ?.filter((item) => item.status === "ACCEPTED")
                                .map((application) => (
                                  <ApplicationCard
                                    key={application.id}
                                    imgSrc={
                                      application.applicant &&
                                      application.applicant?.profileImage
                                        ? urlFor(
                                            application.applicant?.profileImage
                                          )
                                        : ""
                                    }
                                    applicationDate={application.createdAt}
                                    breed={application.breed}
                                    kennel={
                                      application.breeder?.kennel?.name ?? ""
                                    }
                                    applicant={
                                      application.applicant?.firstName ?? ""
                                    }
                                    status={application.status}
                                    breeder={true}
                                    onApprove={() => {
                                      setSelectedApplication(application.id);
                                      setApproveModal(true);
                                    }}
                                    onSeeAd={() => {
                                      if (application.breeder?.memberId) {
                                        history.push(
                                          `/oppdretter/${application.breeder?.memberId}`
                                        );
                                      }
                                    }}
                                    onDelete={() => {
                                      setSelectedApplication(application.id);
                                      setDenyModal(true);
                                    }}
                                    onSeeApplication={() =>
                                      history.push(`/soknad/${application.id}`)
                                    }
                                    onSendMessage={() =>
                                      history.push(`/kontakt/${user.memberId}`)
                                    }
                                  />
                                ))
                            ) : (
                              <div className={css.noApplication}>
                                <h4>Du har ingen på valpeliste</h4>
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </span>
                ) : (
                  <span>
                    {acceptedApplications?.length ? (
                      acceptedApplications.map((application) => (
                        <ApplicationCard
                          key={application.id}
                          imgSrc={
                            application.breeder &&
                            application.breeder?.profileImage
                              ? urlFor(application.breeder?.profileImage)
                              : ""
                          }
                          applicationDate={application.createdAt}
                          breed={application.breed}
                          kennel={application.breeder?.kennel?.name ?? ""}
                          applicant={application.applicant?.firstName ?? ""}
                          status={application.status}
                          onSeeAd={() => {
                            if (application.breeder?.memberId) {
                              history.push(
                                `/oppdretter/${application.breeder?.memberId}`
                              );
                            }
                          }}
                          onSeeApplication={() =>
                            history.push(`/soknad/${application.id}`)
                          }
                          breeder={!!user?.breeder}
                          onDelete={() => {
                            setSelectedApplication(application.id);
                            setDenyModal(true);
                          }}
                          onApprove={() => {
                            setSelectedApplication(application.id);
                            setApproveModal(true);
                          }}
                          onSendMessage={() =>
                            history.push(`/kontakt/${user?.memberId}`)
                          }
                        />
                      ))
                    ) : (
                      <div className={css.noApplication}>
                        <h4>Du er ikke på valpeliste</h4>
                      </div>
                    )}
                  </span>
                )}
              </div>
            )}
            {tab === "3" && (
              <div className={css.tabContent}>
                {user?.breeder ? (
                  <span>
                    {dogAds.filter((ad) => ad.adType !== "DELETED").length ===
                      0 && (
                      <div className={css.noAds}>
                        <h4>Du har ingen aktive annonser</h4>
                      </div>
                    )}
                    {dogAds.map(
                      (ad) =>
                        ad.adType !== "DELETED" && (
                          <div key={ad.id}>
                            <h2>Søknader for {ad.title}</h2>
                            {ad.applications?.filter(
                              (item) => item.status === "DENIED"
                            )?.length ? (
                              ad.applications
                                ?.filter((item) => item.status === "DENIED")
                                .map((application) => (
                                  <ApplicationCard
                                    key={application.id}
                                    imgSrc={
                                      application.applicant &&
                                      application.applicant.profileImage
                                        ? urlFor(
                                            application.applicant?.profileImage
                                          )
                                        : ""
                                    }
                                    applicationDate={application.createdAt}
                                    breed={application.breed}
                                    kennel={
                                      application.breeder?.kennel?.name ?? ""
                                    }
                                    applicant={
                                      application.applicant?.firstName ?? ""
                                    }
                                    status={application.status}
                                    breeder={true}
                                    onSeeAd={() => {
                                      if (application.breeder?.memberId) {
                                        history.push(
                                          `/oppdretter/${application.breeder?.memberId}`
                                        );
                                      }
                                    }}
                                    onApprove={() => {
                                      setSelectedApplication(application.id);
                                      setApproveModal(true);
                                    }}
                                    onDelete={() => {
                                      setSelectedApplication(application.id);
                                      setDenyModal(true);
                                    }}
                                    onSeeApplication={() =>
                                      history.push(`/soknad/${application.id}`)
                                    }
                                    onSendMessage={() =>
                                      history.push(`/kontakt/${user.breeder}`)
                                    }
                                  />
                                ))
                            ) : (
                              <div className={css.noApplication}>
                                <h4>Du har ingen avviste søknader</h4>
                              </div>
                            )}
                          </div>
                        )
                    )}
                  </span>
                ) : (
                  <span>
                    {deniedApplications?.length ? (
                      deniedApplications.map((application) => (
                        <ApplicationCard
                          key={application.id}
                          imgSrc={
                            application.breeder &&
                            application.breeder.profileImage
                              ? urlFor(application.breeder?.profileImage)
                              : ""
                          }
                          applicationDate={application.createdAt}
                          breed={application.breed}
                          kennel={application.breeder?.kennel?.name ?? ""}
                          applicant={application.applicant?.firstName ?? ""}
                          status={application.status}
                          onSeeAd={() => {
                            if (application.breeder?.memberId) {
                              history.push(
                                `/oppdretter/${application.breeder?.memberId}`
                              );
                            }
                          }}
                          onSeeApplication={() =>
                            history.push(`/soknad/${application.id}`)
                          }
                          onApprove={() => {
                            setSelectedApplication(application.id);
                            setApproveModal(true);
                          }}
                          breeder={!!user?.breeder}
                          onSendMessage={() =>
                            history.push(`/kontakt/${user?.memberId}`)
                          }
                        />
                      ))
                    ) : (
                      <div className={css.noApplication}>
                        <h4>
                          Du har for øyeblykket ingen avviste/trukkede søknader
                        </h4>
                      </div>
                    )}
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        show={approveModal}
        onHide={() => setApproveModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={css.modalBody}>
            <img src="/images/puppies.svg" alt="Valper" />
            <h2>
              Ved å godkjenne søknaden setter du NAVN på venteliste for valp.
              Søknaden vil være tilgjengelig under fanen “valpeliste”.
            </h2>
            <div>
              <Button
                label="Tilbake"
                skin="lightGreen"
                onClick={() => setApproveModal(false)}
              />
              <Button
                label="Godkjenn for valpeliste"
                onClick={() =>
                  selectedApplication &&
                  approveApplication({ id: selectedApplication })
                }
              />
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={denyModal}
        onHide={() => setDenyModal(false)}
        fullscreen
        animation
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={css.modalBody}>
            <img src="/images/ouch.svg" alt="Trist hund" />
            <h2>
              {user?.breeder
                ? "Er du sikker på at du vil avvise søknaden til NAVN?"
                : "Er du sikker på at du vil trekke søknaden din?"}
            </h2>
            <div>
              <Button
                label="Tilbake"
                skin="lightGreen"
                onClick={() => setDenyModal(false)}
              />
              {user?.breeder ? (
                <Button
                  label={"Avvis søknad"}
                  skin="error"
                  onClick={() =>
                    selectedApplication &&
                    denyApplication({ id: selectedApplication })
                  }
                />
              ) : (
                <Button
                  label={"Trekk søknad"}
                  skin="error"
                  onClick={() =>
                    selectedApplication &&
                    cancelApplication({ id: selectedApplication })
                  }
                />
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default MyApplications;
