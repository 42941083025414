import React, { useEffect } from "react";
import { useHistory } from "react-router";
import Button from "../components/Button";

const BreederInfo: React.FC = () => {
  const history = useHistory();

  useEffect(() => {
    window.analytics.page("Verktøy for oppdrettere");
  }, []);

  return (
    <div style={{ padding: "4rem", width: "60vw" }}>
      <h4>Verktøy for oppdrettere</h4>
      <p>
        Gjennom POND kan du fremme virksomheten din gratis, med vårt
        sorteringssystem ha full kontroll over alle forespørsler, planlegge og
        ha oversikt over valpebesøk, skreddersy dine egne kontrakter og føle deg
        trygg med vår sikre depositum- og betalingsordning.
      </p>
      <h4>Tilrettelagt for deg og hundens beste</h4>
      <p>
        For deg som er oppdretter ønsker vi å tilrettelegge slik at du finner
        riktige eiere til dine valper. Det er du som kjenner valpene dine best
        og kanskje du til en viss grad kan forutse hvordan de blir som voksne?
      </p>
      <p>
        Gjennom POND oppfordrer vi potensielle hundeeiere til å skrive
        utfyllende om hvorfor de ønsker rasen, hvilken type hund de vil ha og
        hvordan de kan gi hunden et godt hjem. Vi vet at du har valpens beste i
        tankene og vil ønske å stille mange spørsmål til nye hundeeiere. Da kan
        du bruke chatten vår, der du også kan sortere meldingene dine for å få
        bedre oversikt over prosessen og godta/avslå potensielle hundekjøpere.
        Du kan enkelt booke valpebesøk med potensielle hundeeiere i chatten.
      </p>
      <p>
        Dersom valpebesøket har gått fint og du ønsker å selge valpen til
        vedkommende vil du kunne gjøre en trygg overlevering gjennom POND. For å
        gjøre overleveringen tryggere kan du skreddersy din egen kontrakt i Min
        POND. Som en sikkerhet for at kjøpet gjennomføres vil kjøper måtte
        betale inn et depositum. Slik kan du sikre deg mot useriøse
        valpekjøpere.
      </p>
      <h4>Vi ønsker å fremme gode oppdrettere</h4>
      <p>
        Dere er mange gode oppdrettere der ute som jobber hardt for å få friske
        og sunne hunder, og for å finne riktige hjem til sine valper. Nye
        valpekjøpere får ofte råd om at de bør kjøpe valp hos en seriøs
        oppdretter, men spørsmålet for mange er: hvordan kan man skille en
        seriøs oppdretter fra en useriøs en?
      </p>
      <p>
        God brukeropplevelse og så mye relevant informasjon som mulig tror vi
        kan være viktig for at folk skal kunne velge den oppdretteren som passer
        best for dem. Sentralt hos POND er derfor hundeeiernes erfaringer med
        oppdrettere. Slike erfaringer kan ikke leses som en fasit over hvordan
        en oppdretter driver, men det kan likevel gi andre en god pekepinn på om
        oppdretteren passer for dem.
      </p>
      <p>
        Vi ønsker å fremme alle dere som driver på en ansvarlig og god måte, og
        gjøre det enklere for kommende hundeeiere å finne fram til dere. Slik
        vil vi forhåpentligvis unngå at ferske hundeeiere går på en smell og
        kjøper fra valpefabrikker eller useriøse oppdrettere, uten å vite det.
      </p>
      <div
        style={{
          marginTop: "5rem",
          marginBottom: "10rem",
          width: "fit-content",
        }}
      >
        <Button
          label="Opprett en profil for din kennel her"
          skin="dark"
          onClick={() => history.push("/registrer/oppdretter")}
        />
      </div>
    </div>
  );
};

export default BreederInfo;
