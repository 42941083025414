import React from "react";
import { Link } from "react-router-dom";
import { Paper } from "@mui/material";
import Fade from "react-reveal/Fade";

export const PuppySearchSection = ({ puppySearchItems, css, title }: any) => {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;

  return (
    <div className={css.becomeBreeder}>
      <h2>{title}</h2>
      <div className={css.cardContainer}>
        {puppySearchItems.items.map((item: any, index: any) => (
          <Fade bottom delay={index * 100} key={item.content.id}>
            <Paper>
              <Link
                className={css.card}
                onClick={() => {
                  window.analytics.track(
                    `${item.content.properties.title} kort - Forside`
                  );
                }}
                to={item.content.properties.link[0].route.path}
              >
                <picture>
                  <source
                    media="(max-width: 800px)"
                    srcSet={
                      baseUrl + item.content.properties.image[0].url
                        ? `${
                            baseUrl + item.content.properties.image[0].url
                          }?width=344&height=346`
                        : ""
                    }
                  />
                  <img
                    src={
                      baseUrl + item.content.properties.image[0].url
                        ? `${
                            baseUrl + item.content.properties.image[0].url
                          }?width=363&height=365`
                        : ""
                    }
                    alt={item.content.properties.alt}
                  />
                </picture>
                <p>{item.content.properties.title}</p>
              </Link>
            </Paper>
          </Fade>
        ))}
      </div>
    </div>
  );
};

export default PuppySearchSection;
