import css from "../../../styles/Home.module.scss";
import Fade from "react-reveal/Fade";
import ArticleSection from "./AricleSection";
import BannerSection from "./BannerSection";
import CommunitySection from "./CommunitySection";
import FaqSection from "./FaqSection";
import ForSaleSection from "./ForSaleSection";
import MultipleTextSection from "./MultipleTextSection";
import PartnersSection from "./PartnersSection";
import PuppySearchSection from "./PuppySearchSection";
import SliderSection from "./SliderSection";
import TestimonialSection from "./TestimonialSection";
import Button from "../../Button";
import { FC, useRef } from "react";

interface IProps {
  content: any;
  isMobile: any;
  readMoreRef: any;
  loginWithRedirect: any;
  randomAds: any;
  limitedSections: any;
  history: any;
  isAuthenticated: boolean;
}
const handleRedirect = (url: any) => {
  if (url) {
    window.location.href = url;
  }
};
const RenderHomePage: FC<IProps> = ({
  content,
  isMobile,
  readMoreRef,
  loginWithRedirect,
  randomAds,
  limitedSections,
  history,
  isAuthenticated,
}) => {
  const handleButtonClick = () => {
    if (isAuthenticated) {
      window.location.reload();
    } else {
      history.push("/login");
    }
  };
  const multipleTextSectionRef = useRef<HTMLDivElement>(null);

  switch (content.contentType) {
    case "bannerSection":
      return (
        <BannerSection bannerItems={content.properties.bannerItems} css={css} />
      );
    case "puupySearchSection":
      return (
        <PuppySearchSection
          title={content.properties.title}
          puppySearchItems={content.properties.puppySearchItems}
          css={css}
        />
      );
    case "partnerSection":
      return (
        <div className={css.partnersContainer}>
          <h3>{content.properties.samarbeidspartner}</h3>
          <PartnersSection partners={content.properties.partners} css={css} />
        </div>
      );
    case "sliderSection":
      return (
        <SliderSection
          sliderItems={content.properties.sliderItems}
          isMobile={isMobile}
          css={css}
        />
      );
    case "communitySection":
      return (
        <div key={content.id}>
          <div className={css.community}>
            <Fade bottom>
              <h2>{content.properties.title}</h2>
              <CommunitySection
                communityList={content.properties.communityList}
              />
              <Button
                label={content.properties?.link[0].title}
                onClick={() =>
                  handleRedirect(content.properties.link[0].route.path)
                }
              />
            </Fade>
          </div>
        </div>
      );

    case "multipleTextSection":
      return (
        <div ref={multipleTextSectionRef} id="whySection">
          <MultipleTextSection
            properties={content.properties}
            isMobile={isMobile}
            readMoreRef={readMoreRef}
            loginWithRedirect={loginWithRedirect}
            css={css}
          />
        </div>
      );
    case "testimonialSection":
      return (
        <div className={css.testemonial}>
          <TestimonialSection
            title={content.properties.title}
            testimonialPicker={content.properties.testimonialPicker}
            buttonText={content.properties.buttonText}
            link={content.properties.link}
            css={css}
          />
          <Button label="Bli med i fellesskapet!" onClick={handleButtonClick} />
        </div>
      );
    case "articleSection":
      return (
        <ArticleSection
          title={content.properties.title}
          articlePicker={content.properties.articlePicker}
          articlePage={content.properties.articlePage}
          isMobile={isMobile}
          css={css}
        />
      );
    case "forSale":
      return (
        <ForSaleSection
          title={content.properties.title}
          randomAds={randomAds}
          isMobile={isMobile}
          css={css}
        />
      );
    case "faqSection":
      return (
        <FaqSection
          title={content.properties.title}
          limitedSections={limitedSections}
          faqPageLink={content.properties.faqPageLink}
          css={css}
        />
      );
    default:
      return (
        <div key={content.id}>
          <p>Unsupported content type: {content.contentType}</p>
        </div>
      );
  }
};

export default RenderHomePage;
