import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import css from "../../styles/AboutPond.module.scss";

// import { SanityComponent, getPosts, Section } from "../../utils/sanity-common";

interface RouteParams {
  slug: string;
}

const InfoPages: React.FC = function () {
  //const [sections, setSections] = useState<Section[]>([]);
  const { slug } = useParams<RouteParams>();

  useEffect(() => {
    window.analytics.page(slug);
    getPostsType();
  }, []);

  async function getPostsType() {
    //setSections(await getPosts(slug));
  }

  return (
    <div className={css.AboutPond}>
      <div className={css.infoPage}>
        {/* {sections?.map((section, index) => (
          <div key={index}>{SanityComponent(section)}</div>
        ))} */}
      </div>
    </div>
  );
};

export default InfoPages;
