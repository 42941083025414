import React, { useEffect, useRef, useState } from "react";
import { Avatar, CircularProgress } from "@mui/material";
import Button from "./Button";
import { uploadAvatar, uploadFile } from "../api/services/file";
import { FileType } from "../enums";
import css from "../styles/AccountSettings.module.scss";
import { AxiosResponse } from "axios";
import { axios } from "../api/authenticated-axios";

export interface Props {
  url?: string;
  title?: string;
}
const ProfileAvatar: React.FC<Props> = function ({ url, title }) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  // UI/form management
  const [uploading, setUploading] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    url ? url : "/images/default-avatar.png"
  );

  useEffect(() => {
    if (url) {
      setProfilePicture(url);
    }
  }, [url]);
  const onFileChange = (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      onFileUpload(file);
    }
  };

  const onFileUpload = async (file: any) => {
    // prepare UI
    if (!file) {
      return;
    }
    setUploading(true);
    const media = await uploadAvatar(file, FileType.Avatar);
    if (!media) {
      // handle error
      setUploading(false);
      return;
    }
    const url = `${process.env.REACT_APP_API_BASE_URL}${media?.mediaUrl}?width=160&height=160`;
    setProfilePicture(url);
    // reset state/form
    setUploading(false);
  };

  const removeAvatar = async () => {
    const response: AxiosResponse<any> = await axios.post(
      "/memberlist/avatar/remove",
      {}
    );

    if (response.status === 200) {
      setProfilePicture("/images/default-avatar.png");
    }
  };

  return (
    <div>
      {uploading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <CircularProgress color="primary" variant="indeterminate" />
        </div>
      ) : (
        <Avatar src={profilePicture} alt="Profilbilde" className={css.avatar} />
      )}
      <Button
        label="Bytt profilbilde"
        onClick={() => {
          hiddenFileInput.current && hiddenFileInput.current.click();
        }}
      />
      <input type="file" hidden ref={hiddenFileInput} onChange={onFileChange} />
      <Button label="Slett bilde" skin="noColor" onClick={removeAvatar} />
    </div>
  );
};

export default ProfileAvatar;
