import useAuth0 from "../hooks/useAuth0";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
//import Button from "../components/Button";
import css from "../styles/Login.module.scss";
import { axios } from "../api/authenticated-axios";
import { Button, CircularProgress } from "@mui/material";

const Register: React.FC = () => {
  const history = useHistory();

  const { isAuthenticated, isLoading, user } = useAuth0();
  const [isMemberTypeLoading, setIsMemberTypeLoading] = useState(false);
  const [isBreederLoading, setIsBreederLoading] = useState(false);

  useEffect(() => {
    window.analytics.page("Bli POND'er");
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    if (user?.isRegisterStepsCompleted === true) {
      history.push("/min-pond");
    }
  }, [user]);

  const handleLUserType = async (isBreeder: boolean) => {
    !isBreeder && setIsMemberTypeLoading(true);
    isBreeder && setIsBreederLoading(true);
    await axios.post("/memberlist/registermembertype", isBreeder);
    !isBreeder && setIsMemberTypeLoading(false);
    isBreeder && setIsBreederLoading(false);
    history.push("/on-boarding");
  };
  return (
    <div className={css.Login}>
      <div className={css.Register}>
        <h4 className={css.title} style={{ width: 500 }}>
          Hva beskriver deg best?
        </h4>
        <div className={css.buttonIllustrationContainer}>
          <div className={css.buttonParent}>
            <img
              src="/images/breeder_illustration.png"
              alt="Oppdretter illustrasjon"
            />
            {/* <Button
              skin="dark"
              label="Jeg er oppdretter"
              className={css.registerButton}
              onClick={() => handleLUserType(true)}
            /> */}

            <Button
                variant="contained"
                size="large"
                className="fb"
                disabled={isBreederLoading}  
                type="button"
                onClick={() => handleLUserType(true)}
              >
                 {isBreederLoading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "Jeg er oppdretter"
                )}
               
              </Button>
          </div>
          <div className={css.buttonParent}>
            <img
              src="/images/buyer_illustration.png"
              alt="Kjøper illustrasjon"
            />
             <Button
                variant="contained"
                size="large"
                className="fb"
                disabled={isMemberTypeLoading}  
                type="button"
                onClick={() => handleLUserType(false)}
              >
                 {isMemberTypeLoading  ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  "Jeg er vanlig bruker"
                )}
               
              </Button>
            {/* <Button
              skin="dark"
              label="Jeg er vanlig bruker"
              className={css.registerButton}
              onClick={() => handleLUserType(false)}
            /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
