import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface IProps {
  label: string;
  error: boolean;
  onChange: (val: string) => void;
  helperText?: string;
  value?: string;
}
const PasswordField: React.FC<IProps> = ({
  label,
  value,
  onChange,
  error,
  helperText,
}): JSX.Element => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <TextField
      error={error}
      label={label}
      variant="outlined"
      type={showPassword ? "text" : "password"}
      value={value}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowPassword} edge="end">
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      helperText={helperText}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
    />
  );
};

export default PasswordField;
