import React from "react";
import css from "../../styles/Info.module.scss";

const Certificates: React.FC = function () {
  return (
    <div className={css.infoPage}>
      <h4>PONDifiserte oppdrettere</h4>
      <h5>Hva er en PONDifisert oppdretter?</h5>
      <p>
        Uten riktig kunnskap og erfaring kan det dessverre være utfordrende å
        vite hvem som er gode og ansvarlige oppdrettere. Derfor jobber vi
        kontinuerlig med å gjøre det enklere for potensielle hundeeiere å finne
        frem til alle dere gode oppdrettere der ute. Vi jobber tett med
        anerkjente veterinærer og dyrevelferdseksperter for å kunne evaluere
        oppdrettere før vi gir dem en sertifisering, som vi kaller en
        PONDifisering.
      </p>
      <p>
        En PONDifisert oppdretter bryr seg om hundene sine som om det var sin
        egen familie. De er opptatt av å bringe sunne, veltilpassede hunder til
        verden og sørge for at de kommer til varme og omsorgsfulle hjem. Dette
        starter allerede før valpene har kommet, når man velger hvilke hunder
        man ønsker å pare og velger foreldre som er sunne, sterke og har
        egenskaper som bør videreføres.
      </p>
      <p>
        Alle oppdrettere er ulike. De kan prioritere ulike ting og ha
        forskjellige aspekter ved hva de bryr seg mest om. En fellesnevner for
        alle gode oppdrettere er at de prioriterer hundenes velvære og har
        omsorg for hundene sine. Dette vises blant annet ved at de bruker mye
        tid på å sikre at hver hund de formidler passer godt til sin nye eier og
        gir nødvendig informasjon om forhold som er av betydning for hundens
        velferd.
      </p>
      <h5>Fortell oss hva du synes</h5>
      <p>
        PONDs fellesskap er basert på en forpliktelse til å hele tiden lytte og
        lære. Det er mange forskjellige meninger i hundemiljøet, og det kan ta
        tid for oss å finne ut av hvilke meninger som passer inn i våre verdier.
        Vi er hele tiden under utvikling ettersom også forskning hele tiden gir
        oss ny innsikt i hvordan man kan styrke dyrenes velferd. Mens vi
        fortsetter med å utvikle oss til å bli bedre, oppfordrer vi deg til å
        dele tilbakemeldingene dine med oss og stille spørsmål. Det er bare slik
        vi kan bli bedre, ved å samarbeide. Del gjerne dine tilbakemeldinger med
        oss via mail:{" "}
        <u onClick={() => window.open("mailto:contact@pond.no")}>
          contact@pond.no
        </u>
        .
      </p>
      <p>
        Ved å samarbeide og oppfordre til åpenhet og kunnskap om ansvarlig
        hundehold, kan vi gjøre en forskjell for hundene. Sammen kan vi gi
        hundene våre den verden de fortjener.
      </p>
    </div>
  );
};

export default Certificates;
