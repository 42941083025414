import useAuth0 from "../../hooks/useAuth0";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/Favorites.module.scss";
import Footer from "./Footer";
import { useGetMeQueryQuery } from "../../api/graphql.gen";
import { CircularProgress, Stack } from "@mui/material";
import { fetchBreedImages } from "../../api/services/favorites";

const Favorites: React.FC = () => {
  const { isMobile } = useIsMobile();
  const { isAuthenticated, isLoading } = useAuth0();
  const { data: userData } = useGetMeQueryQuery();
  const user = userData?.memberQuery.me;
  const breedList = user?.favorites?.breedersList ?? [];
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const [results, setResults] = useState<any[]>([]);
  const history = useHistory();
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsFetching(true);
      try {
        const allResults = await Promise.all(
          breedList.map(async (breed) => {
            const data = await fetchBreedImages(breed);
            return { breed, data };
          })
        );
        setResults(allResults);
      } catch (err) {
        console.error("Error fetching breed images", err);
        setIsFetching(false);
      } finally {
        setIsFetching(false);
      }
    };

    if (breedList.length > 0) {
      fetchData();
    }
  }, [breedList]);

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      history.push("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    window.analytics.page("Favoritter");
  }, []);

  function convertToEnglishUrl(text: string) {
    return text
      .toLowerCase()
      .replace(/ä/g, "ae")
      .replace(/å/g, "aa")
      .replace(/ø/g, "oe")
      .replace(/æ/g, "ae")
      .replace(/ /g, "-");
  }

  return (
    <div className={css.Favorites}>
      {!isMobile && (
        <DashboardBreadcrumbs prev="POND / Kontoinnstillinger" next="  >" />
      )}
      <div className={css.contentContainer}>
        <DashboardBanner
          image="/images/icons/dashboard/paw-circle.svg"
          percentage={60}
          title="Favoritter"
          info="Her kan du se alle rasene du har lagret"
          customWidth={""}
        />
        {isLoading ? (
          <Stack alignItems="center" mt="32px">
            <CircularProgress color="primary" variant="indeterminate" />
          </Stack>
        ) : (
          <div className={css.breedGrid}>
            {user?.favorites?.breedersList?.map((breed: string) => {
              const breedData = results.find(
                (result) => result.breed === breed
              );
              return (
                <div
                  key={breed}
                  className={css.breedContainer}
                  onClick={() =>
                    history.push(`/hunderaser/${convertToEnglishUrl(breed)}`)
                  }
                >
                  {isFetching ? (
                    <CircularProgress />
                  ) : (
                    breedData?.data?.items?.[0]?.properties?.profilePhoto?.[0]
                      ?.url && (
                      <img
                        src={`${baseUrl}${breedData.data.items[0].properties.profilePhoto[0].url}`}
                        alt={breed}
                      />
                    )
                  )}
                  <h4>{breed}</h4>
                </div>
              );
            })}
          </div>
        )}
        {!isLoading && !user?.favorites?.breedersList?.length && (
          <div>Du har ingen favoritter enda</div>
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Favorites;
