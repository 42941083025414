import React from "react";
import css from "./../styles/Articles.module.scss";
import ArticleCard from "../components/ArticleCard";
import { useIsMobile } from "../hooks/useIsMobile";

import {
  ArticleItemContentModel,
  ArtiklerContentModel,
} from "../api/umbracoApi";
import {
  useUmbracoContentItemQuery,
  useUmbracoContentQuery,
} from "../hooks/useUmbracoQuery";
import { CircularProgress, Stack } from "@mui/material";
import { Helmet } from "react-helmet-async";

const Articles: React.FC = () => {
  const { isMobile } = useIsMobile(1024);
  const { data: contents } = useUmbracoContentItemQuery<any>("artikler");
  const { data: articlePaged, isLoading } = useUmbracoContentQuery<any>({
    fetch: "children:artikler",
    sort: ["createDate:desc"],
  });
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const articles = articlePaged?.items;

  const renderArticles = (articles: any) => {
    if (isLoading) {
      return (
        <Stack alignItems="center" mt="32px">
          <CircularProgress color="primary" variant="indeterminate" />
        </Stack>
      );
    }
    return (
      <div className={css.content}>
        <div className={css.breeders}>
          <div className={css.title}>{contents?.properties?.title}</div>
          {articles && articles.length > 1 && (
            <ArticleCard
              title={articles[0].properties?.heading}
              url={articles[0].route.path}
              articleType={
                (articles[0].properties?.articalCategory &&
                  articles[0].properties?.articalCategory[0].name) ||
                ""
              }
              img={
                articles[0].properties?.image
                  ? articles[0].properties?.image[0].url +
                    "?width=455&height=400"
                  : ""
              }
              breed={articles[0].properties?.shortDescription ?? ""}
              date={new Date(articles[0].properties?.articleDate)}
              mobile={isMobile}
            />
          )}

          <div className={isMobile ? undefined : css.cardGrid}>
            {articles?.map(
              (article: ArticleItemContentModel, index: number) => {
                return index === 0 ? undefined : (
                  <ArticleCard
                    key={article.id}
                    title={article.properties?.heading}
                    url={article.route.path}
                    articleType={
                      (article.properties?.articalCategory &&
                        article.properties?.articalCategory[0].name) ||
                      ""
                    }
                    img={
                      article.properties?.image
                        ? article.properties?.image[0].url +
                          "?width=375&height=200"
                        : ""
                    }
                    breed={article.properties?.shortDescription ?? ""}
                    date={new Date(article.properties?.articleDate ?? "")}
                    mobile={true}
                  />
                );
              }
            )}
          </div>
        </div>
      </div>
    );
  };

  function urlFor(source: any) {
    return baseUrl + source;
  }
  const currentUrl = window.location.href;
  return (
    articles && (
      <div className={css.articles}>
        <Helmet>
      <meta charSet="utf-8" />
      <title>{contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"}</title>
        <meta name="description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {/* <!-- Facebook Meta Tags --> */}
          <meta
          property="og:type"
          content={
            contents?.properties?.metaKeywords
              ? contents?.properties?.metaKeywords
              : "Pond.no"
          }
          />
          <meta property="og:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl}  />
          <meta property="og:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta property="og:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"}  />
          {contents?.properties?.ogImage && (
          <meta name="og:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {contents?.properties?.ogImage && (
          <meta name="og:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}  />
          )}
          {/* <!-- Twitter Meta Tags --> */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta property="twitter:domain" content={contents?.properties?.metaKeywords ? contents?.properties?.metaKeywords : "Pond.no"} />
          <meta property="twitter:url" content={contents?.properties?.canonicalUrl ? contents?.properties?.canonicalUrl : currentUrl} />
          <meta name="twitter:title" content={contents?.properties?.metaTitle ? contents?.properties?.metaTitle : "Finn din beste venn - POND"} />
          <meta name="twitter:description" content={contents?.properties?.metaDescription ? contents?.properties?.metaDescription : "Plattform for ansvarlig kjøp og salg av hund"} />
          {contents?.properties?.ogImage && (
          <meta name="twitter:image:secure" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
          {contents?.properties?.ogImage && (
          <meta name="twitter:image" content={urlFor(contents?.properties?.ogImage[0].url).toString()}></meta>
          )}
        </Helmet>
        <div className={css.intro}>
          <h3>{contents?.properties?.title}</h3>
          <p>{contents?.properties?.description}</p>
        </div>
        {renderArticles(articles)}
      </div>
    )
  );
};

export default Articles;
