interface ValidationErrors {
    [field: string]: string[];
}

export function extractErrorMessages(errors: ValidationErrors): string[] {
    if (!errors || typeof errors !== "object") {
        return [];
    }

    // Collect all error messages into a single array
    const errorMessages = [];
    for (const messages of Object.values(errors)) {
        if (Array.isArray(messages)) {
            errorMessages.push(...messages);
        }
    }

    return errorMessages;
}

export const validatePassword = (value: string) => {
    if (!value) return 'Password is required';
    if (value.length < 10) return 'Password must be at least 10 characters long';
    if (!/[A-Za-z]/.test(value)) return 'Password must include at least one letter';
    if (!/[0-9]/.test(value)) return 'Password must include at least one number';
    if (!/[!@#$%^&*(),.?":{}|<>]/.test(value)) return 'Password must include at least one special character';
    return true;
};

export function isEmpty(obj: object): boolean {
    return Object.keys(obj).length === 0;
}