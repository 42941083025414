import React, { useState } from "react";
import { Button, Typography, Stack, ToggleButtonGroup, ToggleButton } from "@mui/material";
import SubscriptionCard from "./SubscriptionCard";
import { useUmbracoContentItemQuery, useUmbracoContentQuery } from "../hooks/useUmbracoQuery";
import { IApiContentResponsePagedViewModel } from "../api/umbracoApi";

const Subscription: React.FC = () => {
  const [tab, setTab] = useState<string>("1");
  const { data: contents } =
  useUmbracoContentItemQuery<any>("abonnement");

  const { data: subscription} = useUmbracoContentQuery<any>({
    fetch: "children:abonnement",
    sort: ["createDate:asc"],
  });


  const subscriptionItems=subscription?.items;

  const renderTabs = () => {
    return (
      <>
      {tab === '1' ? (
        <Stack
          direction="row"
          gap="32px"
          width="100%"
          maxWidth="1450px"
          justifyContent="center"
          padding={{ xs: "16px", md: "32px" }}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
        >
         {subscriptionItems?.map((item: any, index: number) => (
          <SubscriptionCard
            key={index}
            title={item.properties.title}
            subtitle={item.properties.duration}
            description={item.properties.detail}
            price={`${item.properties.currency} ${item.properties.price}`}
            features={item.properties.features}
          >
            <Button
              sx={{
                marginTop: "60px",
                border: "2px solid #065C53",
                padding: "16px 22px",
                gap: "4px",
                width: "100%",
                height: "64px",
                borderRadius: "56px",
                color: item.properties.buttonTextColor,
                backgroundColor: item.properties.buttonBackgroundColor,
                "&:hover": {
                  backgroundColor: item.properties.buttonBackgroundColor,
                  opacity: 0.8,
                },
              }}
            >
              {item.properties.subscriptionButton[0].title}
            </Button>
          </SubscriptionCard>
        ))}
      </Stack>
      ): null}
      {tab === '2' ? (
        <Stack
          direction="row"
          gap="32px"
          width="100%"
          maxWidth="1450px"
          justifyContent="center"
          padding={{ xs: "16px", md: "32px" }}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
        >
          {subscriptionItems?.filter((item: any) => 
            item.properties.title === "Gratis" || item.properties.title === "Pro+"
          ).map((item: any, index: number) => (
            <SubscriptionCard
              key={index}
              title={item.properties.title}
              subtitle={item.properties.duration}
              description={item.properties.detail}
              price={`${item.properties.currency} ${item.properties.price}`}
              features={item.properties.features}
            >
              <Button
                sx={{
                  marginTop: "60px",
                  border: "2px solid #065C53",
                  padding: "16px 22px",
                  gap: "4px",
                  width: "100%",
                  height: "64px",
                  borderRadius: "56px",
                  color: item.properties.buttonTextColor,
                  backgroundColor: item.properties.buttonBackgroundColor,
                  "&:hover": {
                    backgroundColor: item.properties.buttonBackgroundColor,
                    opacity: 0.8,
                  },
                }}
              >
                {item.properties.subscriptionButton[0].title}
              </Button>
            </SubscriptionCard>
          ))}
        </Stack>
      ) : null}


      </>
    )}

  return (
    <>
      <Stack
        marginBottom="45px"
        width="100vw"
        justifyContent="flex-start"
        alignItems="center"
        bgcolor="#E2F6D1"
        padding={{ xs: "16px", md: "32px" }}
      >
        <Stack
          width="100%"
          maxWidth="898px"
          gap={{ xs: "30px", md: "30px" }}
          justifyContent="center"
          padding="16px"
        >
          <Typography
            fontSize={{ xs: "40px", md: "80px" }}
            fontWeight="700"
            lineHeight={{ xs: "55px", md: "110px" }}
            letterSpacing="-1.5px"
            textAlign="center"
            color="#065C53"
          >
            {contents?.properties?.title}
            {/* Velg et abonnement */}
          </Typography>
          <Typography
            fontSize={{ xs: "18px", md: "26px" }}
            fontWeight="400"
            lineHeight={{ xs: "28px", md: "42px" }}
            textAlign="center"
            color="#065C53"
          >
            {contents?.properties?.description}
            {/* Finn det abonnementet som gir deg de funksjonene og fordelene du
            trenger for å administrere din kennel på best mulig måte. */}
          </Typography>
          <Stack
            direction="row"
            justifyContent="center"
            gap="20px"
            flexWrap="wrap"
          >
         

            <ToggleButtonGroup
              value={tab}
              exclusive
              onChange={(_, value) => {
                if (value !== null && value !== tab) {
                  setTab(value);
                }
              }}
              sx={{
                width: "310px",
                height: "56px",
                border: "2px solid #065C53",
                backgroundColor: "#FFFFFF",
                padding: "6px",
                borderRadius: "8px",
              }}
            >
              <ToggleButton
                value="1"
                sx={{
                  borderRadius: "8px",
                  border: "none",
                  padding: "10px 16px",
                  backgroundColor: tab === "1" ? "#065C53" : "#FFFFFF",
                  color: tab === "1" ? "#FFFFFF" : "#065C53",
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#065C53",
                    borderTopRightRadius: "8px",
                    borderBottomRightRadius: "8px",
                  },
                  "&:hover":{
                    backgroundColor:'none'
                  }
                }}
              >
                {contents?.properties?.monthlyText}
                {/* Månedlig */}
              </ToggleButton>
              <ToggleButton
                value="2"
                sx={{
                  width:'176px', 
                  borderRadius: "0 8px 8px 0",
                  border: "none",
                  padding: "10px 16px",
                  backgroundColor: tab === "2" ? "#065C53" : "#FFFFFF",
                  color: tab === "2" ? "#FFFFFF" : "#065C53",
                  "&.Mui-selected": {
                    color: "#fff",
                    backgroundColor: "#065C53",
                    borderRadius: "8px",
                  },
                  
                }}
              >
                {contents?.properties?.yearlyText}
                {/* Årlig (Spar 20%) */}
              </ToggleButton>
            </ToggleButtonGroup>
          </Stack>
        </Stack>
        {/* <Stack
          direction="row"
          gap="32px"
          width="100%"
          maxWidth="1450px"
          justifyContent="center"
          padding={{ xs: "16px", md: "32px" }}
          flexWrap={{ xs: "wrap", md: "nowrap" }}
        >
          {subscriptionItems?.map((items:any, index:number) => (
            <SubscriptionCard
              key={index}
              title={items.properties.title}
              subtitle={items.properties.duration}
              description={items.properties.detail}
              price={items.properties.currency + " " + items.properties.price}
              features={items.properties.features}
            >
              <Button
                sx={{
                  marginTop: "60px",
                  border: "2px solid #065C53",
                  padding: "16px 22px",
                  gap: "4px",
                  width: "100%",
                  height: "64px",
                  borderRadius: "8px",
                  color: items.properties.buttonTextColor,
                  backgroundColor: items.properties.buttonBackgroundColor,
                  "&:hover": {
                    backgroundColor: items.properties.buttonBackgroundColor,
                    opacity: 0.8,
                  },
                }}
              >
                {items.properties.subscriptionButton[0].title}
              </Button>
            </SubscriptionCard>
          ))}
        </Stack>  */}
        {renderTabs()}
      </Stack>
    </>
  );
};

export default Subscription;
