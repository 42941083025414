import React from "react";
import css from "../../styles/MyDogsCard.module.scss";
import Button from "../Button";

export interface CardProps {
  imgSrc: string;
  imgAlt: string;
  title: string;
  nkkNumber: string;
  breedName: string;
  birthDate: string;
  onClick?: () => void;
  onDelete?: () => void;
}

const MyDogsCard: React.FC<CardProps> = ({
  imgSrc,
  imgAlt,
  title,
  nkkNumber,
  breedName,
  birthDate,
  onClick,
  onDelete,
}: CardProps) => {
  return (
    <div className={css.card}>
      <div className={css.container}>
        <img
          src={imgSrc === "" ? "/images/placeholder-dog.png" : imgSrc}
          alt={imgAlt}
          className={css.image}
          onError={(e) => {
            e.currentTarget.src = "/images/placeholder-dog.png";
          }}
        />
        <div className={css.textContainer}>
          <h5 className={css.title}>{title}</h5>
          <h6 className={css.breedName}>{breedName}</h6>
          <h6 className={css.nkkNumber}>
            <b>Reg. nr:</b> {nkkNumber}
          </h6>
          <h6 className={css.birthDate}>
            <b>Født</b> {birthDate}
          </h6>
          <div className={css.buttonContainer}>
            <div onClick={onClick} className={css.edit}>
              <img src="/images/icons/dashboard/new-edit.svg" />
              Rediger
            </div>
            <div onClick={onDelete} className={css.delete}>
              <img src="/images/icons/dashboard/delete.svg" />
              Slett
            </div>
          </div>
        </div>
      </div>
      <span className={css.border}></span>
    </div>
  );
};

export default MyDogsCard;
