export const DogBreeds = [
  "Affenpinscher",
  "Afghansk mynde",
  "Airedale terrier",
  "Akita",
  "Alaskan malamute",
  "American akita",
  "Amerikansk cocker spaniel",
  "Amerikansk nakenterrier",
  "Australian shepherd",
  "Australsk kelpie",
  "Australsk terrier",
  "Azawakh",
  "Basenji",
  "Basset artesien normand",
  "Basset fauve de bretagne",
  "Basset hound",
  "Bayersk viltsporhund",
  "Beagle",
  "Bearded collie",
  "Beauceron",
  "Bedlington terrier",
  "Belgisk fårehund, groenendael",
  "Belgisk fårehund, malinois",
  "Belgisk fårehund, tervueren",
  "Berner sennenhund",
  "Bichon frisé",
  "Bichon havanais",
  "Bolognese",
  "Bordeaux dogge",
  "Border collie",
  "Border terrier",
  "Borzoi",
  "Boston terrier",
  "Boxer",
  "Bracco italiano",
  "Brasiliansk terrier",
  "Breton",
  "Briard",
  "Bull terrier",
  "Bullmastiff",
  "Cairn terrier",
  "Cesky terrier",
  "Chihuahua korthåret",
  "Chihuahua langhåret",
  "Chinese crested",
  "Chinese crested powderpuff",
  "Chongqing dog",
  "Chow chow",
  "Clumber spaniel",
  "Cocker spaniel",
  "Collie korthåret",
  "Collie langhåret",
  "Coton de tulear",
  "Curly coated retriever",
  "Dachshund korthåret",
  "Dachshund langhåret",
  "Dachshund strihåret",
  "Dalmatiner",
  "Dandie dinmont terrier",
  "Dansk-svensk gårdshund",
  "Dobermann",
  "Drentsche patrijshond",
  "Drever",
  "Dunker",
  "Dvergdachshund korthåret",
  "Dvergdachshund langhåret",
  "Dvergdachshund strihåret",
  "Dvergpinscher",
  "Dvergpuddel",
  "Dvergschnauzer hvit",
  "Dvergschnauzer salt/pepper",
  "Dvergschnauzer sort",
  "Dvergschnauzer sort/silver",
  "Engelsk bulldog",
  "Engelsk mastiff",
  "Engelsk setter",
  "Engelsk springer spaniel",
  "Engelsk toy terrier",
  "Eurasier",
  "Faraohund",
  "Field spaniel",
  "Finsk lapphund",
  "Finsk spets",
  "Finsk støver",
  "Flat coated retriever",
  "Fox terrier glatthåret",
  "Fox terrier ruhåret",
  "Fransk bulldog",
  "Golden retriever",
  "Gordon setter",
  "Grand danois",
  "Greyhound",
  "Griffon belge",
  "Griffon bruxellois",
  "Grønlandshund",
  "Grosser münsterländer",
  "Grosser schweizer sennenhund",
  "Haldenstøver",
  "Hamiltonstøver",
  "Hannoveransk viltsporhund",
  "Hovawart",
  "Hvit gjeterhund",
  "Hygenhund",
  "Irish glen of imaal terrier",
  "Irish softcoated wheaten terrier",
  "Irsk rød og hvit setter",
  "Irsk setter",
  "Irsk terrier",
  "Irsk ulvehund",
  "Islandsk fårehund",
  "Italiensk mynde",
  "Italiensk spinone",
  "Jack russell terrier",
  "Jämthund",
  "Japansk spisshund",
  "Kanindachshund korthåret",
  "Kanindachshund langhåret",
  "Kanindachshund strihåret",
  "Keeshond",
  "Kerry blue terrier",
  "Kleiner Münsterländer",
  "Kleinspitz",
  "Kroatisk gjeterhund",
  "Kuvasz",
  "Labrador retriever",
  "Lagotto romagnolo",
  "Lakeland terrier",
  "Lancashire heeler",
  "Lapsk vallhund",
  "Leonberger",
  "Lhasa apso",
  "Liten bernerstøver",
  "Løwchen",
  "Luzernerstøver",
  "Malteser",
  "Manchester terrier",
  "Maremma",
  "Mellompuddel",
  "Miniature bull terrier",
  "Mittelspitz",
  "Mudi",
  "Newfoundlandshund",
  "Norfolk terrier",
  "Norsk buhund",
  "Norsk elghund grå",
  "Norsk elghund sort",
  "Norsk lundehund",
  "Norwich terrier",
  "Nova scotia duck tolling retriever",
  "Østsibirsk laika",
  "Papillon",
  "Parson russell terrier",
  "Pekingeser",
  "Petit basset griffon vendeen",
  "Petit brabancon",
  "Phalene",
  "Pinscher",
  "Podenco ibicenco glatthår",
  "Podengo portugues liten glatthåret",
  "Pointer",
  "Polsk mynde",
  "Pomeranian",
  "Portugisisk vannhund",
  "Prazsky krysarik",
  "Presa canario",
  "Puli",
  "Pumi",
  "Pyreneeisk gjeterhund",
  "Pyreneerhund",
  "Pyreneisk gjeterhund med korthåret hode",
  "Rhodesian ridgeback",
  "Riesenschnauzer sort",
  "Rottweiler",
  "Russisk toy, korthåret",
  "Russisk toy, langhåret",
  "Russisk tsvetnaya bolonka",
  "Saluki",
  "Samojedhund",
  "Sankt bernhardshund korthåret",
  "Sankt bernhardshund langhåret",
  "Schäferhund langhår",
  "Schäferhund normalhår",
  "Schapendoes",
  "Schnauzer salt/pepper",
  "Schnauzer sort",
  "Sealyham terrier",
  "Shetland sheepdog",
  "Shiba",
  "Shih tzu",
  "Siberian husky",
  "Silky terrier",
  "Skotsk hjortehund",
  "Skotsk terrier",
  "Skye terrier",
  "Sloughi",
  "Spansk galgo",
  "Spansk vannhund",
  "Stabijhoun",
  "Staffordshire bull terrier",
  "Stor puddel",
  "Sussex spaniel",
  "Svensk hvit elghund",
  "Svensk lapphund",
  "Tibetansk spaniel",
  "Tibetansk terrier",
  "Toy puddel",
  "Tysk jaktterrier",
  "Ungarsk mynde",
  "Ungarsk vizla, korthåret",
  "Ungarsk vizsla, strihåret",
  "Vestsibirsk laika",
  "Volpino italiano",
  "Vorstehhund korthåret",
  "Vorstehhund strihåret",
  "Wachtelhund",
  "Weimaraner korthåret",
  "Weimaraner langhåret",
  "Welsh corgi cardigan",
  "Welsh corgi pembroke",
  "Welsh springer spaniel",
  "Welsh terrier",
  "West highland white terrier",
  "Whippet",
  "Xoloitzcuintle mellom",
  "Xoloitzcuintle miniatyr",
  "Xoloitzcuintle stor",
  "Yorkshire terrier",
  "Podengo portugues liten strihåret",
  "Chodsky pes",
  "Miniature american shepherd",
  "Continental bulldog",
  "Yakutian laika",
  "Nederlanske kooikerhondje",
  "Entlebucher sennenhund",
  "Bouvier des flandres",
  "Shar pei",
  "Segugio maremmano",
  "Valencian terrier",
  "Cäo de gado transmontano",
  "Brazilian tracker",
  "Schipperke",
  "Tibetansk mastiff",
  "Hollandsk Gjeterhund",
  "Chesapeake Retriever",
  "Barbet",
  "Wetterhoun",
  "Laekenois",
  "Cane corso",
  "Basset Bleu de Gascogne",
  "Tenterfield Terrier",
];
