import { default as Api, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { jwtDecode } from "jwt-decode";

export class AuthApi {
  private accessToken: string;
  private refreshToken: string;
  private grantRequest: Promise<any> | null;
 
  private isRefreshing: boolean;
  public tokenListener?: (
    accessToken: string,
    refreshToken: string
  ) => void | null;
  private refreshSubscribers: Array<(token: string) => void>;

  constructor(private axios: AxiosInstance) {
    this.accessToken = localStorage.getItem('accessToken') || '';
     this.refreshToken = localStorage.getItem('refreshToken') || '';
    this.grantRequest = null;    
    this.isRefreshing = false;
    this.refreshSubscribers = [];

    axios.interceptors.request.use(request  => this.requestInterceptor(request));
    axios.interceptors.response.use(
      (response) => response,
      this.responseInterceptor
    );
  }

  setAccessToken = (accessToken = ''): void => {
    this.accessToken = accessToken;
  };

  setRefreshToken = (refreshToken= ""): void => {
    this.refreshToken = refreshToken;
  };

  

  /**
   * Adds Authorization headers for non-oauth requests
   */
  requestInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken !== null && !config.headers?.Authorization) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    return config;
  };

  /**
   * Intercepts responses to handle token expiration and refreshing.
   */
  responseInterceptor = async (error: any): Promise<AxiosResponse> => {
    const originalRequest = error.config;

    // Check if the error is due to an expired access token (401 status)
    if ((error.response.status === 401  || error.response.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

       // If we are already refreshing the token, queue the request
      //  if (this.isRefreshing) {
      //   localStorage.clear();
      //   window.location.reload();
      // }

      this.isRefreshing = true;


      try {
        if (!this.grantRequest) {
          // Refresh the token
          this.grantRequest = this.refreshTokenRequest();
        }

        const newTokens = await this.grantRequest;
        this.isRefreshing = false;
        this.notifySubscribers(newTokens.accessToken);
        this.grantRequest = null;

        // Update tokens in localStorage and in the class
        this.setAccessToken(newTokens.accessToken);
        this.setRefreshToken(newTokens.refreshToken);

        // Retry the original request with the new access token
        originalRequest.headers['Authorization'] = `Bearer ${newTokens.accessToken}`;
        return this.axios(originalRequest);

      } catch (refreshError) {
        this.grantRequest = null;
        localStorage.clear();
        window.location.reload();
        console.error('Token refresh failed:', refreshError);
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  };


  /**
   * Set new access and refresh tokens
   */
  private setTokens(accessToken: string | null, refreshToken: string | null): void {
    this.accessToken = accessToken || '';
    this.refreshToken = refreshToken || '';
    localStorage.setItem('accessToken', accessToken || '');
    localStorage.setItem('refreshToken', refreshToken || '');
  }


  /**
   * Method to request a new access token using the refresh token.
   */
  private refreshTokenRequest = async (): Promise<any> => {
    const decoded:any = jwtDecode(this.accessToken || localStorage.getItem('accessToken') || '');
    
    const data = {
      refreshToken: this.refreshToken ?? localStorage.getItem('refreshToken') ?? '',
      UserId: decoded?.UserId,
    };
    
    const response = await Api.post<any>('/auth/refresh', data);
    
    const newAccessToken =  response.data.accessToken;
    const newRefreshToken = response.data.refreshToken;

    this.setTokens(newAccessToken, newRefreshToken);

    return {
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    };
  };
  
  /**
   * Subscribe to token refresh and queue requests until the token is available
   */
  private subscribeTokenRefresh(callback: (token: string) => void): void {
    this.refreshSubscribers.push(callback);
  }

  /**
   * Notify all subscribers about the new token
   */
  private notifySubscribers(token: string): void {
    this.refreshSubscribers.forEach((callback) => callback(token));
    this.refreshSubscribers = [];
  }
}
