import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./styles/TextStyles.module.scss";
import BecomeOwner from "./pages/BecomeOwner";
import BecomeBreeder from "./pages/BecomeBreeder";
import Profile from "./pages/Profile";
import Ad from "./pages/Ad";
import BreedOverview from "./pages/BreedOverview";
import BreedProfile from "./pages/BreedProfile";
import AboutPond from "./pages/InfoPages/AboutPond";
import ScrollToTop from "./scrollToTop";
import BreederInfo from "./pages/BreederInfo";
import Certificates from "./pages/InfoPages/Certificates";
import TermsAndCond from "./pages/InfoPages/TermsAndCond";
import Cookies from "./pages/InfoPages/Cookies";
import ContactUs from "./pages/InfoPages/ContactUs";
import TermsOfUse from "./pages/InfoPages/TermsOfUse";
import FAQ from "./pages/InfoPages/FAQ";
import BecomeOwnerInfo from "./pages/InfoPages/BecomeOwnerInfo";
import BecomeBreederInfo from "./pages/InfoPages/BecomeBreederInfo";
import BreederEthics from "./pages/InfoPages/BreederEthics";
import OwnerEthics from "./pages/InfoPages/OwnerEthics";
import ContactBreeder from "./pages/ContactBreeder";
import NewAdMobile from "./pages/NewAdMobile";
import Dashboard from "./pages/Dashboard/Dashboard";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/material-theme";
import { useIsMobile } from "./hooks/useIsMobile";
import ProfileMobile from "./pages/ProfileMobile";
import AccountSettings from "./pages/Dashboard/AccountSettings";
import EditProfile from "./pages/Dashboard/EditProfile";
import MyPuppies from "./pages/Dashboard/MyPuppies";
import MyDogs from "./pages/Dashboard/MyDogs";
import NewPuppy from "./pages/Dashboard/NewPuppy";
import NewDog from "./pages/Dashboard/NewDog";
import NewAdSteps from "./pages/NewAdSteps";
import AllAds from "./pages/AllAds";
import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/bundle";
import VerifyUser from "./pages/VerifyUser";
import Favorites from "./pages/Dashboard/Favorites";
import Articles from "./pages/Articles";
import Article from "./pages/Article";
import InfoPages from "./pages/InfoPages/InfoPage";
import { Helmet } from "react-helmet-async";
import OwnerRegistered from "./pages/OwnerRegistered";
import MyApplications from "./pages/Dashboard/MyApplications";
import Reference from "./pages/Dashboard/Reference";
import SendApplication from "./pages/Application/SendApplication";
import Application from "./pages/Application/Application";
import BreederOverview from "./pages/BreederOverview";
import BreederRegistered from "./pages/BreederRegistered";
import ApplicationConfirmation from "./pages/Application/ApplicationConfirmation";
import RedirectPage from "./pages/RedirectPage";
import ChatScreen from "./pages/Chat";
import Auth0Reroute from "./pages/Auth0Reroute";
import customLogin from "./pages/custom-login";
// import VerificationApenhet from "./components/VerificationApenhetCard";
import customRegister from "./pages/custom-register";
import ProtectedRoute from "./components/ProtectedRoute";
import Kunnskap from "./components/Kunnskap";
import OnBoarding from "./pages/custom-register/on-boarding";
import VerifyEmail from "./pages/custom-register/verify";
import ToastProvider from "./components/Toast";
import Miljo from "./components/Miljo";
import SuccessForm from "./components/SuccessForm";
import KunnskapForm from "./components/KunnskaForm";
import Subscription from "./components/Subscription";
import SubscriptionSucceessForm from "./components/SubscriptionSuccessForm";
import UserFeedbacks from "./pages/Dashboard/UserFeedbacks";
import Verification from "./pages/verification";
import ForgotPassword from "./pages/forgot-password";
import NewPassword from "./pages/new-password";
import Step4 from "./pages/custom-register/components/Step4";
import ChangePassword from "./pages/Dashboard/ChangePassword";

const App: React.FC = () => {
  const [metaTags, setMetaTags] = useState<any>();
  const [loading, setLoading] = useState(false);

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  function urlFor(source: any) {
    return "baseUrl" + source;
  }

  
  const { isMobile } = useIsMobile();
  if (loading) {
    return <div />;
  }
  return (
    <Router>
      <Helmet>
      <meta charSet="utf-8" />
        {/* <title>Finn din beste venn - POND</title>
        <meta name="description" content="Plattformen for ansvarlig kjøp og salg av hund"  />
        
        {/* <!-- Facebook Meta Tags --> */}
        {/* <meta property="og:url" content={baseUrl}  />
        <meta property="og:title" content="Finn din beste venn - POND"  />
        <meta property="og:description" content="Plattformen for ansvarlig kjøp og salg av hund"  />
        <meta property="og:image" content=""  />
        
        {/* <!-- Twitter Meta Tags --> */}
        {/* <meta name="twitter:card" content="summary_large_image"  />
        <meta property="twitter:domain" content="pond.no"  />
        <meta property="twitter:url" content={baseUrl}  />
        <meta name="twitter:title" content="Finn din beste venn - POND"  />
        <meta name="twitter:description" content="Plattformen for ansvarlig kjøp og salg av hund"  />
        // <meta name="twitter:image" content=""  /> */} 

      </Helmet>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <ScrollToTop />
          <Switch>
            <Layout>
              <Route exact path="/" component={Home} />
              <Route path="/login" component={customLogin} />
              <Route path="/register" component={customRegister} />
              <Route path="/verify" component={VerifyEmail} />
              <Route path="/forget-password" component={ForgotPassword} />
              <Route path="/new-password" component={NewPassword} />
              <Route path="/reset-password" component={ChangePassword} />
              <ProtectedRoute path="/on-boarding" component={OnBoarding} />
              <ProtectedRoute path="/velkommen" component={Step4} />
              <Route exact path="/valper-til-salgs" component={AllAds} />

              <ProtectedRoute exact path="/registrer" component={Register} /> 
              <Route exact path="/registrer/kjøper" component={BecomeOwner} />
              <Route
                exact
                path="/bruker-opprettet/kjøper"
                component={OwnerRegistered}
              />
              <Route
                exact
                path="/bruker-opprettet/oppdretter"
                component={BreederRegistered}
              />
              <Route
                exact
                path="/registrer/oppdretter"
                component={BecomeBreeder}
              />
              <Route exact path="/opprett-bruker" component={Auth0Reroute} />
              <Route
                exact
                path="/soknad-sendt"
                component={ApplicationConfirmation}
              />
              <Route
                exact
                path="/oppdretter/:userId"
                component={isMobile ? ProfileMobile : Profile}
              />
              <Route
                exact
                path="/verifiser/:type/:userId/:uuid"
                component={VerifyUser}
              />
              <Route exact path="/artikler" component={Articles} />
              <Route exact path="/artikler/:slug" component={Article} />
              <Route exact path="/guide/:slug" component={InfoPages} />
              <ProtectedRoute exact path="/profil" component={Profile} />
              <ProtectedRoute exact path="/min-pond" component={Dashboard} />
              <ProtectedRoute
                exact
                path="/min-pond/rediger-profil"
                component={EditProfile}
              />
              <ProtectedRoute
                exact
                path="/min-pond/kontoinnstillinger"
                component={AccountSettings}
              />
              <ProtectedRoute exact path="/min-pond/favoritter" component={Favorites} />
              <ProtectedRoute exact path="/min-pond/mine-valper" component={MyPuppies} />
              <ProtectedRoute
                exact
                path="/min-pond/mine-valper/ny/:type"
                component={NewPuppy}
              />
              <ProtectedRoute exact path="/nytt-valpekull" component={NewAdSteps} />
              <ProtectedRoute
                exact
                path="/min-pond/mine-valper/rediger/:id/:type"
                component={NewPuppy}
              />
              <ProtectedRoute exact path="/min-pond/avlshunder/ny" component={NewDog} />
              <ProtectedRoute
                exact
                path="/min-pond/avlshunder/rediger/:id"
                component={NewDog}
              />
              <Route exact path="/oppdrettere" component={BreederOverview} />
              <ProtectedRoute exact path="/min-pond/avlshunder" component={MyDogs} />
              <ProtectedRoute
                exact
                path="/min-pond/mine-soknader"
                component={MyApplications}
              />
              <Route exact path="/annonse-alternativ" component={NewAdMobile} />
              <ProtectedRoute
                exact
                path="/min-pond/referanser"
                component={UserFeedbacks}
              />
              <ProtectedRoute
                exact
                path="/annonse-alternativ"
                component={NewAdMobile}
              />
              <ProtectedRoute
                exact
                path="/send-soknad/:userId"
                component={SendApplication}
              />
              <ProtectedRoute
                exact
                path="/soknad/:applicationId"
                component={Application}
              />
              <Route exact path="/hunderaser" component={BreedOverview} />
              <Route
                exact
                path="/hunderaser/:breedName"
                component={BreedProfile}
              />
              <Route exact path="/annonse/:adId" component={Ad} />
              <Route exact path="/om-oss" component={AboutPond} />
              <Route exact path="/info/oppdretter" component={BreederInfo} />
              <Route exact path="/kontakt/:id" component={ContactBreeder} />
              <Route
                exact
                path="/pondifiserte-oppdrettere"
                component={Certificates}
              />
              <Route
                exact
                path="/personvernerklæring"
                component={TermsAndCond}
              />
              <Route exact path="/privacy-policy" component={TermsAndCond} />
              <Route
                exact
                path="/personvern-innstillinger"
                component={TermsAndCond}
              />
              <Route exact path="/cookies" component={Cookies} />
              <Route exact path="/kontakt-oss" component={ContactUs} />
              <Route
                exact
                path="/betingelser-og-vilkar"
                component={TermsOfUse}
              />
              <Route exact path="/faq" component={FAQ} />
              <Route exact path="/chat" component={ChatScreen} />
              <Route exact path="/bli-ponder" component={BecomeOwnerInfo} />
              <Route
                exact
                path="/registrer-oppdretter"
                component={BecomeBreederInfo}
              />
              <Route
                exact
                path="/retningslinjer/oppdretter"
                component={BreederEthics}
              />
              <Route
                exact
                path="/retningslinjer/eier"
                component={OwnerEthics}
              />
              <Route
                exact
                path="/raser"
                component={() => <RedirectPage url="/hunderaser" />}
              />
              <Route
                exact
                path="/annonser"
                component={() => <RedirectPage url="/valper-til-salgs" />}
              />
              <ProtectedRoute exact path="/verification" component={Verification} />
              <Route exact path="/kunnskap" component={Kunnskap} />
              <Route exact path="/miljo" component={Miljo} />
              <Route exact path="/success-form" component={SuccessForm} />
              <Route exact path="/kunnskap-form" component={KunnskapForm} />
              <Route
                exact
                path="/price-subscription"
                component={Subscription}
              />
              <Route
                exact
                path="/success-subscription"
                component={SubscriptionSucceessForm}
              />
            </Layout>
          </Switch>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  );
};

export default App;
