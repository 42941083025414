import React, { useState } from "react";
import css from "../../styles/UserFeedback.module.scss";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import useDogFeedback, { useMyFeedbackList } from "../../hooks/useDogFeedback";
import { CircularProgress, Divider, Stack } from "@mui/material";
import { FormControlLabel } from "@mui/material";
import CustomSwitch from "../../components/CustomSwitch";

const UserFeedbacks: React.FC = (): JSX.Element => {
  const { data: feedbackList, isLoading: feedbackLoading } =
    useMyFeedbackList();
  const { updateFeedbackStatus } = useDogFeedback();

  return (
    <div className={css.Dashboard}>
      <div className={css.contentContainer}>
        <DashboardBanner
          percentage={60}
          image="/images/icons/dashboard/smile-circle.svg"
          title="Referanser"
          info="Her kan du administrere tilbakemeldinger fra valpekjøpere og velge hvilke referanser som skal vises på profilen din. Vi anbefaler å være åpen og transparent ved å vise alle tilbakemeldinger, selv om noen inneholder kritikk. Dette bygger tillit og viser at du tar ansvar for å forbedre deg."
          logoutButton={false}
          profileCard={false}
          customWidth={""}
        />
      </div>
      <div className={css.mainContainer}>
        <div className={css.containerTextCard}>
          <h3>Dine tilbakemeldinger</h3>

          {feedbackLoading? <Stack alignItems="center" mt="32px"><CircularProgress color="primary" variant="indeterminate" /></Stack>:<div>
            {feedbackList && feedbackList?.length !== 0 ? (
              feedbackList?.map((item, index) => (
                <div className={css.referenceContainer} key={index}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <span className={css.referenceHeader1}>
                      {item.senderName}
                    </span>
                    <span className={css.referenceHeader2}>
                      Reg.nr: {item.registrationNumber}
                    </span>
                  </div>
                  <Divider
                    sx={{ borderColor: "#ABC6C2", marginBottom: "12px" }}
                  />
                  <div className={css.referenceBody}>
                    {item.feedbackDescription}
                  </div>
                  <span className={css.referenceHeader1}>Vis på profil:</span>
                  {/* <Switch
                  color="primary"
                  size="small"
                  checked={item.feedbackStatus === 1}
                  onChange={async () => {
                    const feedbackStatus = item.feedbackStatus === 2 || item.feedbackStatus === 0 ? 1 : 2;
                    try {
                      await updateFeedbackStatus.mutateAsync({
                        id: item.id,
                        feedbackStatus: feedbackStatus.toString(), // Adjust based on API requirements
                      });
                    } catch (error) {
                      console.error("Failed to update feedback status:", error);
                    }
                  }}
                  disabled={updateFeedbackStatus.isLoading}
                /> */}
                  <FormControlLabel
                    sx={{ paddingBottom: "20px", marginLeft: "0px" }}
                    control={
                      <CustomSwitch
                        onhandlePress={async (value) => {
                          const feedbackStatus = value ? 1 : 0;
                          await updateFeedbackStatus.mutateAsync({
                            id: item.feedbackId,
                            feedbackStatus: feedbackStatus,
                          });
                        }}
                        feedbackStatus={item.feedbackStatus}
                      />
                    }
                    label=""
                  />
                </div>
              ))
            ) : (
              <div className={css.emptyContainer}>
                <img
                  src="/images/icons/dashboard/hund.svg"
                  alt="Innstillinger ikon"
                />
                <p className={css.containerText}>
                  Du har foreløpig ingen referanser. Ta gjerne kontakt med
                  tidligere valpekjøpere og be dem legge inn en referanse via
                  din kennelprofil.
                </p>
              </div>
            )}
          </div>}
        </div>
      </div>
    </div>
  );
};
export default UserFeedbacks;
