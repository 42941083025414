import {
  Alert,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Divider,
  Autocomplete,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { useHistory, useParams } from "react-router-dom";
import Button from "../../components/Button";
import DashboardBanner from "../../components/Dashboard/DashboardBanner";
import DashboardBreadcrumbs from "../../components/Dashboard/DashboardBreadcrumbs";
import Input from "../../components/NewInput";
import { useIsMobile } from "../../hooks/useIsMobile";
import css from "../../styles/NewDog.module.scss";
import {
  Gender,
  ParentType,
  useFindMyDogsQueryQuery,
  useUploadDogParentMutationMutation,
} from "../../api/graphql.gen";
import { Controller, useForm } from "react-hook-form";
import { useUmbracoContentQuery } from "../../hooks/useUmbracoQuery";
import { IApiContentResponsePagedViewModel } from "../../api/umbracoApi";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { FileType } from "../../enums";
import { deleteFile, uploadFile } from "../../api/services/file";
import { axios } from "../../api/authenticated-axios";
import ProfileImage from "../../components/ProfileImage";
import { Button as ButtonSubmit } from "@mui/material";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface RouteParams {
  id: string;
}

interface IDoc {
  id: number;
  name: string;
  result: string;
  file: File | null;
  fileName?: string | null;
  dogParentId?: number;
  fileId?: string;
}

const NewDog: React.FC = () => {
  const { control, handleSubmit, getValues, setValue } = useForm();
  const defaultImage = "/images/placeholder-dog.png";
  const [isImageRemoved, setIsImageRemoved] = useState(false);
  const { isMobile } = useIsMobile();
  const [myDog, setMyDog] = useState(true);
  const [ja, setJa] = useState<boolean>(false);
  const [registered, setRegistered] = useState(false);
  const [ownersName, setOwnersName] = useState("");
  const [dogsName, setDogsName] = useState("");
  const [breed, setBreed] = useState("");
  const [color, setColor] = useState("");
  const [personality, setPersonality] = useState("");
  const [weight, setWeight] = useState<number>();
  const [registrationNumber, setRegistrationNumber] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [maleGender, setMaleGender] = useState(true);
  const [medications, setMedications] = useState(false);
  const [rabies, setRabies] = useState(false);
  const [pibbpi, setPibbpi] = useState(false);
  const [dhp, setDhp] = useState(false);
  const [wormTreatment, setWormTreatment] = useState(false);
  const [medicationDetails, setMedicationDetails] = useState("");
  const [eyeCheck, setEyeCheck] = useState(true);
  const [dnaTest, setDnaTest] = useState(true);
  const [mentalTest, setMentalTest] = useState(true);
  const [ADRight, setADRight] = useState<number>();
  const [ADLeft, setADLeft] = useState<number>();
  const [HDLeft, setHDLeft] = useState<number>();
  const [HDRight, setHDRight] = useState<number>();
  const [patellaLeft, setPatellaLeft] = useState<number>();
  const [patellaRight, setPatellaRight] = useState<number>();
  const [image, setImage] = useState<string>();
  const [dnaFile, setDnaFile] = useState<string>();
  const [vetFile, setVetFile] = useState<string>();
  const [eyeFile, setEyeFile] = useState<string>();
  const [mentalFile, setMentalFile] = useState<string>();
  const [fileType, setFileType] = useState<string>("");
  const [champions, setChampions] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [dogParentId, setDogParentId] = useState<number>();
  const [selectedFileIndex, setSelectedFileIndex] = useState<number | null>(
    null
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [healthCertificate, setHealthCertificate] = useState<string>("");
  const { data: DogBreeds } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:dogBreedItem"],
    });
  const { data: ChampionItems } =
    useUmbracoContentQuery<IApiContentResponsePagedViewModel>({
      filter: ["contentType:championItem"],
    });
  const allBreeds = DogBreeds;
  const { id } = useParams<RouteParams>();
  const { data } = useFindMyDogsQueryQuery();
  const myDogs = data?.dogParentQuery.findMyDogs;
  const history = useHistory();
  const fileInputRef = useRef<HTMLInputElement | null>();
  const testFileRef = useRef<HTMLInputElement | null>();
  const [testFile, setTestFile] = useState<File | null>(null);
  const [testFileName, setTestFileName] = useState<string | null>(null);
  const [fields, setFields] = useState<IDoc[]>([]);
  const [healthDocuments, setHealthDocuments] = useState<IDoc[]>([]);
  const [doc, setDoc] = useState<IDoc>({
    name: "",
    result: "",
    file: null,
    id: 0,
  });
  const [docError, setDocError] = useState<{ name: boolean; result: boolean }>({
    name: false,
    result: false,
  });
  const dog = myDogs?.filter((item) => item.id.toString() === id)[0];
  const [toggleFields, setToggleFields] = useState<boolean>(true);
  const hiddenFileInputImage = useRef<HTMLInputElement>(null);
  // current file to upload into container
  const [fileSelected, setFileSelected] = useState<File | null>(null);
  // current dog image to upload
  const [imageSelected, setImageSelected] = useState<File | null>(null);
  // UI/form management
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    window.analytics.page("Ny hund");
  }, []);
  useEffect(() => {
    if (id) {
      if (dog) {
        setDogsName(dog.name);
        setImage(
          dog.fileRecord?.url
            ? `${process.env.REACT_APP_API_BASE_URL}${dog.fileRecord.url}?width=410&height=410`
            : ""
        );
        setBreed(dog.breed);
        setPersonality(dog.description);
        setValue("dogsName", dog.name ?? "");
        setValue("nkkId", dog.nkkId ?? "");
        setRegistered(!!dog.nkkId);
        setMaleGender(dog.gender === Gender.Male);
        setOwnersName(dog?.ownersName ?? "");
        setMyDog(dog.isOwner);
        setADLeft(dog.adLeft ?? undefined);
        setADRight(dog.adRight ?? undefined);
        setHDLeft(dog.hdLeft ?? undefined);
        setHDRight(dog.hdRight ?? undefined);
        setPatellaLeft(dog.patellaLeft ?? undefined);
        setPatellaRight(dog.patellaRight ?? undefined);
        setValue("birthDate", format(new Date(dog.born), "yyyy-MM-dd"));
        setRabies(dog.rabies);
        setDhp(dog.dhp);
        setPibbpi(dog.pibbpi);
        setWeight(dog.weight ?? undefined);
        setColor(dog.color ?? "");
        setDnaTest(dog.dnaTest ?? false);
        setMentalTest(dog.mentalTest ?? false);
        setMedications(dog.medicines);
        setMedicationDetails(dog.healthInfo ?? "");
        setWormTreatment(dog.wormTreatment);
        setEyeCheck(dog.eye ?? false);
        setChampions(dog.championTitlesList ?? []);
        setDogParentId(dog.id);
        setHealthCertificate(dog.healthCertificate ?? "");
        setJa(dog.dogHealthChecked ?? false);
        setTestFileName(
          dog.healthCertificateUrl
            ? extractFileNameFromUrl(dog.healthCertificateUrl)
            : null
        );
        if (dog.healthDocuments && Array.isArray(dog.healthDocuments)) {
          setFields(
            dog.healthDocuments.map((doc) => ({
              id: doc.id,
              name: doc.testName,
              result: doc.testResult,
              fileId: doc.testFileId ?? "",
              file: null,
              fileName: extractFileNameFromUrl(doc.testFileUrl ?? ""),
            }))
          );
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (fileSelected) {
      onFileUpload(fileType);
    }
  }, [fileSelected]);

  const getRandomId = () => {
    return Math.floor(Math.random() * (999999 - 1000 + 1)) + 1000;
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files ? event.target?.files[0] : null;
    setDoc({ ...doc, file: file, fileName: file?.name });
  };

  const handleAddButton = () => {
    const file = doc.file;
    if (doc.name && doc.result) {
      const id = doc.id;
      setDoc({ name: "", result: "", file: null, id: 0 });
      if (selectedFileIndex) {
        const field = fields[selectedFileIndex];
        fields[selectedFileIndex] = {
          ...doc,
          file: file,
          dogParentId: dogParentId,
        };
        setFields([...fields]);
        setSelectedFileIndex(null);
        setHealthDocuments([
          ...healthDocuments,
          { ...field, file: file, dogParentId: dogParentId },
        ]);
      } else {
        const updatedFields = fields.map((field) =>
          field.id > 0 && field.id === doc.id
            ? { ...field, file: file, dogParentId: dogParentId }
            : field
        );

        const fieldDocumentExists = updatedFields.some(
          (field) => field.id === doc.id
        );
        if (!fieldDocumentExists) {
          updatedFields.push({
            ...doc,
            file: file,
            dogParentId: dogParentId,
            id: id > 0 ? id : getRandomId(),
          });
        }
        setFields([...updatedFields]);

        const updatedHealthDocuments = healthDocuments.map((healthDoc) =>
          healthDoc.id > 0 && healthDoc.id === doc.id
            ? { ...healthDoc, file: file, dogParentId: dogParentId }
            : healthDoc
        );
        const documentExists = updatedHealthDocuments.some(
          (healthDoc) => healthDoc.id === doc.id
        );
        if (!documentExists) {
          updatedHealthDocuments.push({
            ...doc,
            file: file,
            dogParentId: dogParentId,
            id: id > 0 ? id : getRandomId(),
          });
        }

        setHealthDocuments([...updatedHealthDocuments]);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
        setToggleFields(false);
      }
    }
  };

  const createMediaJson = (media: any) => {
    if (!media) return null;
    const file = {
      id: media.mediaId,
      url: media.mediaUrl,
    };
    return JSON.stringify(file);
  };

  const uploadImageAsync = async () => {
    if (!fileSelected && isImageRemoved && id && dog?.fileRecord?.id) {
      console.log("remove image api call", dog?.fileRecord?.id);
      await deleteFile(parseInt(dog?.fileRecord?.id?.toString() ?? ""));
      return null;
    }

    if (!fileSelected) {
      const fileResult = {
        mediaId: dog?.fileRecord?.id,
        mediaKey: dog?.fileRecord?.id,
        mediaUrl: dog?.fileRecord?.url,
      };

      return createMediaJson(fileResult);
    }

    const fileResult = await uploadFile(
      fileSelected,
      FileType.DogParent,
      dog?.fileRecord?.id?.toString()
    );
    setFileSelected(null);
    return createMediaJson(fileResult);
  };
  const removeField = async (index: number) => {
    if (index > -1 && index < fields.length) {
      if (id && fields[index].id) {
        try {
          await axios.post("/DogParent/deleteFileWithRecord", {
            Id: fields[index].id,
            FileId: fields[index].fileId ? parseInt(fields[index].fileId) : 0,
          });
        } catch (e) {
          console.log(e);
        }
      }
      const array = fields.filter((_, idx) => idx !== index);
      setFields(array);
      const updatedHealthDocuments = healthDocuments.filter(
        (doc) =>
          doc.name !== fields[index].name && doc.result !== fields[index].result
      );
      setHealthDocuments(updatedHealthDocuments);
    }
  };

  const changeField = (field: IDoc) => {
    setDoc(field);
    setToggleFields(true);
  };

  const extractFileNameFromUrl = (url: string): string | null => {
    return url.split("/").pop() || null;
  };

  const UploadHealthDocument = async (params: IDoc[]) => {
    try {
      const formData = new FormData();

      params.forEach((doc, index) => {
        formData.append(`files[${index}].id`, doc.id.toString());
        formData.append(`files[${index}].name`, doc.name);
        formData.append(`files[${index}].result`, doc.result);
        formData.append(`files[${index}].file`, doc.file ?? "");
        if (doc.dogParentId) {
          formData.append(
            `files[${index}].dogParentId`,
            doc.dogParentId.toString()
          );
        }
      });
      const res = await axios.post("/dogParent/upload", formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return res;
    } catch (error) {
      console.error("Error fetching user profile:", error);
    }
  };

  const onFileUpload = async (file: any) => {
    if (!file) {
      return;
    }
    setUploading(true);
    const media = await uploadFile(file, FileType.HealthCertificate);
    setFileSelected(null);
    setUploading(false);

    return media?.mediaId.toString();
  };

  const { mutate: uploadDog } = useUploadDogParentMutationMutation({
    onSuccess: async (data) => {
      const id = data.dogParentMutation.uploadDogParent.id;
      if (healthDocuments.length > 0) {
        healthDocuments.forEach((item) => {
          item.dogParentId = id;
        });
        const res: any = await UploadHealthDocument(healthDocuments);
        res?.data == true && setIsSubmitting(false);
      }
      setIsSubmitting(false);
      history.replace("/min-pond/avlshunder");
    },
    onError: () => setIsSubmitting(false),
  });

  const save = async () => {
    setIsSubmitting(true);
    // if (userId !== -1) {
    const dogsName = getValues("dogsName");
    const birthDate = getValues("birthDate");
    const registrationNumber = getValues("nkkId");

    let healthCertificateId: string | undefined = "";

    if (testFile) {
      await onFileUpload(testFile).then((id) => {
        healthCertificateId = id;
      });
    }

    const media = await uploadImageAsync();

    await uploadDog({
      model: {
        name: dogsName,
        adLeft: ADLeft ?? -1,
        adRight: ADRight ?? -1,
        born: new Date(birthDate),
        ownersName: myDog ? "" : ownersName,
        gender: maleGender ? Gender.Male : Gender.Female,
        breed: breed,
        description: personality,
        dhp,
        pibbpi,
        wormTreatment,
        eye: eyeCheck,
        image: media ? [media] : [""],
        medicines: medications,
        rabies,
        dnaTest,
        mentalTest,
        mentalTestFile: mentalFile,
        dnaTestFile: dnaFile,
        dogHealthChecked: ja ?? false,
        healthCertificate: healthCertificateId
          ? healthCertificateId
          : healthCertificate,
        eyeFile,
        vetCertificate: vetFile,
        hdLeft: HDLeft ?? -1,
        championTitle: champions,
        hdRight: HDRight ?? -1,
        isOwner: myDog,
        type: ParentType.Dad,
        weight: weight ?? -1,
        nkKid: registrationNumber,
        patellaLeft: patellaLeft ?? -1,
        patellaRight: patellaRight ?? -1,
        color,
        dogId: parseInt(id) ?? undefined,
      },
    });
    // }
  };

  const updateChampions = (value: string, index: number) => {
    const arr: string[] = [];
    champions.map((champion, i) => {
      if (index === i) {
        arr.push(value);
      } else {
        arr.push(champion);
      }
    });
    setChampions(arr);
  };

  return (
    <div className={css.NewDog}>
      {!isMobile && (
        <DashboardBreadcrumbs
          prev="POND / Avlshunder"
          next="Helse & genetikk  >"
        />
      )}
      <DashboardBanner
        image="/images/icons/dashboard/paw-circle.svg"
        percentage={60}
        title="Avlshunder"
        info="Her kan du legge til egne hunder og hunder brukt til avl til oppdrett. Du kan legge til egenskaper, championater og helse; samt. linke til NKK."
        customWidth={""}
      />
      <div className={css.mainContainer}>
        <div className={css.leftContainer}>
          <ProfileImage
            defaultImage={defaultImage}
            setFileSelected={setFileSelected}
            url={image}
            setIsImageRemoved={setIsImageRemoved}
          />
        </div>
        <div className={css.rightContainer}>
          <div className={css.flexGap}>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox checked={myDog} onChange={() => setMyDog(!myDog)} />
                }
                label="Min hund"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!myDog}
                    onChange={() => setMyDog(!myDog)}
                  />
                }
                label="Ikke min hund"
              />
            </FormGroup>
            <p className={css.warning}>
              Legg til hund her slik at du kan gi informasjon om både mor og far
              når du legger ut et valpekull. Ettersom dette ikke er din hund vil
              den ikke vises på din profil, men bare i valpekull-annonsen.{" "}
            </p>
            {!myDog && (
              <div className={css.singleContainer}>
                <Input
                  id="ownersName"
                  type="text"
                  onChange={(value) => setOwnersName(value)}
                  value={ownersName}
                  placeholder="Navn på eier/kennel"
                  className={css.singleInput}
                  sx={{ width: "100%" }}
                />
              </div>
            )}
            <div className={css.singleContainer}>
              <Controller
                name="dogsName"
                control={control}
                defaultValue={""}
                rules={{ required: "Navn på hund er påkrevd" }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="dogsName"
                    type="text"
                    placeholder="Navn på hund"
                    className={css.singleInput}
                    sx={{ width: "100%" }}
                    error={!!error} // Pass error state if your Input component supports it
                    errorMessage={error ? error.message?.toString() : ""} // Pass error message if your Input component supports it
                  />
                )}
              />
            </div>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={maleGender}
                    onChange={() => setMaleGender(!maleGender)}
                  />
                }
                label="Hannhund"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!maleGender}
                    onChange={() => setMaleGender(!maleGender)}
                  />
                }
                label="Tispe"
              />
            </FormGroup>
            <div className={css.dualContainer}>
              <FormControl>
                <InputLabel>Rase</InputLabel>
                <Select
                  name={`breed`}
                  id={`breed`}
                  onChange={(e) => setBreed(e.target.value as string)}
                  className={css.dualInput}
                  value={breed}
                  label="Rase"
                >
                  {allBreeds?.items.map((item: any) => (
                    <MenuItem
                      sx={{ zIndex: 100000 }}
                      value={item.properties.breedName}
                      key={item}
                    >
                      {item.properties.breedName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Input
                id="color"
                type="text"
                onChange={(value) => setColor(value)}
                value={color}
                className={css.dualInput}
                placeholder="Farge"
              />
            </div>
            <div className={css.dualContainer}>
              <Controller
                name="birthDate"
                control={control}
                rules={{ required: "Fødselsdato er påkrevd" }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="birthDate"
                    type="date"
                    placeholder="Fødselsdato"
                    className={css.dualInput}
                    sx={{
                      label: {
                        background: "white",
                        width: "95px",
                        "&.Mui-focused": { width: "auto" },
                      },
                    }}
                    error={!!error} // Pass error state if your Input component supports it
                    errorMessage={error ? error.message?.toString() : ""} // Pass error message if your Input component supports it
                  />
                )}
              />
              <Input
                id="weight"
                type="number"
                onChange={(value) => setWeight(parseFloat(value))}
                value={weight?.toString() ?? 0}
                className={css.dualInput}
                placeholder="Kg"
              />
            </div>
            <div className={css.flexGap}>
              <Controller
                name="nkkId"
                control={control}
                defaultValue={""}
                rules={{ required: "Registreringsnummer er påkrevd" }}
                render={({ field, fieldState: { error } }) => (
                  <Input
                    {...field}
                    id="nkkId"
                    type="text"
                    className={css.singleInput}
                    placeholder="Registreringsnummer"
                    sx={{ width: "100%" }}
                    error={!!error} // Pass error state if your Input component supports it
                    errorMessage={error ? error.message?.toString() : ""} // Pass error message if your Input component supports it
                  />
                )}
              />
              <FormGroup className={css.formGroup}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={registered}
                      onChange={() => setRegistered(!registered)}
                    />
                  }
                  label="Ikke registrert"
                />
              </FormGroup>
            </div>
          </div>
          <div className={css.singleContainer}>
            <Input
              id="personality"
              type="text"
              onChange={(value) => setPersonality(value)}
              value={personality}
              placeholder="Om hunden"
              className={css.singleInput}
              multiline
              sx={{ width: "100%" }}
            />
          </div>
          {/* <div className={css.singleContainer}>
            {champions.map((champion, index) => (
              <FormControl
                key={`${champion}-${index}`}
                className={css.championContainer}
                sx={{ width: "100%" }}
              >
                <InputLabel>Championater</InputLabel>
                <Select
                  name={`champion`}
                  id={`champion`}
                  value={champion}
                  onChange={(e) =>
                    updateChampions(e.target.value as string, index)
                  }
                  label="Championater"
                >
                  {ChampionItems?.items.map((item:any, i) => (
                    <MenuItem sx={{ zIndex: 100000 }} value={item.properties.title} key={i}>
                      {item.properties.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ))}
            <Button
              className={css.lessButton}
              skin="noColor"
              label="+ Legg til flere"
              onClick={() => setChampions([...champions, ""])}
            />
          </div> */}
          <Autocomplete
            multiple
            fullWidth
            options={
              ChampionItems?.items.map((item: any) => item.properties.title) ||
              []
            }
            // options={AllChamps || []}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              );
            }}
            getOptionLabel={(option: any) => option}
            renderInput={(params) => (
              <TextField {...params} label="Championater" />
            )}
            value={champions}
            onChange={(_, newValue) => setChampions(newValue)}
          />
          <Stack mb={2}>
            <h6 style={{ fontWeight: 600 }}>Hvilke helsetester er tatt?</h6>
            <Stack>
              {fields.map((field, idx) => (
                <>
                  {idx === 0 && (
                    <Divider sx={{ borderColor: "#ABC6C2", marginY: "16px" }} />
                  )}
                  <Stack direction="row" gap="6px">
                    <h6>Navn på helsetest: </h6>
                    <span>{field.name}</span>
                  </Stack>
                  <Stack direction="row" gap="6px">
                    <h6>Resultat: </h6>
                    <span>{field.result}</span>
                  </Stack>
                  <Stack direction="row" gap="6px">
                    <h6>Dokumentasjon: </h6>
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {field.fileName ? field.fileName : field.file?.name}
                    </span>
                  </Stack>
                  <Stack direction="row" className={css.buttonContainer}>
                    <div
                      className={css.edit}
                      onClick={() => {
                        changeField(field);
                        setSelectedFileIndex(idx);
                      }}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src="/images/icons/dashboard/edit-new.svg" />
                      Rediger
                    </div>
                    <div
                      className={css.delete}
                      onClick={() => removeField(idx)}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img src="/images/icons/dashboard/bin.png" />
                      Slett
                    </div>
                  </Stack>
                  <Divider sx={{ borderColor: "#ABC6C2", marginY: "16px" }} />
                </>
              ))}
            </Stack>
            <Stack gap="16px">
              {toggleFields && (
                <>
                  <Input
                    id="helsetest"
                    placeholder="Navn på helsetest"
                    type="text"
                    value={doc.name}
                    onChange={(val) => {
                      setDoc((pre) => ({
                        ...pre,
                        name: val.replace(/[^A-Za-zæøåÆØÅ\s'-]/g, ""),
                      }));
                      setDocError((pre) => ({ ...pre, name: false }));
                    }}
                    error={docError.name}
                    errorMessage={
                      docError.name
                        ? "This field is required for uploading file."
                        : ""
                    }
                  />
                  <Input
                    id="resultat"
                    placeholder="Resultat"
                    type="text"
                    value={doc.result}
                    onChange={(val) => {
                      setDoc((pre) => ({
                        ...pre,
                        result: val.replace(/[^A-Za-z0-9æøåÆØÅ\s'-]/g, ""),
                      }));
                      setDocError((pre) => ({ ...pre, result: false }));
                    }}
                    error={docError.result}
                    errorMessage={
                      docError.result
                        ? "This field is required for uploading file."
                        : ""
                    }
                  />
                </>
              )}
              <h6>Last opp dokumentasjon:</h6>
              {doc?.file || doc?.fileName ? (
                <>
                  <OutlinedInput
                    size="small"
                    id="outlined-adornment-password"
                    value={doc?.file?.name || doc?.fileName}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={async () => {
                            if (
                              doc.id > 0 &&
                              doc.fileId &&
                              doc.fileId.length > 0
                            ) {
                              setIsDeleting(true);
                              try {
                                await axios.post("/DogParent/deleteFile", {
                                  Id: doc.id,
                                  FileId: doc.fileId,
                                });
                                if (fileInputRef.current) {
                                  fileInputRef.current.value = "";
                                }
                                setDoc({ ...doc, file: null, fileName: null });
                              } catch (e) {
                                console.log("error deleting file");
                              } finally {
                                setIsDeleting(false);
                              }
                            } else {
                              if (fileInputRef.current) {
                                fileInputRef.current.value = "";
                              }
                              setDoc({ ...doc, file: null, fileName: null });
                            }
                          }}
                          edge="end"
                          disabled={isDeleting}
                        >
                          {isDeleting ? (
                            <CircularProgress color="primary" size={24} />
                          ) : (
                            <img src="/images/icons/dashboard/bin.png" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <ButtonSubmit
                    variant="contained"
                    size="large"
                    style={{ height: "56px", borderRadius: "30px" }}
                    onClick={() => {
                      if (!doc.name) {
                        setDocError((pre) => ({ ...pre, name: true }));
                        return;
                      }
                      if (!doc.result) {
                        setDocError((pre) => ({ ...pre, result: true }));
                        return;
                      }
                      handleAddButton();
                    }}
                  >
                    + Legg til
                  </ButtonSubmit>
                </>
              ) : (
                <>
                  {toggleFields ? (
                    <>
                      <Button
                        label="+ Last opp"
                        skin="lightGreen"
                        onClick={() => {
                          if (!doc.name) {
                            setDocError((pre) => ({ ...pre, name: true }));
                            return;
                          }
                          if (!doc.result) {
                            setDocError((pre) => ({ ...pre, result: true }));
                            return;
                          }
                          fileInputRef.current?.click();
                        }}
                        disabled={uploading}
                        height="56px"
                      />
                      <ButtonSubmit
                        variant="contained"
                        size="large"
                        style={{ height: "56px", borderRadius: "30px" }}
                        onClick={() => {
                          if (!doc.name) {
                            setDocError((pre) => ({ ...pre, name: true }));
                            return;
                          }
                          if (!doc.result) {
                            setDocError((pre) => ({ ...pre, result: true }));
                            return;
                          }
                          handleAddButton();
                        }}
                      >
                        + Legg til
                      </ButtonSubmit>
                    </>
                  ) : (
                    <Button
                      label="+ Legg til flere helsetester"
                      skin="lightGreen"
                      onClick={() => setToggleFields(true)}
                      disabled={uploading}
                      height="56px"
                    />
                  )}
                </>
              )}
              <input
                hidden
                type="file"
                accept="application/pdf"
                ref={(el: HTMLInputElement | null) =>
                  (fileInputRef.current = el)
                }
                onChange={(event) => handleFileChange(event)}
              />
              <Alert severity="success" sx={{ alignItems: "center" }}>
                Dokumentasjon blir ikke publisert offentlig på profilen din. Den
                blir gjennomgått for å verifisere at helsetestene faktisk er
                gjennomført.
              </Alert>
            </Stack>
          </Stack>
          <Stack gap={1}>
            <h6>Er hunden helsesjekket de siste 12 måneder før parring?*</h6>
            <FormGroup className={css.formGroup}>
              <FormControlLabel
                control={<Checkbox checked={ja} onChange={() => setJa(!ja)} />}
                label="Ja"
              />
              <FormControlLabel
                control={<Checkbox checked={!ja} onChange={() => setJa(!ja)} />}
                label="Nei"
              />
            </FormGroup>
            <h6>Last opp helseattest:</h6>
            {testFileName || testFile ? (
              <OutlinedInput
                size="small"
                id="outlined-adornment-password"
                value={testFileName ? testFileName : testFile?.name}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={async () => {
                        if (healthCertificate) {
                          try {
                            await axios.post(
                              "/File/deleteFile",
                              healthCertificate
                            );
                            if (testFileRef.current) {
                              testFileRef.current.value = "";
                            }
                            setTestFile(null);
                            setTestFileName(null);
                            setHealthCertificate("");
                          } catch (e) {
                            console.log("error deleting file");
                          }
                        } else {
                          if (testFileRef.current) {
                            testFileRef.current.value = "";
                          }
                          setTestFile(null);
                          setTestFileName(null);
                          setHealthCertificate("");
                        }
                      }}
                      edge="end"
                    >
                      <img src="/images/icons/dashboard/bin.png" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            ) : (
              <Button
                label="+ Last opp"
                skin="lightGreen"
                onClick={() => testFileRef.current?.click()}
                disabled={uploading}
              />
            )}
            <input
              hidden
              type="file"
              accept="application/pdf"
              ref={(el: HTMLInputElement | null) => (testFileRef.current = el)}
              onChange={(event) => {
                setTestFile(
                  event.target?.files ? event.target?.files[0] : null
                );
                setTestFileName(
                  event.target?.files && event.target?.files[0].name
                );
              }}
            />
            <Alert severity="success" sx={{ alignItems: "center", mt: "8px" }}>
              Dokumentasjon blir ikke publisert offentlig på profilen din. Den
              blir gjennomgått for å verifisere at helsetestene faktisk er
              gjennomført.
            </Alert>
          </Stack>
          <p className={css.warning}>
            Dokumentasjon du laster opp blir ikke publisert offentlig på
            profilen din. Vi gjennomfører jevnlige stikkprøver for å verifisere
            at helsetestene faktisk er gjennomført. Oppdages det feil i
            dokumentasjonen kan du risikere å bli utestengt fra POND.
          </p>
        </div>
      </div>
      {errorMessage && (
        <Alert
          severity="error"
          sx={{
            marginTop: "16px",
            ".MuiAlert-icon": {
              marginRight: "0px",
            },
          }}
        >
          {errorMessage}
        </Alert>
      )}
      <div style={{ marginTop: "16px" }}>
        <Button
          label="Tilbake"
          skin="lightGreen"
          disabled={isSubmitting}
          onClick={() => history.goBack()}
          height="56px"
        />
        <ButtonSubmit
          disabled={isSubmitting}
          variant="contained"
          size="large"
          style={{ width: "9rem" }}
          // type="submit"
          onClick={handleSubmit(save)}
        >
          {isSubmitting ? (
            <CircularProgress color="primary" size={24} />
          ) : (
            "Fortsett"
          )}
        </ButtonSubmit>
      </div>
    </div>
  );
};

export default NewDog;
