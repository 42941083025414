import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import css from "./index.module.scss";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";
import UploadButton from "../../../../components/UploadButton";

interface IProps {
  show: boolean;
  onSuccess: (step: number, data: any) => void;
  isLoading: boolean;
}

const Step3: React.FC<IProps> = ({ show, isLoading, onSuccess }) => {
  const { handleSubmit, control, watch } = useForm({
    defaultValues: { name: true, PuppyPictureId: null },
  });
  const pic = watch("PuppyPictureId");

  const onSubmit = (data: any) => {
    onSuccess(3, data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: show ? "block" : "none" }}
    >
      <Stack alignItems="center">
        <Stack
          gap="20px"
          marginBottom="40px"
          padding={{ xs: "16px", sm: "32px" }}
        >
          <div className={css.Miljo}>
            <div className={css.card}>
              <div className={css.iconText}>
                <div className={css.icon}>1</div>
                <div className={css.cardText}>
                  <Typography className={css.title}>
                    Bilde av valpebinge
                  </Typography>
                  <Typography className={css.desc}>
                    Last opp bilde av din valpebinge som viser det trygge og
                    sunne miljoet valpene vokser opp i.
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <Stack gap={3} maxWidth="486px">
            <Typography variant="body2" color="primary.main" fontWeight="600">
              Last opp bilde av valpebinge:
            </Typography>
            <Controller
              render={({ field }) => (
                <UploadButton
                  accept="image/*"
                  onChange={(file: any) => field.onChange(file)}
                  sx={{
                    backgroundColor: "secondary.main",
                    "&:hover": {
                      backgroundColor: "secondary.main",
                    },
                    h6: {
                      color: "#065C53",
                    },
                    p: {
                      color: "#555555",
                    },
                    height: "200px",
                  }}
                />
              )}
              name="PuppyPictureId"
              control={control}
              rules={{ required: true }}
            />
          </Stack>
          <Button
            disabled={!!!pic || isLoading}
            variant="contained"
            sx={{
              width: "100%",
              maxWidth: "399px",
              height: "56px",
              borderRadius: "56px",
            }}
            type="submit"
          >
            {isLoading ? (
              <CircularProgress color="primary" variant="indeterminate" />
            ) : (
              "Gå videre"
            )}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

export default Step3;
